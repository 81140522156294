<template>
  <section class="bg-grey-lighten-4 fill-height h-100">
    <v-row>
      <v-col cols="12">
        <v-data-table
            ref="dataTable"
            :class="['elevation-0', { 'disable-table': loadingDataTableActivity }]"
            :headers="headersActivity"
            hover
            :items="getActivity"
            :items-per-page="getActivityPagination.limit"
            @update:page="callGetActivity"
            @update:items-per-page="callGetActivity"
            @click:row="callGetActivityId"
            :loading="loadingDataTableActivity"
        >
          <template v-slot:top>
            <v-row class="px-2 pt-2 bg-grey-lighten-4" :no-gutters="true">
              <v-col cols="12" class="d-flex justify-space-between align-content-center align-center">
                <v-btn icon="mdi-plus" @click.stop="showDialogType(null, 0)" class="mr-2" density="comfortable" color="green-darken-1" variant="flat"></v-btn>
                <v-text-field :disabled="loadingInputSearch" :loading="loadingInputSearch" @input="detectSearchSheet()" @click:clear="clearSearch()" class="w-50" v-model="inputSearchActivity" prepend-inner-icon="mdi-magnify" label="Rechercher une activité" single-line hide-details :clearable="true">
                  <template v-slot:append-inner>
                    <v-progress-circular
                        v-if="loadingInputSearch"
                        indeterminate
                        size="20"
                        width="4"
                        color="blue-grey-darken-4"
                    ></v-progress-circular>
                  </template>
                </v-text-field>
                <v-select
                    :disabled="loadingInputStructure"
                    :loading="loadingInputStructure"
                    v-model="inputSelectStructure"
                    @update:modelValue="callGetActivity(inputSearchActivity, inputSelectLimitActivity, 1, inputSelectStructure)"
                    prepend-inner-icon="mdi-domain"
                    class="ml-2 w-25"
                    hide-details
                    clearable
                    label="Sélectionner un lieu"
                    :items="itemsSelectStructure"
                >
                  <template v-slot:append-inner>
                    <v-progress-circular
                        v-if="loadingInputStructure"
                        color="blue-grey-darken-4"
                        indeterminate
                        size="20"
                        width="4"
                    ></v-progress-circular>
                  </template>
                </v-select>
                <v-btn icon="mdi-refresh" class="ml-2" density="comfortable" :loading="loadingButtonRefresh" :color="colorButtonRefresh" variant="flat" @click="callGetActivity(inputSearchActivity, inputSelectLimitActivity, 1, inputSelectStructure)"></v-btn>
                <v-btn :icon="iconFilter" class="ml-2" density="comfortable" :color="colorFilter" variant="flat" @click="openFilter()"></v-btn>
                <v-btn icon="mdi-printer" class="ml-2" density="comfortable" :color="colorPrinter" :loading="loadingButtonPrinter" variant="flat" @click="printDataSheet"></v-btn>
                <v-btn icon="mdi-file-pdf-box" class="ml-2" density="comfortable" :color="colorPDF" :loading="loadingButtonPDF" variant="flat" @click="pdfDataSheet"></v-btn>
              </v-col>
            </v-row>
            <v-row class="px-2 pt-1 bg-grey-lighten-4" :no-gutters="true">
              <v-col cols="12" class="d-flex justify-end align-content-center align-center">
                <template v-if="codeRefresh === 0">
                  <v-progress-circular class="mr-2" model-value="20" :indeterminate="true" :width="1" :size="12"></v-progress-circular>
                  <p class="text-caption text-gray font-weight-bold">Mise à jour des données en cours...</p>
                </template>
                <template v-if="codeRefresh === 1">
                  <v-icon icon="mdi-information" color="green" class="mr-1" size="small"></v-icon>
                  <p class="text-caption text-green font-weight-bold" v-if="timeLastRefreshSheet === 1">Donnée mise à jour il y a {{ timeLastRefreshSheet }} seconde</p>
                  <p class="text-caption text-green font-weight-bold" v-else>Donnée mise à jour il y a {{ timeLastRefreshSheet }} secondes</p>
                </template>
                <template v-if="codeRefresh === 2">
                  <v-icon icon="mdi-alert" color="red" class="mr-1" size="small"></v-icon>
                  <p class="text-caption text-red font-weight-bold">Impossible de mettre à jour les données</p>
                </template>
              </v-col>
            </v-row>
            <!--START SECTION FILTER-->
            <v-row class="px-2 bg-grey-lighten-4" :no-gutters="true" v-if="showFilter">
              <v-col cols="12" class="d-flex justify-start align-content-center align-center flex-row">
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterId')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterId" style="font-size: 10px;" class="mr-3" density="compact" hide-details @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Identifiant</p>
                  </template>
                </v-checkbox>
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterName')"  color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterName" style="font-size: 10px;" class="mr-3" density="compact" hide-details @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Nom</p>
                  </template>
                </v-checkbox>
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterStatus')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterStatus" style="font-size: 10px;" class="mr-3" density="compact" hide-details @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Status</p>
                  </template>
                </v-checkbox>
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterRegistration')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterRegistration" style="font-size: 10px;" class="mr-3" density="compact" hide-details @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Inscriptions</p>
                  </template>
                </v-checkbox>
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterDescription')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterDescription" style="font-size: 10px;" class="mr-3" density="compact" hide-details @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Description</p>
                  </template>
                </v-checkbox>
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterAddress')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterAddress" style="font-size: 10px;" class="mr-3" density="compact" hide-details @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Adresse</p>
                  </template>
                </v-checkbox>
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterStartActivityDate')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterStartActivityDate" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Date de début</p>
                  </template>
                </v-checkbox>
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterEndActivityDate')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterEndActivityDate" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Date de fin</p>
                  </template>
                </v-checkbox>
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterStructure')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterStructure" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Structure</p>
                  </template>
                </v-checkbox>
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterCreatedAt')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterCreatedAt" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Date de création</p>
                  </template>
                </v-checkbox>
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterModifiedAt')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterModifiedAt" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Date de modification</p>
                  </template>
                </v-checkbox>
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterCreatedBy')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterCreatedBy" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Créer par</p>
                  </template>
                </v-checkbox>
                <v-checkbox :disabled="disableFilterIfFew('checkboxFilterModifiedBy')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterModifiedBy" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                  <template v-slot:label>
                    <p class="text-subtitle-2">Modifier par</p>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <!--END SECTION FILTER-->
          </template>
          <template v-slot:[`item.structure`]="{ item }">
            <template v-if="item.structure !== [] && item.structure !== null">
              <v-chip density="compact" size="small" class="mr-1" v-for="(snackbar, index) in item.structure" :key="index">{{snackbar}}</v-chip>
            </template>
            <template v-else>
              <v-chip density="compact" size="small">TOUS</v-chip>
            </template>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex flex-column d-md-flex flex-md-row justify-center align-content-center align-center">
              <v-chip v-if="item.status === 'BLOCK'" class="font-weight-bold" color="deep-purple-accent-1" size="small">Bloqué</v-chip>
              <v-chip v-if="item.status === 'STARTED'" class="font-weight-bold" color="orange-darken-1" size="small">En cours</v-chip>
              <v-chip v-if="item.status === 'FINISH'" class="font-weight-bold" color="green-darken-1" size="small">Terminé</v-chip>
              <v-chip v-if="item.status === 'SCHEDULED'" class="font-weight-bold" color="blue-darken-1" size="small">À venir</v-chip>
              <template v-if="item.seat != null">
                <v-chip v-if="item.totalRegistration === item.seat" class="font-weight-bold" color="red-darken-1" size="small">Complet</v-chip>
                <v-chip v-else-if="item.totalRegistration > item.seat" class="font-weight-bold blink" color="red-darken-1" size="small"><v-icon icon="mdi-alert" class="mr-1"></v-icon>SURBOOKÉ</v-chip>
              </template>
              <v-chip v-if="item.status === 'CANCELED'" class="font-weight-bold" color="grey-darken-1" size="small">Annulé</v-chip>
            </div>
          </template>
          <template v-slot:[`item.totalRegistration`]="{ item }">
            <p v-if="item.seat !== null && item.seat !== 0" :class="{ 'text-red': item.totalRegistration >= item.seat }"><b>{{item.totalRegistration}}</b> / <b>{{item.seat}}</b></p>
            <p v-else class="font-weight-bold">{{item.totalRegistration}}</p>
          </template>
          <template v-slot:[`item.startActivityDate`]="{ item }">
            <p>{{formatDate(item.startActivityDate, true)}}</p>
          </template>
          <template v-slot:[`item.endActivityDate`]="{ item }">
            <p>{{formatDate(item.endActivityDate, true)}}</p>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <p>{{formatDate(item.createdAt, true)}}</p>
          </template>
          <template v-slot:[`item.modifiedAt`]="{ item }">
            <p>{{formatDate(item.modifiedAt, true)}}</p>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex flex-column d-md-flex flex-md-row" v-if="item.status !== 'BLOCK'">
              <v-btn @click.stop="showDialogType(item, 1)" icon="mdi-pencil" density="comfortable" color="orange-darken-1"></v-btn>
              <v-btn @click.stop="showDialogType(item, 2)" icon="mdi-delete" density="comfortable" class="ml-0 mt-1 ml-md-2 mt-md-0" color="red-darken-1"></v-btn>
           <!--   <v-btn v-if="item.status === 'STARTED' || item.status === 'SCHEDULED'" @click.stop="showDialogType(item, 3)" icon="mdi-cancel" density="comfortable" class="ml-0 mt-1 ml-md-2 mt-md-0" color="red-accent-3"></v-btn>
              <v-btn v-if="item.status === 'FINISH'" @click.stop="showDialogType(item, 4)" icon="mdi-lock" density="comfortable" class="ml-0 mt-1 ml-md-2 mt-md-0" color="grey-darken-1"></v-btn>-->
            </div>
            <div v-else class="d-flex flex-column d-md-flex flex-md-row align-content-center align-center">
              <v-btn @click.stop="showDialogType(item, 5)" icon="mdi-lock-open" density="comfortable" class="ml-0 mt-1 ml-md-2 mt-md-0" color="grey-darken-1"></v-btn>
            </div>
          </template>
          <template v-slot:no-data>
            <v-empty-state icon="mdi-magnify" text="Essayez de modifier vos termes de recherche. Il se peut aussi qu'il n'y ait pas d'activité disponible dans ce cas, vous pouvez en créer une en cliquant sur le bouton ci-dessous." title="Aucune activité">
              <template v-slot:actions>
                <v-btn prepend-icon="mdi-plus" color="green-darken-1" text="Créer une activité"></v-btn>
              </template>
            </v-empty-state>
          </template>
          <template v-slot:bottom v-if="getActivityPagination.current_page">
            <v-row :no-gutters="true" class="pa-2">
              <v-col cols="12" class="d-flex justify-end align-center align-content-center">
                <p class="mr-2">Fiches par page :</p>
                <v-select
                    :disabled="loadingInputLimitSheet"
                    :loading="loadingInputLimitSheet"
                    class="mr-2"
                    density="compact"
                    hide-details
                    max-width="100"
                    :items="['5', '10', '20', '50', '100']"
                    v-model="inputSelectLimitActivity"
                    @update:modelValue="goToSheetPagination('updateLimit')"
                ></v-select>
                <v-btn
                    class="mr-1"
                    density="comfortable"
                    :disabled="!getActivityPagination.has_previous_page || loadingButtonFirstPage"
                    icon="mdi-page-first"
                    @click="goToSheetPagination('first')"
                ></v-btn>
                <v-btn
                    density="comfortable"
                    :disabled="!getActivityPagination.has_previous_page || loadingButtonPreviousPage"
                    icon="mdi-chevron-left"
                    @click="goToSheetPagination('previous')"
                ></v-btn>
                <span class="mx-2">Page <b v-if="getActivityPagination.total_pages > 0">{{ getActivityPagination.current_page }}</b><b v-else>0</b> sur <b>{{ getActivityPagination.total_pages }}</b></span>
                <v-btn
                    class="mr-1"
                    density="comfortable"
                    :disabled="!getActivityPagination.has_next_page || loadingButtonNextPage"
                    icon="mdi-chevron-right"
                    @click="goToSheetPagination('next')"
                ></v-btn>
                <v-btn
                    density="comfortable"
                    :disabled="!getActivityPagination.has_next_page || loadingButtonLastPage"
                    icon="mdi-page-last"
                    @click="goToSheetPagination('last')"
                ></v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:bottom v-else></template>
        </v-data-table>
      </v-col>
    </v-row>
  </section>
  <DialogActivityComponent :type="typeDialogActivity" :data="dataDialog" @finish="callGetActivity(inputSearchActivity, inputSelectLimitActivity, getActivityPagination.current_page , inputSelectStructure)" />
</template>
<script>
import {generalStore} from "@/store/General/generalStore";
import html2pdf from 'html2pdf.js';
import {activityStore} from "@/store/activityStore";
import DialogActivityComponent from "@/components/Activity/DialogActivityComponent";
import {registrationStore} from "@/store/registrationStore";

export default {
  name: 'ListActivityComponent',
  components: {DialogActivityComponent},
  data() {
    return {
      //START DATA SEARCH
      loadingInputSearch: false,
      timeoutTypingSearch: null,
      inputSearchActivity: null,
      //END DATA SEARCH
      //START DATA SEARCH STRUCTURE
      loadingInputStructure: false,
      inputSelectStructure: null,
      itemsSelectStructure: process.env.VUE_APP_STRUCTURES.split(','),
      //END DATA SEARCH STRUCTURE
      //START DATA FILTER
      showFilter: false,
      iconFilter: "mdi-filter",
      colorFilter: "blue-grey-darken-4",
      filterCheckbox: {
        checkboxFilterId: false,
        checkboxFilterName: true,
        checkboxFilterRegistration: true,
        checkboxFilterDescription: false,
        checkboxFilterAddress: false,
        checkboxFilterStatus: true,
        checkboxFilterStartActivityDate: true,
        checkboxFilterEndActivityDate: true,
        checkboxFilterStructure: true,
        checkboxFilterCreatedAt: false,
        checkboxFilterModifiedAt: false,
        checkboxFilterCreatedBy: false,
        checkboxFilterModifiedBy: false,
      },
      //END DATA FILTER
      //START DATA TABLE
      loadingButtonRefresh: false,
      loadingDataTableActivity: false,
      colorButtonRefresh: "blue-grey-darken-4",
      headersActivity: [
        { title: 'Status', align: 'center', key: 'status', sortable: false},
        { title: 'Nom', align: 'start', sortable: true, key: 'name'},
        { title: 'Inscrit(s)', align: 'start', sortable: true, key: 'totalRegistration'},
        { title: 'Date de Début', align: 'start', sortable: true, key: 'startActivityDate'},
        { title: 'Date de Fin', align: 'start', sortable: true, key: 'endActivityDate'},
        { title: 'Structures', align: 'center', sortable: true, key: 'structure'},
        { title: '', align: 'start', key: 'action', sortable: false},
      ],
      //END DATA TABLE
      //START DATA PAGINATION TABLE
      inputSelectLimitActivity: '10',
      loadingInputLimitSheet: false,
      loadingButtonNextPage: false,
      loadingButtonPreviousPage: false,
      loadingButtonFirstPage: false,
      loadingButtonLastPage: false,
      //END DATA PAGINATION TABLE
      //START DATA DIALOG SHEET
      dataDialog: null,
      typeDialogActivity: 0,
      //END DATA DIALOG SHEET
      //START DATA CODE REFRESH
      codeRefresh: 0,
      timerLastRefresh: null,
      timeLastRefreshSheet: 1,
      timer: null,
      timerLeft: 10,
      //END DATA CODE REFRESH
      //START DATA PRINTER AND PDF
      colorPrinter: "blue-grey-darken-4",
      colorPDF: "blue-grey-darken-4",
      loadingButtonPrinter: false,
      loadingButtonPDF: false,
      //END DATA PRINTER AND PDF
    };
  },
  computed: {
    getActivityPagination() {
      return activityStore().getActivityPagination;
    },
    getActivity() {
      return activityStore().getActivity;
    },
  },
  created() {
    this.callGetActivity();
  },
  unmounted() {
    clearInterval(this.timerLastRefresh);
  },
  methods: {
    //START METHOD SEARCH
    detectSearchSheet() {
      if (this.timeoutTypingSearch) {
        clearTimeout(this.timeoutTypingSearch);
      }
      this.timeoutTypingSearch = setTimeout(async () => {
        this.loadingInputSearch = true;
        await this.callGetActivity(this.inputSearchActivity, this.inputSelectLimitActivity, 1, this.inputSelectStructure)
        this.loadingInputSearch = false;
      }, 1000);
    },
    async clearSearch() {
      if (this.timeoutTypingSearch) {
        clearTimeout(this.timeoutTypingSearch);
      }
      this.loadingInputSearch = true;
      await this.callGetActivity(this.inputSearchActivity, this.inputSelectLimitActivity, 1, this.inputSelectStructure)
      this.loadingInputSearch = false;
    },
    //END METHOD SEARCH
    //START METHOD FILTER
    openFilter() {
      this.showFilter = !this.showFilter;
      this.showFilter ? this.iconFilter = "mdi-filter-off" : this.iconFilter = "mdi-filter";
      this.showFilter ? this.colorFilter = "blue-grey-lighten-1" : this.colorFilter = "blue-grey-darken-4";
    },
    updateFilter() {
      this.headersActivity = [];
      if (this.filterCheckbox.checkboxFilterId) {
        this.headersActivity.push({title: 'Identifiant', align: 'start', key: 'id', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterStatus) {
        this.headersActivity.push({title: 'Status', align: 'center', key: 'status', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterName) {
        this.headersActivity.push({title: 'Nom', align: 'start', key: 'name', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterRegistration) {
        this.headersActivity.push({title: 'Inscrit(s)', align: 'start', key: 'totalRegistration', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterDescription) {
        this.headersActivity.push({title: 'Description', align: 'start', key: 'description', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterAddress) {
        this.headersActivity.push({title: 'Adresse', align: 'start', key: 'address', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterStartActivityDate) {
        this.headersActivity.push({title: 'Date de Début', align: 'start', key: 'startActivityDate', sortable: false});
      }
      if (this.filterCheckbox.checkboxFilterEndActivityDate) {
        this.headersActivity.push({title: 'Date de Fin', align: 'center', key: 'endActivityDate', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterStructure) {
        this.headersActivity.push({title: 'Structures', align: 'center', key: 'structure', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterCreatedAt) {
        this.headersActivity.push({title: 'Crée le', align: 'center', key: 'createdAt', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterModifiedAt) {
        this.headersActivity.push({title: 'Modifié le', align: 'center', key: 'modifiedAt', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterCreatedBy) {
        this.headersActivity.push({title: 'Crée par', align: 'center', key: 'createdBy', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterModifiedBy) {
        this.headersActivity.push({title: 'Modifié par', align: 'center', key: 'modifiedBy', sortable: true});
      }
      this.headersActivity.push({title: '', align: 'start', key: 'action', sortable: false});
    },
    disableFilterIfFew(checkboxName) {
      const checkedCount = Object.values(this.filterCheckbox).filter(value => value).length;
      return checkedCount <= 3 && this.filterCheckbox[checkboxName];
    },
    //END METHOD FILTER

    //START METHOD PAGINATION TABLE
    goToSheetPagination(action) {
      let page;
      switch (action) {
        case 'first':
          page = 1;
          break;
        case 'last':
          page = this.getActivityPagination.total_pages;
          break;
        case 'next':
          page = this.getActivityPagination.current_page + 1;
          break;
        case 'previous':
          page = this.getActivityPagination.current_page - 1;
          break;
        case 'updateLimit':
          page = 1;
          break;
        default:
          page = this.getActivityPagination.current_page;
          break;
      }
      this.callGetActivity(this.inputSearchActivity, this.inputSelectLimitActivity, page, this.inputSelectStructure);
    },
    //END METHOD PAGINATION TABLE

    //START METHOD DIALOG SHEET
    showDialogType(item, typeDialog) {
      if (item !== null) {
        this.dataDialog = item;
      }
      this.typeDialogActivity = typeDialog;
      activityStore().dialogActivityShow = true;
    },
    //END METHOD DIALOG SHEET

    //START METHOD FORMAT DATE AND BIRTHDAY
    formatDate(dateValue, withTime = false) {
      if (!dateValue || typeof dateValue !== 'object') {
        return "";
      }
      if (!dateValue.date) {
        return "Date invalide";
      }
      dateValue = dateValue.date;
      const cleanDate = dateValue.split('.')[0].replace(' ', 'T');
      const date = new Date(cleanDate);
      // Vérifier si la date est valide
      if (isNaN(date)) return "Date invalide";
      const options = {
        timeZone: 'Europe/Paris',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
      if (withTime) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.second = '2-digit';
      }
      return date.toLocaleString('fr-FR', options);
    },
    isBirthday(birthday) {
      const cleanBirthday = this.getCleanDate(birthday);
      if (!cleanBirthday) return false;
      const today = new Date();
      const birthDate = new Date(cleanBirthday);
      return today.getMonth() === birthDate.getMonth() && today.getDate() === birthDate.getDate();
    },
    calculateAge(birthday) {
      const cleanBirthday = this.getCleanDate(birthday);
      if (!cleanBirthday) return "";
      const today = new Date();
      const birthDate = new Date(cleanBirthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    getCleanDate(dateValue) {
      if (!dateValue) return null;
      if (typeof dateValue === 'object' && dateValue.date) {
        dateValue = dateValue.date;
      }
      const cleanDate = dateValue.split('.')[0].replace(' ', 'T');
      const date = new Date(cleanDate);
      if (isNaN(date)) return null;
      return cleanDate;
    },
    //END METHOD FORMAT DATE AND BIRTHDAY

    //START METHOD CHANGE WINDOW
    goToWindow(state) {
      activityStore().stateWindowActivity = state;
    },
    //END METHOD CHANGE WINDOW

    //START METHOD CALL API
    async callGetActivity(search = null, limit = 10, page = 1, structure = "TOUS") {
      this.codeRefresh = 0;
      this.loadingButtonRefresh = true;
      this.loadingInputLimitSheet = true;
      this.loadingInputStructure = true;
      this.loadingInputSearch = true;
      this.loadingButtonFirstPage = true;
      this.loadingButtonPreviousPage = true;
      this.loadingButtonLastPage = true;
      this.loadingButtonNextPage = true;
      this.colorButtonRefresh = "blue-grey-lighten-1";
      try {
        const response = await activityStore().callGetActivity(search, limit, page, structure);
        if (response) {
          this.codeRefresh = 1;
          this.startInterval();
        } else {
          generalStore().showNotifSnackbar("ERREUR", "orange", true, 5000);
          clearInterval(this.timerLastRefresh);
          this.codeRefresh = 2;
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la récupération des fiches !", "red-darken-3", true, 5000);
        clearInterval(this.timerLastRefresh);
        this.codeRefresh = 2;
      }
      this.loadingButtonRefresh = false;
      this.loadingInputLimitSheet = false;
      this.loadingInputStructure = false;
      this.loadingInputSearch = false;
      this.loadingButtonFirstPage = false;
      this.loadingButtonPreviousPage = false;
      this.loadingButtonLastPage = false;
      this.loadingButtonNextPage = false;
      this.colorButtonRefresh = "blue-grey-darken-4";
    },
    async callGetActivityId(item, row) {
      this.loadingDataTableActivity = true;
      try {
        const response1 = await activityStore().callGetActivityId(row.item.id);
        if (response1) {
          const response2 = await registrationStore().callGetRegistrationActivity(row.item.id, '',1000, 1);
          if (response2) {
            clearInterval(this.timerLastRefresh);
            this.goToWindow(2);
          } else {
            generalStore().showNotifSnackbar("ERREUR", "orange", true, 5000);
            this.codeRefresh = 2;
            clearInterval(this.timerLastRefresh);
          }
        } else {
          generalStore().showNotifSnackbar("ERREUR", "orange", true, 5000);
          this.codeRefresh = 2;
          clearInterval(this.timerLastRefresh);
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la récupération des fiches !", "red-darken-3", true, 5000);
        this.codeRefresh = 2;
        clearInterval(this.timerLastRefresh);
      }
      this.loadingDataTableActivity = false;
    },
    //END METHOD CALL API


    //START METHOD TIMEOUT AND INTERVAL
    startInterval() {
      if (this.timerLastRefresh !== null) {
        clearInterval(this.timerLastRefresh);
      }
      this.timeLastRefreshSheet = 1;
      this.timerLastRefresh = setInterval(() => {
        this.timeLastRefreshSheet++;
        if (this.timeLastRefreshSheet >= 60) {
          this.callGetActivity(this.inputSearchActivity, this.inputSelectLimitActivity, this.getActivityPagination.current_page, this.inputSelectStructure);
          clearInterval(this.timerLastRefresh);
        }
      }, 1000);
    },
    //END METHOD TIMEOUT AND INTERVAL

    //TODO HERE CLEAN CODE

    async printDataSheet() {
      this.loadingButtonPrinter = true;
      this.colorPrinter = "blue-grey-lighten-1";
      const printContent = `
    <html>
    <head>
 <style>
        @media print {
          @page {
            size: landscape; /* Définit l'orientation en paysage */
          }
          body {
            margin: 0;
          }
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid black;
            padding: 5px;
            text-align: left;
            font-size: 6px; /* Taille de police plus petite pour l'impression */
          }
          th {
            background-color: #f2f2f2;
          }
        }
      </style>
    </head>
    <body>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nom</th>
            <th>Naissance</th>
            <th>Téléphone</th>
            <th>E-mail</th>
            <th>Commentaire</th>
            <th>Structure</th>
          </tr>
        </thead>
        <tbody>
          ${this.getActivity
          .map((item, index) =>
              `<tr>
                    <td>${index + 1}</td> <!-- Numéro de ligne -->
                  <td><span style="text-transform: uppercase;">${item.name || ''}</span></td>
                  <td style="text-align: center;">${this.formatDate(item.birthday) || ''}<br><b>${this.calculateAge(item.birthday)}</b> ans</td>
                  <td>${item.address || ''}</td>
                  <td>${item.description || ''}</td>
                  <td>${item.comment || ''}</td>
                    <td>${(item.structure || []).map(line => `${line}`).join('<br/>')}</td>
                </tr>`
          )
          .join('')}
        </tbody>
      </table>
    </body>
    </html>
  `;

      const width = 1600;  // largeur de la nouvelle fenêtre
      const height = 800; // hauteur de la nouvelle fenêtre

// Calculer la position centrale
      const left = (window.screen.width / 2) - (width / 2);
      const top = (window.screen.height / 2) - (height / 2);

// Ouvrir la nouvelle fenêtre centrée
      const newWindow = await window.open('', 'Activités', `width=${width},height=${height},top=${top},left=${left}`);
      newWindow.document.write(printContent);
      newWindow.document.title = "Activités"; // Définir le titre de la nouvelle fenêtre
      newWindow.document.close();
      newWindow.focus();
      newWindow.print();
      this.loadingButtonPrinter = false;
      this.colorPrinter = "blue-grey-darken-4";
    },
    async pdfDataSheet() {
      this.loadingButtonPDF = true;
      this.colorPDF = "blue-grey-lighten-1";
      const calculateAge = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      };

      const table = document.createElement('table');
      table.innerHTML = `
  <caption style="border: 5px solid black; padding: 10px; font-weight: bold;">FICHES</caption>
  <thead>
    <tr style="border-left: 1px solid black; border-right: 1px solid black;">
      <th>#</th>
      <th style="padding: 10px 0">Nom/Prénom</th>
      <th>Naissance</th>
      <th>Téléphone</th>
      <th>E-mail</th>
      <th>Commentaire</th>
      <th>Structure</th>
    </tr>
  </thead>
  <tbody>
    ${this.getActivity.map((item, index) => {
        // Extract the date string from the object
        const dateStr = item.birthday?.date || '';
        // Create a new Date object using the extracted date string
        const formattedDate = dateStr ? new Date(dateStr).toLocaleDateString('fr-FR') : '';
        // Calculate the age
        const age = dateStr ? calculateAge(dateStr) : '';

        return `
        <tr style="
          border-left: 1px solid black;
          border-right: 1px solid black;
          border-top: 1px solid black;
          ${index === this.getActivity.length - 1 ? 'border-bottom: 1px solid black;' : ''}">
          <td style="padding: 10px;">#${item.id}</td>
          <td style="font-size: 12px;"><b>${item.lastname.toUpperCase()}</b><br>${item.firstname}</td>
          <td>${formattedDate}<br><span style="font-size: 12px;">${age ? `Âge: ${age} ans` : ''}</span></td>
          <td style="font-size: 12px; padding: 10px;">${item.phone ? item.phone : ''}</td>
          <td style="font-size: 12px;">${item.email ? item.email : ''}</td>
          <td style="font-size: 10px; padding: 10px;">${item.comment ? item.comment : ''}</td>
          <td style="font-size: 12px;">${item.structure ? item.structure : ''}</td>
        </tr>
      `;
      }).join('')}
  </tbody>
  <style>
  table, tr {
    page-break-inside: avoid;
  }
  </style>
`;

      table.style.width = "100%";
      table.style.pageBreakInside = "avoid"
      table.style.borderCollapse = "collapse";
      table.style.textAlign = "center";
      table.style.fontSize = "15px";

      // dateFormatee = `${jour}_${mois}_${annee}_${heure}_${minute}`;
// Supprimer les accents et remplacer les espaces par des underscores dans le nom de l'événement
      //const nomEvenement = removeAccents(this.getRegistration.name.replace(/ /g, '_'));
//const nomEvenement = "oru";
// Nom du fichier PDF avec la date formatée et le nom de l'événement sans accents
      // const nomFichierPDF = `${nomEvenement}_${dateFormatee}.pdf`;
      const nomFichierPDF = "dsf.pdf";

      const opt = {
        margin: 5,
        jsPDF: {
          format: 'a4',
          orientation: 'landscape'
        },
        html2canvas: {
          scale: 2,
          pagebreak: {mode: ['avoid-all']}
        }
      };

      await html2pdf()
          .set(opt)
          .from(table)
          .toPdf()
          .get('pdf')
          .then(function (pdf) {
            // Regarder si la couleur est bien noire ici avant de sauvegarder
            pdf.save(nomFichierPDF);
          });
      this.loadingButtonPDF = false;
      this.colorPDF = "blue-grey-darken-4";
    }
  },
}
</script>
<style scoped>
.blink {
  animation: blink-animation 0.8s infinite; /* Durée plus longue pour un clignotement plus visible */
}

@keyframes blink-animation {
  0%, 70% {
    visibility: visible;
  }
  71%, 100% {
    visibility: hidden;
  }
}
</style>