import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('Le service worker est actif.');
    },
    registered() {
      console.log('Le service worker a été enregistré.');
    },
    cached() {
      console.log('Le contenu a été mis en cache pour une utilisation hors ligne.');
    },
    updatefound() {
      console.log('Une nouvelle version du service worker est en cours d’installation...');
    },
    updated(register) {
      console.log('Nouvelle version détectée !');

      if (register && register.waiting) {
        console.log('Un nouveau Service Worker est prêt à être activé.');

        // Envoyer un message au Service Worker pour qu'il se met à jour immédiatement
        register.waiting.postMessage({ type: 'SKIP_WAITING' });

        // Écouter l'activation du SW avant de recharger
        register.waiting.addEventListener('statechange', (event) => {
          if (event.target.state === 'activated') {
            console.log('Mise à jour appliquée, rechargement de la page...');
            window.location.reload();
          }
        });
      }
    },
    offline() {
      console.log('Pas de connexion internet. L’application fonctionne en mode hors ligne.');
    },
    error(error) {
      console.error('Erreur lors de l’enregistrement du service worker:', error);
    }
  });
}

window.addEventListener('load', () => {
  localStorage.removeItem('swUpdated');
});