<template>
  <ErrorComponent />
</template>

<script>
import { defineComponent } from 'vue';
import ErrorComponent from "@/components/Error/ErrorComponent";

export default defineComponent({
  name: 'ErrorView',
  components: {ErrorComponent},
});
</script>