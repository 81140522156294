<template>
  <v-footer :app="true" class="bg-blue-grey-darken-4 pa-0 d-flex flex-column">
    <template v-if="mdAndUp">
      <div class="px-4 py-2 text-center w-100">
        <p>{{ date }} - Ville Plurielle ©</p>
        <p class="text-caption cursor-pointer" @click="goTo('https://harmant-mathieu.fr')">Développeur Mathieu HARMANT</p>
      </div>
    </template>
    <template v-else>
      <div class="text-center w-100" style="font-size: 8px">
        <p>{{ date }} - Ville Plurielle ©</p>
        <p class="mb-3 cursor-pointer" @click="goTo('https://harmant-mathieu.fr')">Développeur Mathieu HARMANT</p>
      </div>
    </template>
  </v-footer>
</template>
<script>
import {useDisplay} from "vuetify";

export default {
  name: 'FooterComponent',
  data() {
    return {
      date: new Date().getFullYear()
    }
  },
  setup() {
    const { mdAndUp } = useDisplay()
    return { mdAndUp }
  },
  methods: {
    goTo(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
