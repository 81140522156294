<template>
  <v-dialog v-model="getDialogRegistrationShow" max-width="1200" @update:modelValue="toggleDialog">
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5">
          <v-icon size="small" class="mr-1" icon="mdi-plus"></v-icon>Sélectionner les fiches à inscrire
        </div>
        <v-btn
            icon="mdi-close"
            variant="text"
            @click="toggleDialog"
        ></v-btn>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-data-table
            @update:modelValue="updateSelectedItems"
            show-select
            ref="dataTable"
            @click:row="rowClick"
            :class="['elevation-0', { 'disable-table': loadingDataTableSheet }, {'mobile-datatable': !mdAndUp }]"
            :headers="headersSheet"
            :items="getSheet"
            items-per-page="0"
            @update:page="callGetSheet"
            @update:items-per-page="callGetSheet"
            :loading="loadingDataTableSheet"
        >
          <template v-slot:top>
            <template v-if="mdAndUp">
              <v-row class="px-2 pt-2 bg-grey-lighten-4" :no-gutters="true">
                <v-col cols="12" class="d-flex justify-space-between align-content-center align-center">
                  <v-tooltip text="Créer une fiche" location="top" class="add-tooltip-color">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" icon="mdi-plus" @click.stop="showDialogType(null, 0)" class="mr-2" density="comfortable" color="green-darken-1" variant="flat"></v-btn>
                    </template>
                  </v-tooltip>
                  <v-text-field :disabled="loadingInputSearch" :loading="loadingInputSearch" @input="detectSearchSheet()" @click:clear="clearSearch()" class="w-50" v-model="inputSearchSheet" prepend-inner-icon="mdi-magnify" label="Rechercher une fiche" single-line hide-details :clearable="true">
                    <template v-slot:append-inner>
                      <v-progress-circular
                          v-if="loadingInputSearch"
                          indeterminate
                          size="20"
                          width="4"
                          color="blue-grey-darken-4"
                      ></v-progress-circular>
                    </template>
                  </v-text-field>
                  <v-select
                      :disabled="loadingInputStructure"
                      :loading="loadingInputStructure"
                      v-model="inputSelectStructure"
                      @update:modelValue="callGetSheet(inputSearchSheet, inputSelectLimitSheet, 1, inputSelectStructure)"
                      prepend-inner-icon="mdi-domain"
                      class="ml-2 w-25"
                      hide-details
                      label="Sélectionner un lieu"
                      :items="itemsSelectStructure"
                  >
                    <template v-slot:append-inner>
                      <v-progress-circular
                          v-if="loadingInputStructure"
                          color="blue-grey-darken-4"
                          indeterminate
                          size="20"
                          width="4"
                      ></v-progress-circular>
                    </template>
                  </v-select>
                  <v-tooltip text="Rafraîchir les fiches" location="top" class="default-tooltip-color">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" icon="mdi-refresh" class="ml-2" density="comfortable" :loading="loadingButtonRefresh" :color="colorButtonRefresh" variant="flat" @click="callGetSheet(inputSearchSheet, inputSelectLimitSheet, 1, inputSelectStructure)"></v-btn>
                    </template>
                  </v-tooltip>
                  <v-tooltip v-if="showFilter === false" text="Afficher les filtres" location="top" class="default-tooltip-color">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" :icon="iconFilter" class="ml-2" density="comfortable" :color="colorFilter" variant="flat" @click="openFilter()"></v-btn>
                    </template>
                  </v-tooltip>
                  <v-tooltip v-else text="Cacher les filtres" location="top" class="default-tooltip-color">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" :icon="iconFilter" class="ml-2" density="comfortable" :color="colorFilter" variant="flat" @click="openFilter()"></v-btn>
                    </template>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row class="px-2 pt-2 bg-grey-lighten-4" :no-gutters="true">
                <v-col cols="12" class="d-flex justify-space-between align-content-center align-center">
                  <v-tooltip text="Créer une fiche" location="top" class="add-tooltip-color">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" icon="mdi-plus" @click.stop="showDialogType(null, 0)" class="mr-2" density="comfortable" color="green-darken-1" variant="flat"></v-btn>
                    </template>
                  </v-tooltip>
                  <v-tooltip text="Rafraîchir les fiches" location="top" class="default-tooltip-color">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" icon="mdi-refresh" class="ml-2" density="comfortable" :loading="loadingButtonRefresh" :color="colorButtonRefresh" variant="flat" @click="callGetSheet(inputSearchSheet, inputSelectLimitSheet, 1, inputSelectStructure)"></v-btn>
                    </template>
                  </v-tooltip>
                  <v-tooltip v-if="showFilter === false" text="Afficher les filtres" location="top" class="default-tooltip-color">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" :icon="iconFilter" class="ml-2" density="comfortable" :color="colorFilter" variant="flat" @click="openFilter()"></v-btn>
                    </template>
                  </v-tooltip>
                  <v-tooltip v-else text="Cacher les filtres" location="top" class="default-tooltip-color">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" :icon="iconFilter" class="ml-2" density="comfortable" :color="colorFilter" variant="flat" @click="openFilter()"></v-btn>
                    </template>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" class="d-flex justify-space-between align-content-center align-center">
                  <v-select
                      :disabled="loadingInputStructure"
                      :loading="loadingInputStructure"
                      v-model="inputSelectStructure"
                      @update:modelValue="callGetSheet(inputSearchSheet, inputSelectLimitSheet, 1, inputSelectStructure)"
                      prepend-inner-icon="mdi-domain"
                      class="mt-2 w-100"
                      hide-details
                      label="Sélectionner un lieu"
                      :items="itemsSelectStructure"
                  >
                    <template v-slot:append-inner>
                      <v-progress-circular
                          v-if="loadingInputStructure"
                          color="blue-grey-darken-4"
                          indeterminate
                          size="20"
                          width="4"
                      ></v-progress-circular>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" class="d-flex justify-space-between align-content-center align-center">
                  <v-text-field :disabled="loadingInputSearch" :loading="loadingInputSearch" @input="detectSearchSheet()" @click:clear="clearSearch()" class="w-100 mt-2" v-model="inputSearchSheet" prepend-inner-icon="mdi-magnify" label="Rechercher une fiche" single-line hide-details :clearable="true">
                    <template v-slot:append-inner>
                      <v-progress-circular
                          v-if="loadingInputSearch"
                          indeterminate
                          size="20"
                          width="4"
                          color="blue-grey-darken-4"
                      ></v-progress-circular>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
            <v-row class="px-2 pt-1 bg-grey-lighten-4" :no-gutters="true">
              <v-col cols="12" class="d-flex justify-end align-content-center align-center">
                <template v-if="codeRefresh === 0">
                  <v-progress-circular class="mr-2" model-value="20" :indeterminate="true" :width="1" :size="12"></v-progress-circular>
                  <p class="text-caption text-gray font-weight-bold">Mise à jour des données en cours...</p>
                </template>
                <template v-if="codeRefresh === 1">
                  <v-icon icon="mdi-information" color="green" class="mr-1" size="small"></v-icon>
                  <p class="text-caption text-green font-weight-bold" v-if="timeLastRefreshSheet === 1">Donnée mise à jour il y a {{ timeLastRefreshSheet }} seconde</p>
                  <p class="text-caption text-green font-weight-bold" v-else>Donnée mise à jour il y a {{ timeLastRefreshSheet }} secondes</p>
                </template>
                <template v-if="codeRefresh === 2">
                  <v-icon icon="mdi-alert" color="red" class="mr-1" size="small"></v-icon>
                  <p class="text-caption text-red font-weight-bold">Impossible de mettre à jour les données</p>
                </template>
                <template v-if="codeRefresh === 3">
                  <v-icon icon="mdi-pause-circle" color="gray" class="mr-1" size="small"></v-icon>
                  <p class="text-caption text-gray font-weight-bold">Mise à jour en pause lors d'ajout, modification ou suppresion</p>
                </template>
              </v-col>
            </v-row>
            <v-row v-if="selectedItems.length > 0" class="d-flex flex-wrap mb-4">
              <v-col>
                <h2>Vos fiches sélectionnées :</h2>
                <v-chip
                    v-for="item in selectedItems"
                    :key="item.id"
                    class="mr-2 font-weight-bold"
                >
                  {{ item.lastname }} {{ item.firstname }}
                </v-chip>
              </v-col>
            </v-row>
            <!--START SECTION FILTER-->
            <v-row class="px-2 bg-grey-lighten-4" :no-gutters="true" v-if="showFilter">
              <v-col cols="12" class="d-flex justify-start align-content-center align-center flex-row">
                <v-row :no-gutters="true">
                  <v-col cols="auto">
                    <v-checkbox color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterId" style="font-size: 10px;" class="mr-3" density="compact" hide-details @update:modelValue="updateFilter()">
                      <template v-slot:label>
                        <p class="text-subtitle-2">Identifiant</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox :disabled="disableFilterIfFew('checkboxFilterLastname')"  color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterLastname" style="font-size: 10px;" class="mr-3" density="compact" hide-details @update:modelValue="updateFilter()">
                      <template v-slot:label>
                        <p class="text-subtitle-2">Nom</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox :disabled="disableFilterIfFew('checkboxFilterFirstname')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterFirstname" style="font-size: 10px;" class="mr-3" density="compact" hide-details @update:modelValue="updateFilter()">
                      <template v-slot:label>
                        <p class="text-subtitle-2">Prénom</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox :disabled="disableFilterIfFew('checkboxFilterBirthday')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterBirthday" style="font-size: 10px;" class="mr-3" density="compact" hide-details @update:modelValue="updateFilter()">
                      <template v-slot:label>
                        <p class="text-subtitle-2">Naissance</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox :disabled="disableFilterIfFew('checkboxFilterPhone')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterPhone" style="font-size: 10px;" class="mr-3" density="compact" hide-details @update:modelValue="updateFilter()">
                      <template v-slot:label>
                        <p class="text-subtitle-2">Téléphone</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox :disabled="disableFilterIfFew('checkboxFilterEmail')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterEmail" style="font-size: 10px;" class="mr-3" density="compact" hide-details @update:modelValue="updateFilter()">
                      <template v-slot:label>
                        <p class="text-subtitle-2">E-mail</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox :disabled="disableFilterIfFew('checkboxFilterComment')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterComment" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                      <template v-slot:label>
                        <p class="text-subtitle-2">Commentaire</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox :disabled="disableFilterIfFew('checkboxFilterStructure')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterStructure" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                      <template v-slot:label>
                        <p class="text-subtitle-2">Structure</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox :disabled="disableFilterIfFew('checkboxFilterCreatedAt')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterCreatedAt" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                      <template v-slot:label>
                        <p class="text-subtitle-2">Date de création</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox :disabled="disableFilterIfFew('checkboxFilterModifiedAt')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterModifiedAt" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                      <template v-slot:label>
                        <p class="text-subtitle-2">Date de modification</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox :disabled="disableFilterIfFew('checkboxFilterCreatedBy')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterCreatedBy" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                      <template v-slot:label>
                        <p class="text-subtitle-2">Créer par</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox :disabled="disableFilterIfFew('checkboxFilterModifiedBy')" color="blue-grey-darken-2" v-model="filterCheckbox.checkboxFilterModifiedBy" style="font-size: 10px;" class="mr-3" density="compact" hide-details label="Checkbox" @update:modelValue="updateFilter()">
                      <template v-slot:label>
                        <p class="text-subtitle-2">Modifier par</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--END SECTION FILTER-->
          </template>
          <template v-slot:[`item.birthday`]="{ item }">
            <p v-if="item.birthday !== null">{{ calculateAge(item.birthday) }} ans ({{formatDate(item.birthday)}})<v-icon color="amber" icon="mdi-cake-variant" v-if="isBirthday(item.birthday)"></v-icon></p>
            <p v-else class="text-center">-</p>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <p v-if="item.phone !== null">{{item.phone}}</p>
            <p v-else class="text-center">-</p>
          </template>
          <template v-slot:[`item.structure`]="{ item }">
            <template v-if="item.structure !== [] && item.structure !== null">
              <v-chip density="compact" size="small" class="mr-1" v-for="(snackbar, index) in item.structure" :key="index">{{snackbar}}</v-chip>
            </template>
            <template v-else>
              <v-chip density="compact" size="small">TOUS</v-chip>
            </template>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <p v-if="item.email !== null">{{item.email}}</p>
            <p v-else class="text-center">-</p>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <p>{{formatDate(item.createdAt, true)}}</p>
          </template>
          <template v-slot:[`item.modifiedAt`]="{ item }">
            <p>{{formatDate(item.modifiedAt, true)}}</p>
          </template>
          <template v-slot:no-data>
            <v-empty-state icon="mdi-magnify" text="Essayez de modifier vos termes de recherche. Il se peut aussi qu'il n'y ait pas de fiche disponible dans ce cas, vous pouvez en créer une en cliquant sur le bouton ci-dessous." title="Aucune fiche">
              <template v-slot:actions>
                <v-btn prepend-icon="mdi-plus" color="green-darken-1" text="Créer une fiche"></v-btn>
              </template>
            </v-empty-state>
          </template>
          <template v-slot:bottom v-if="getSheetPagination.current_page">
            <template v-if="mdAndUp">
              <v-row :no-gutters="true" class="pa-2">
                <v-col cols="12" class="d-flex justify-end align-center align-content-center">
                  <p class="mr-2">Fiches par page :</p>
                  <v-select
                      :disabled="loadingInputLimitSheet"
                      :loading="loadingInputLimitSheet"
                      class="mr-2"
                      density="compact"
                      hide-details
                      max-width="100"
                      :items="['5', '10', '20', '50', '100']"
                      v-model="inputSelectLimitSheet"
                      @update:modelValue="goToSheetPagination('updateLimit')"
                  ></v-select>
                  <v-btn
                      class="mr-1"
                      density="comfortable"
                      :disabled="!getSheetPagination.has_previous_page || loadingButtonFirstPage"
                      icon="mdi-page-first"
                      @click="goToSheetPagination('first')"
                  ></v-btn>
                  <v-btn
                      density="comfortable"
                      :disabled="!getSheetPagination.has_previous_page || loadingButtonPreviousPage"
                      icon="mdi-chevron-left"
                      @click="goToSheetPagination('previous')"
                  ></v-btn>
                  <span class="mx-2">Page <b v-if="getSheetPagination.total_pages > 0">{{ getSheetPagination.current_page }}</b><b v-else>0</b> sur <b>{{ getSheetPagination.total_pages }}</b></span>
                  <v-btn
                      class="mr-1"
                      density="comfortable"
                      :disabled="!getSheetPagination.has_next_page || loadingButtonNextPage"
                      icon="mdi-chevron-right"
                      @click="goToSheetPagination('next')"
                  ></v-btn>
                  <v-btn
                      density="comfortable"
                      :disabled="!getSheetPagination.has_next_page || loadingButtonLastPage"
                      icon="mdi-page-last"
                      @click="goToSheetPagination('last')"
                  ></v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row :no-gutters="true" class="pa-2">
                <v-col cols="12" class="d-flex justify-center align-center align-content-center">
                  <p class="mr-2">Fiches par page :</p>
                  <v-select
                      :disabled="loadingInputLimitSheet"
                      :loading="loadingInputLimitSheet"
                      class="mr-2"
                      density="compact"
                      hide-details
                      :items="['5', '10', '20', '50', '100']"
                      v-model="inputSelectLimitSheet"
                      @update:modelValue="goToSheetPagination('updateLimit')"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="mt-3 d-flex justify-center align-center align-content-center">
                  <v-btn
                      class="mr-5"
                      density="comfortable"
                      :disabled="!getSheetPagination.has_previous_page || loadingButtonFirstPage"
                      icon="mdi-page-first"
                      @click="goToSheetPagination('first')"
                  ></v-btn>
                  <v-btn
                      density="comfortable"
                      :disabled="!getSheetPagination.has_previous_page || loadingButtonPreviousPage"
                      icon="mdi-chevron-left"
                      @click="goToSheetPagination('previous')"
                  ></v-btn>
                  <span class="mx-2">Page <b v-if="getSheetPagination.total_pages > 0">{{ getSheetPagination.current_page }}</b><b v-else>0</b> sur <b>{{ getSheetPagination.total_pages }}</b></span>
                  <v-btn
                      class="mr-5"
                      density="comfortable"
                      :disabled="!getSheetPagination.has_next_page || loadingButtonNextPage"
                      icon="mdi-chevron-right"
                      @click="goToSheetPagination('next')"
                  ></v-btn>
                  <v-btn
                      density="comfortable"
                      :disabled="!getSheetPagination.has_next_page || loadingButtonLastPage"
                      icon="mdi-page-last"
                      @click="goToSheetPagination('last')"
                  ></v-btn>
                </v-col>
              </v-row>
            </template>
          </template>
          <template v-slot:bottom v-else></template>
        </v-data-table>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="position-fixed bg-white w-100" style="bottom: -20px;">
        <v-spacer></v-spacer>

        <v-btn
            text="Annuler"
            color="red"
            @click="toggleDialog"
            variant="elevated"
        ></v-btn>

        <v-btn
            variant="elevated"
            color="green"
            text="Confirmer l'ajout"
            @click="confirmAddSheet"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <DialogSheetComponent :type="typeDialogSheet" :data="dataDialog" @finish="callGetSheet(inputSearchSheet, inputSelectLimitSheet, getSheetPagination.current_page , inputSelectStructure)" />
</template>
<script>
import {registrationStore} from "@/store/registrationStore";
import DialogSheetComponent from "@/components/Sheet/DialogSheetComponent";
import {sheetStore} from "@/store/sheetStore";
import {useDisplay} from "vuetify";
import {generalStore} from "@/store/General/generalStore";
import {activityStore} from "@/store/activityStore";

export default {
  name: 'DialogRegistrationComponent',
  components: {DialogSheetComponent},
  props: {
    type: Number,
    data: Object,
  },
  created() {
    sheetStore().callGetSheet('', 9999, 1);
  },
  setup() {
    const { mdAndUp } = useDisplay()
    return { mdAndUp }
  },
  data() {
    return {
      //START DATA SEARCH
      loadingInputSearch: false,
      timeoutTypingSearch: null,
      inputSearchSheet: null,
      //END DATA SEARCH
      //START DATA SEARCH STRUCTURE
      loadingInputStructure: false,
      inputSelectStructure: 'TOUS',
      itemsSelectStructure: process.env.VUE_APP_STRUCTURES.split(','),
      //END DATA SEARCH STRUCTURE
      //START DATA FILTER
      showFilter: false,
      iconFilter: "mdi-filter",
      colorFilter: "blue-grey-darken-4",
      filterCheckbox: {
        checkboxFilterId: false,
        checkboxFilterLastname: true,
        checkboxFilterFirstname: true,
        checkboxFilterBirthday: true,
        checkboxFilterPhone: true,
        checkboxFilterEmail: true,
        checkboxFilterComment: false,
        checkboxFilterStructure: false,
        checkboxFilterCreatedAt: false,
        checkboxFilterModifiedAt: false,
        checkboxFilterCreatedBy: false,
        checkboxFilterModifiedBy: false,
      },
      //END DATA FILTER
      //START DATA TABLE
      selectedIds: [], // IDs sélectionnés
      selectedItems: [], // Objets sélectionnés

      loadingButtonRefresh: false,
      loadingDataTableSheet: false,
      colorButtonRefresh: "blue-grey-darken-4",
      headersSheet: [
        { title: 'Nom', align: 'start', key: 'lastname', sortable: false},
        { title: 'Prénom', align: 'start', sortable: true, key: 'firstname'},
        { title: 'Naissance', align: 'start', key: 'birthday', sortable: true},
        { title: 'Téléphone', align: 'start', key: 'phone', sortable: true},
        { title: 'Email', align: 'start', key: 'email', sortable: true},
      ],
      //END DATA TABLE
      //START DATA PAGINATION TABLE
      inputSelectLimitSheet: '9999',
      loadingInputLimitSheet: false,
      loadingButtonNextPage: false,
      loadingButtonPreviousPage: false,
      loadingButtonFirstPage: false,
      loadingButtonLastPage: false,
      //END DATA PAGINATION TABLE
      //START DATA DIALOG SHEET
      dataDialog: null,
      typeDialogSheet: 0,
      //END DATA DIALOG SHEET
      //START DATA CODE REFRESH
      codeRefresh: 0,
      timerLastRefresh: null,
      timeLastRefreshSheet: 1,
      timer: null,
      timerLeft: 10,
      //END DATA CODE REFRESH
      //START DATA PRINTER AND PDF
      colorPrinter: "blue-grey-darken-4",
      colorPDF: "blue-grey-darken-4",
      loadingButtonPrinter: false,
      loadingButtonPDF: false,
      //END DATA PRINTER AND PDF
    };
  },
  unmounted() {
    clearInterval(this.timerLastRefresh);
  },
  computed: {
    getSheetPagination() {
      return sheetStore().getSheetPagination;
    },
    getSheet() {
      return sheetStore().getSheet;
    },
    getDialogRegistrationShow: {
      get() {
        return registrationStore().getDialogRegistrationShow;
      },
      set() {
        registrationStore().toogleDialogRegistration;
      }
    }
  },
  methods: {
    async confirmAddSheet() {
      let transformedArray = this.selectedIds.map((id) => ({
        id,
        total: 1,
        payement: false,
      }));
      try {
        await registrationStore().callPostRegistration(activityStore().getActivityId.id, transformedArray);
        this.toggleDialog();
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors des inscriptions", "red-darken-3", true, 5000);
      }
    },
    //START METHOD SEARCH
    detectSearchSheet() {
      if (this.timeoutTypingSearch) {
        clearTimeout(this.timeoutTypingSearch);
      }
      this.timeoutTypingSearch = setTimeout(async () => {
        this.loadingInputSearch = true;
        await this.callGetSheet(this.inputSearchSheet, this.inputSelectLimitSheet, 1, this.inputSelectStructure)
        this.loadingInputSearch = false;
      }, 1000);
    },
    async clearSearch() {
      if (this.timeoutTypingSearch) {
        clearTimeout(this.timeoutTypingSearch);
      }
      this.loadingInputSearch = true;
      await this.callGetSheet(this.inputSearchSheet, this.inputSelectLimitSheet, 1, this.inputSelectStructure)
      this.loadingInputSearch = false;
    },
    //END METHOD SEARCH
    //START METHOD SEARCH STRUCTURE

    //END METHOD SEARCH STRUCTURE
    //START METHOD FILTER
    openFilter() {
      this.showFilter = !this.showFilter;
      this.showFilter ? this.iconFilter = "mdi-filter-off" : this.iconFilter = "mdi-filter";
      this.showFilter ? this.colorFilter = "blue-grey-lighten-1" : this.colorFilter = "blue-grey-darken-4";
    },
    updateSelectedItems(newSelectedIds) {
      this.selectedIds = newSelectedIds;
      this.selectedItems = this.getSheet.filter((item) =>
          newSelectedIds.includes(item.id)
      );
    },
    updateFilter() {
      this.headersSheet = [];
      if (this.filterCheckbox.checkboxFilterId) {
        this.headersSheet.push({title: 'Identifiant', align: 'start', key: 'id', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterLastname) {
        this.headersSheet.push({title: 'Nom', align: 'start', key: 'lastname', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterFirstname) {
        this.headersSheet.push({title: 'Prénom', align: 'start', key: 'firstname', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterBirthday) {
        this.headersSheet.push({title: 'Naissance', align: 'start', key: 'birthday', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterPhone) {
        this.headersSheet.push({title: 'Téléphone', align: 'start', key: 'phone', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterEmail) {
        this.headersSheet.push({title: 'E-mail', align: 'start', key: 'email', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterComment) {
        this.headersSheet.push({title: 'Commentaire', align: 'start', key: 'comment', sortable: false});
      }
      if (this.filterCheckbox.checkboxFilterStructure) {
        this.headersSheet.push({title: 'Structures', align: 'center', key: 'structure', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterCreatedAt) {
        this.headersSheet.push({title: 'Crée le', align: 'center', key: 'createdAt', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterModifiedAt) {
        this.headersSheet.push({title: 'Modifié le', align: 'center', key: 'modifiedAt', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterCreatedBy) {
        this.headersSheet.push({title: 'Crée par', align: 'center', key: 'createdBy', sortable: true});
      }
      if (this.filterCheckbox.checkboxFilterModifiedBy) {
        this.headersSheet.push({title: 'Modifié par', align: 'center', key: 'modifiedBy', sortable: true});
      }
      this.headersSheet.push({title: '', align: 'start', key: 'action', sortable: false});
    },
    disableFilterIfFew(checkboxName) {
      const checkedCount = Object.values(this.filterCheckbox).filter(value => value).length;
      return checkedCount <= 3 && this.filterCheckbox[checkboxName];
    },
    //END METHOD FILTER

    //START METHOD PAGINATION TABLE
    goToSheetPagination(action) {
      let page;
      switch (action) {
        case 'first':
          page = 1;
          break;
        case 'last':
          page = this.getSheetPagination.total_pages;
          break;
        case 'next':
          page = this.getSheetPagination.current_page + 1;
          break;
        case 'previous':
          page = this.getSheetPagination.current_page - 1;
          break;
        case 'updateLimit':
          page = 1;
          break;
        default:
          page = this.getSheetPagination.current_page;
          break;
      }
      this.callGetSheet(this.inputSearchSheet, this.inputSelectLimitSheet, page, this.inputSelectStructure);
    },
    //END METHOD PAGINATION TABLE

    //START METHOD DIALOG SHEET
    showDialogType(item, typeDialog) {
      clearInterval(this.timerLastRefresh);
      this.codeRefresh = 3;
      if (item !== null) {
        this.dataDialog = item;
      }
      this.typeDialogSheet = typeDialog;
      sheetStore().dialogSheetShow = true;
    },
    //END METHOD DIALOG SHEET

    //START METHOD FORMAT DATE AND BIRTHDAY
    formatDate(dateValue, withTime = false) {
      if (!dateValue || typeof dateValue !== 'object') {
        return "";
      }
      if (!dateValue.date) {
        return "Date invalide";
      }
      dateValue = dateValue.date;
      const cleanDate = dateValue.split('.')[0].replace(' ', 'T');
      const date = new Date(cleanDate);
      // Vérifier si la date est valide
      if (isNaN(date)) return "Date invalide";
      const options = {
        timeZone: 'Europe/Paris',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
      if (withTime) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.second = '2-digit';
      }
      return date.toLocaleString('fr-FR', options);
    },
    isBirthday(birthday) {
      const cleanBirthday = this.getCleanDate(birthday);
      if (!cleanBirthday) return false;
      const today = new Date();
      const birthDate = new Date(cleanBirthday);
      return today.getMonth() === birthDate.getMonth() && today.getDate() === birthDate.getDate();
    },
    calculateAge(birthday) {
      const cleanBirthday = this.getCleanDate(birthday);
      if (!cleanBirthday) return "";
      const today = new Date();
      const birthDate = new Date(cleanBirthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    toggleDialog() {
        registrationStore().dialogRegistrationShow = !registrationStore().dialogRegistrationShow;
        this.$emit('finish');
    },
    getCleanDate(dateValue) {
      if (!dateValue) return null;
      if (typeof dateValue === 'object' && dateValue.date) {
        dateValue = dateValue.date;
      }
      const cleanDate = dateValue.split('.')[0].replace(' ', 'T');
      const date = new Date(cleanDate);
      if (isNaN(date)) return null;
      return cleanDate;
    },
    //END METHOD FORMAT DATE AND BIRTHDAY

    //START METHOD CHANGE WINDOW
    goToWindow(state) {
      sheetStore().stateWindowSheet = state;
    },
    //END METHOD CHANGE WINDOW

    //START METHOD CALL API
    async callGetSheet(search = null, limit = 10, page = 1, structure = "TOUS") {
      this.codeRefresh = 0;
      this.loadingButtonRefresh = true;
      this.loadingInputLimitSheet = true;
      this.loadingInputStructure = true;
      this.loadingInputSearch = true;
      this.loadingButtonFirstPage = true;
      this.loadingButtonPreviousPage = true;
      this.loadingButtonLastPage = true;
      this.loadingButtonNextPage = true;
      this.colorButtonRefresh = "blue-grey-lighten-1";
      try {
        const response = await sheetStore().callGetSheet(search, limit, page, structure);
        if (response) {
          this.codeRefresh = 1;
        } else {
          clearInterval(this.timerLastRefresh);
          this.codeRefresh = 2;
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la récupération des fiches !", "red-darken-3", true, 5000);
        clearInterval(this.timerLastRefresh);
        this.codeRefresh = 2;
      }
      this.loadingButtonRefresh = false;
      this.loadingInputLimitSheet = false;
      this.loadingInputStructure = false;
      this.loadingInputSearch = false;
      this.loadingButtonFirstPage = false;
      this.loadingButtonPreviousPage = false;
      this.loadingButtonLastPage = false;
      this.loadingButtonNextPage = false;
      this.colorButtonRefresh = "blue-grey-darken-4";
    },
  }
}
</script>