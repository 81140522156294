<template>
  <v-dialog v-if="mdAndUp" v-model="getDialogSheetShow" max-width="600" @update:modelValue="toggleDialog" :persistent="disableLoading">
    <v-card v-if="type === 0">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5 font-weight-bold">
          <v-icon size="small" class="mr-1" icon="mdi-plus"></v-icon>Créer une Fiche {{addSheetForm.inputAddFirstname}} {{addSheetForm.inputAddLastname}}
        </div>
        <v-btn
            icon="mdi-close"
            variant="text"
            @click="toggleDialog"
        ></v-btn>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-row dense>
          <v-col cols="12" md="4" sm="6">
            <v-text-field
                required
                v-model="addSheetForm.inputAddFirstname" :error-messages="v$.addSheetForm.inputAddFirstname.$errors.map(e => e.$message)" @input="v$.addSheetForm.inputAddFirstname.$touch" @blur="v$.addSheetForm.inputAddFirstname.$touch"
            >
              <template v-slot:label>
                <p>Prénom <span class="text-red-darken-1 font-weight-bold">*</span></p>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-text-field
                persistent-hint
                v-model="addSheetForm.inputAddLastname" :error-messages="v$.addSheetForm.inputAddLastname.$errors.map(e => e.$message)" @input="v$.addSheetForm.inputAddLastname.$touch" @blur="v$.addSheetForm.inputAddLastname.$touch"
                required
            >
              <template v-slot:label>
                <p>Nom <span class="text-red-darken-1 font-weight-bold">*</span></p>
              </template>
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="4"
              sm="6"
          >
            <v-text-field placeholder="jj/mm/aaaa" type="date" v-model="addSheetForm.inputAddBirthday" :error-messages="v$.addSheetForm.inputAddBirthday.$errors.map(e => e.$message)" @input="v$.addSheetForm.inputAddBirthday.$touch" @blur="v$.addSheetForm.inputAddBirthday.$touch">
            </v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-text-field
                label="E-mail"
                v-model="addSheetForm.inputAddEmail" :error-messages="v$.addSheetForm.inputAddEmail.$errors.map(e => e.$message)" @input="v$.addSheetForm.inputAddEmail.$touch" @blur="v$.addSheetForm.inputAddEmail.$touch"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-text-field
                v-model="addSheetForm.inputAddPhone" :error-messages="v$.addSheetForm.inputAddPhone.$errors.map(e => e.$message)" @input="v$.addSheetForm.inputAddPhone.$touch" @blur="v$.addSheetForm.inputAddPhone.$touch"
                label="Téléphone"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="12"
              sm="12"
          >
            <v-textarea persistent-hint hint="Ajouter un commentaire si vous pensez que c'est nécessaire" label="Commentaire" counter v-model="addSheetForm.inputAddComment" :error-messages="v$.addSheetForm.inputAddComment.$errors.map(e => e.$message)" @input="v$.addSheetForm.inputAddComment.$touch" @blur="v$.addSheetForm.inputAddComment.$touch">
              <template v-slot:counter="{ counter }">
                <template v-if="counter >= 501"><span class="text-red">{{counter}}/500</span></template>
                <template v-else>{{counter}}/500</template>
              </template>
            </v-textarea>
          </v-col>

          <v-col
              cols="12"
              sm="12"
          >
            <v-select
                v-model="addSheetForm.inputAddStructure"
                persistent-hint
                hint="Sélectionner une structure si la personne fréquente souvent une ou des structures. Laisser vide sinon"
                multiple
                chips
                clearable
                label="Select"
                :items="itemsSelectStructure">
            </v-select>
          </v-col>
        </v-row>

      </v-card-text>
      <small class="text-caption text-medium-emphasis ml-6"><span class="text-red font-weight-bold">*</span> Champs requis</small>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
            text="Annuler"
            variant="tonal"
            color="red-darken-1"
            :loading="disableLoading"
            @click="toggleDialog()"
        ></v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="green-darken-1"
            text="Confirmer"
            variant="tonal"
            :loading="disableLoading"
            @click="callPostSheet()"
        ></v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="type === 1">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5 font-weight-bold">
          <v-icon size="small" class="mr-1" icon="mdi-pencil"></v-icon>Modifier la fiche {{data.lastname}} {{data.firstname}}
        </div>
        <v-btn
            icon="mdi-close"
            variant="text"
            @click="toggleDialog"
        ></v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="4" sm="6">
            <v-text-field
                label="Prénom"
                required
                v-model="editSheetForm.inputEditFirstname" :error-messages="v$.editSheetForm.inputEditFirstname.$errors.map(e => e.$message)" @input="v$.editSheetForm.inputEditFirstname.$touch" @blur="v$.editSheetForm.inputEditFirstname.$touch"
            >
              <template v-slot:label>
                <p>Prénom <span class="text-red-darken-1 font-weight-bold">*</span></p>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-text-field
                hint="example of persistent helper text"
                persistent-hint
                v-model="editSheetForm.inputEditLastname" :error-messages="v$.editSheetForm.inputEditLastname.$errors.map(e => e.$message)" @input="v$.editSheetForm.inputEditLastname.$touch" @blur="v$.editSheetForm.inputEditLastname.$touch"
                required
            >
              <template v-slot:label>
                <p>Nom <span class="text-red-darken-1 font-weight-bold">*</span></p>
              </template>
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="4"
              sm="6"
          >
            <v-text-field placeholder="jj/mm/aaaa" type="date" v-model="editSheetForm.inputEditBirthday" :error-messages="v$.editSheetForm.inputEditBirthday.$errors.map(e => e.$message)" @input="v$.editSheetForm.inputEditBirthday.$touch" @blur="v$.editSheetForm.inputEditBirthday.$touch">
            </v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-text-field
                label="E-mail"
                v-model="editSheetForm.inputEditEmail" :error-messages="v$.editSheetForm.inputEditEmail.$errors.map(e => e.$message)" @input="v$.editSheetForm.inputEditEmail.$touch" @blur="v$.editSheetForm.inputEditEmail.$touch"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-text-field
                v-model="editSheetForm.inputEditPhone" :error-messages="v$.editSheetForm.inputEditPhone.$errors.map(e => e.$message)" @input="v$.editSheetForm.inputEditPhone.$touch" @blur="v$.editSheetForm.inputEditPhone.$touch"
                label="Téléphone"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="12"
              sm="12"
          >
            <v-textarea label="Commentaire" counter v-model="editSheetForm.inputEditComment" :error-messages="v$.editSheetForm.inputEditComment.$errors.map(e => e.$message)" @input="v$.editSheetForm.inputEditComment.$touch" @blur="v$.editSheetForm.inputEditComment.$touch">
              <template v-slot:counter="{ counter }">
                {{counter}}/45
              </template>
            </v-textarea>
          </v-col>

          <v-col
              cols="12"
              sm="12"
          >
            <v-select
                v-model="editSheetForm.inputEditStructure"
                persistent-hint
                hint="Sélectionner une structure si la personne fréquente souvent une ou des structures. Laisser vide sinon"
                multiple
                chips
                clearable
                label="Select"
                :items="['VILLE PLURIELLE', 'MAISON DE QUARTIER GRANDE FIN', 'MAISON DE QUARTIER GARE', 'MAISON DE QUARTIER BOIS DE LA SARRE', 'HOMETONS', 'LES DIABLOTINS', 'LES PETITES GRENOUILLES', 'LES PETITS CROCOS', 'LES PETITES FRIMOUSSES', 'LAEP JOEUF', 'LAEP HOMÉCOURT', 'REEAP']">
            </v-select>
          </v-col>
        </v-row>

      </v-card-text>
      <small class="text-caption text-medium-emphasis ml-6"><span class="text-red font-weight-bold">*</span> Champs requis</small>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
            text="Annuler"
            variant="tonal"
            color="red-darken-1"
            :loading="disableLoading"
            @click="toggleDialog()"
        ></v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="green-darken-1"
            text="Confirmer"
            variant="tonal"
            :loading="disableLoading"
            @click="callEditSheet()"
        ></v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="type === 2">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5 font-weight-bold">
          <v-icon size="small" class="mr-1" icon="mdi-delete"></v-icon>Supprimer la fiche de {{data.lastname}} {{data.firstname}} ?
        </div>
        <v-btn
            icon="mdi-close"
            variant="text"
            @click="toggleDialog()"
        ></v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        Êtes-vous sûr de vouloir supprimer la fiche de :
        <p class="text-center font-weight-bold my-2">"{{data.lastname}} {{data.firstname}}"</p>
        <v-alert variant="flat" class="text-subtitle-2"
                 prominent border="bottom" text="Attention, cette action est irréversible. Elle entraînera la suppression de la fiche, ainsi que des inscriptions aux activités et des paiements qui lui sont liés." type="warning"></v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
            text="Annuler"
            variant="tonal"
            color="red-darken-1"
            :loading="disableLoading"
            @click="toggleDialog()"
        ></v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="green-darken-1"
            text="Confirmer"
            variant="tonal"
            :loading="disableLoading"
            @click="callDeleteSheet()"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-else v-model="getDialogSheetShow" fullscreen @update:modelValue="toggleDialog" :persistent="disableLoading">
    <v-card v-if="type === 0">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-subtitle-1 font-weight-bold">
          <v-icon size="small" class="mr-1" icon="mdi-plus"></v-icon>Créer une Fiche {{addSheetForm.inputAddFirstname}} {{addSheetForm.inputAddLastname}}
        </div>
        <v-btn
            icon="mdi-close"
            variant="text"
            @click="toggleDialog"
        ></v-btn>
      </v-card-title>
      <v-card-text class="pa-0 px-2">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
                required
                v-model="addSheetForm.inputAddFirstname" :error-messages="v$.addSheetForm.inputAddFirstname.$errors.map(e => e.$message)" @input="v$.addSheetForm.inputAddFirstname.$touch" @blur="v$.addSheetForm.inputAddFirstname.$touch"
            >
              <template v-slot:label>
                <p>Prénom <span class="text-red-darken-1 font-weight-bold">*</span></p>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-text-field
                persistent-hint
                v-model="addSheetForm.inputAddLastname" :error-messages="v$.addSheetForm.inputAddLastname.$errors.map(e => e.$message)" @input="v$.addSheetForm.inputAddLastname.$touch" @blur="v$.addSheetForm.inputAddLastname.$touch"
                required
            >
              <template v-slot:label>
                <p>Nom <span class="text-red-darken-1 font-weight-bold">*</span></p>
              </template>
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="4"
              sm="6"
          >
            <v-text-field placeholder="jj/mm/aaaa" type="date" v-model="addSheetForm.inputAddBirthday" :error-messages="v$.addSheetForm.inputAddBirthday.$errors.map(e => e.$message)" @input="v$.addSheetForm.inputAddBirthday.$touch" @blur="v$.addSheetForm.inputAddBirthday.$touch">
            </v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-text-field
                label="E-mail"
                v-model="addSheetForm.inputAddEmail" :error-messages="v$.addSheetForm.inputAddEmail.$errors.map(e => e.$message)" @input="v$.addSheetForm.inputAddEmail.$touch" @blur="v$.addSheetForm.inputAddEmail.$touch"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-text-field
                v-model="addSheetForm.inputAddPhone" :error-messages="v$.addSheetForm.inputAddPhone.$errors.map(e => e.$message)" @input="v$.addSheetForm.inputAddPhone.$touch" @blur="v$.addSheetForm.inputAddPhone.$touch"
                label="Téléphone"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="12"
              sm="12"
          >
            <v-textarea persistent-hint hint="Ajouter un commentaire si vous pensez que c'est nécessaire" label="Commentaire" counter v-model="addSheetForm.inputAddComment" :error-messages="v$.addSheetForm.inputAddComment.$errors.map(e => e.$message)" @input="v$.addSheetForm.inputAddComment.$touch" @blur="v$.addSheetForm.inputAddComment.$touch">
              <template v-slot:counter="{ counter }">
                <template v-if="counter >= 501"><span class="text-red">{{counter}}/500</span></template>
                <template v-else>{{counter}}/500</template>
              </template>
            </v-textarea>
          </v-col>

          <v-col
              cols="12"
              sm="12"
          >
            <v-select
                v-model="addSheetForm.inputAddStructure"
                persistent-hint
                hint="Sélectionner une structure si la personne fréquente souvent une ou des structures. Laisser vide sinon"
                multiple
                chips
                clearable
                label="Select"
                :items="itemsSelectStructure">
            </v-select>
          </v-col>
        </v-row>

      </v-card-text>
      <small class="text-caption text-medium-emphasis ml-6"><span class="text-red font-weight-bold">*</span> Champs requis</small>
      <v-divider></v-divider>

      <v-card-actions class="mb-5">
        <v-btn
            text="Annuler"
            variant="tonal"
            color="red-darken-1"
            :loading="disableLoading"
            @click="toggleDialog()"
        ></v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="green-darken-1"
            text="Confirmer"
            variant="tonal"
            :loading="disableLoading"
            @click="callPostSheet()"
        ></v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="type === 1">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-subtitle-1 font-weight-bold">
          <v-icon size="small" class="mr-1" icon="mdi-pencil"></v-icon>Modifier la fiche {{data.lastname}} {{data.firstname}}
        </div>
        <v-btn
            icon="mdi-close"
            variant="text"
            @click="toggleDialog"
        ></v-btn>
      </v-card-title>
      <v-card-text class="pa-0 px-2">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
                label="Prénom"
                required
                v-model="editSheetForm.inputEditFirstname" :error-messages="v$.editSheetForm.inputEditFirstname.$errors.map(e => e.$message)" @input="v$.editSheetForm.inputEditFirstname.$touch" @blur="v$.editSheetForm.inputEditFirstname.$touch"
            >
              <template v-slot:label>
                <p>Prénom <span class="text-red-darken-1 font-weight-bold">*</span></p>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
                hint="example of persistent helper text"
                persistent-hint
                v-model="editSheetForm.inputEditLastname" :error-messages="v$.editSheetForm.inputEditLastname.$errors.map(e => e.$message)" @input="v$.editSheetForm.inputEditLastname.$touch" @blur="v$.editSheetForm.inputEditLastname.$touch"
                required
            >
              <template v-slot:label>
                <p>Nom <span class="text-red-darken-1 font-weight-bold">*</span></p>
              </template>
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
          >
            <v-text-field label="Date de naissance" type="date" v-model="editSheetForm.inputEditBirthday" :error-messages="v$.editSheetForm.inputEditBirthday.$errors.map(e => e.$message)" @input="v$.editSheetForm.inputEditBirthday.$touch" @blur="v$.editSheetForm.inputEditBirthday.$touch">
            </v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-text-field
                label="E-mail"
                v-model="editSheetForm.inputEditEmail" :error-messages="v$.editSheetForm.inputEditEmail.$errors.map(e => e.$message)" @input="v$.editSheetForm.inputEditEmail.$touch" @blur="v$.editSheetForm.inputEditEmail.$touch"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-text-field
                v-model="editSheetForm.inputEditPhone" :error-messages="v$.editSheetForm.inputEditPhone.$errors.map(e => e.$message)" @input="v$.editSheetForm.inputEditPhone.$touch" @blur="v$.editSheetForm.inputEditPhone.$touch"
                label="Téléphone"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="12"
              sm="12"
          >
            <v-textarea persistent-hint hint="Ajouter un commentaire si vous pensez que c'est nécessaire" label="Commentaire" counter v-model="editSheetForm.inputEditComment" :error-messages="v$.editSheetForm.inputEditComment.$errors.map(e => e.$message)" @input="v$.editSheetForm.inputEditComment.$touch" @blur="v$.editSheetForm.inputEditComment.$touch">
              <template v-slot:counter="{ counter }">
                <template v-if="counter >= 501"><span class="text-red">{{counter}}/500</span></template>
                <template v-else>{{counter}}/500</template>
              </template>
            </v-textarea>
          </v-col>

          <v-col
              cols="12"
              sm="12"
          >
            <v-select
                v-model="editSheetForm.inputEditStructure"
                persistent-hint
                hint="Sélectionner une structure si la personne fréquente souvent une ou des structures. Laisser vide sinon"
                multiple
                chips
                clearable
                label="Select"
                :items="['VILLE PLURIELLE', 'MAISON DE QUARTIER GRANDE FIN', 'MAISON DE QUARTIER GARE', 'MAISON DE QUARTIER BOIS DE LA SARRE', 'HOMETONS', 'LES DIABLOTINS', 'LES PETITES GRENOUILLES', 'LES PETITS CROCOS', 'LES PETITES FRIMOUSSES', 'LAEP JOEUF', 'LAEP HOMÉCOURT', 'REEAP']">
            </v-select>
          </v-col>
        </v-row>

      </v-card-text>
      <small class="text-caption text-medium-emphasis ml-6"><span class="text-red font-weight-bold">*</span> Champs requis</small>
      <v-divider></v-divider>

      <v-card-actions class="mb-5">
        <v-btn
            text="Annuler"
            variant="tonal"
            color="red-darken-1"
            :loading="disableLoading"
            @click="toggleDialog()"
        ></v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="green-darken-1"
            text="Confirmer"
            variant="tonal"
            :loading="disableLoading"
            @click="callEditSheet()"
        ></v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="type === 2">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-subtitle-1 font-weight-bold">
          <v-icon size="small" class="mr-1" icon="mdi-delete"></v-icon>Supprimer la fiche de {{data.lastname}} {{data.firstname}} ?
        </div>
        <v-btn
            icon="mdi-close"
            variant="text"
            @click="toggleDialog()"
        ></v-btn>
      </v-card-title>
      <v-card-text class="text-center d-flex justify-center align-content-center align-center flex-column">
        <div>
        Êtes-vous sûr de vouloir supprimer la fiche de :
        <p class="text-center font-weight-bold my-2">"{{data.lastname}} {{data.firstname}}"</p>
        <v-alert variant="flat" class="text-subtitle-2"
                 prominent border="bottom" text="Attention, cette action est irréversible. Elle entraînera la suppression de la fiche, ainsi que des inscriptions aux activités et des paiements qui lui sont liés." type="warning"></v-alert>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mb-5">
        <v-btn
            text="Annuler"
            variant="tonal"
            color="red-darken-1"
            :loading="disableLoading"
            @click="toggleDialog()"
        ></v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="green-darken-1"
            text="Confirmer"
            variant="tonal"
            :loading="disableLoading"
            @click="callDeleteSheet()"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import {sheetStore} from "@/store/sheetStore";
import {generalStore} from "@/store/General/generalStore";
import {useDisplay} from "vuetify";

const isValidDate = helpers.withMessage(
    'La date doit être une date valide au format complet.',
    value => {
      const date = new Date(value);
      return !isNaN(date.getTime()); // Vérifie si la date est valide
    }
);

export default {
  name: 'DialogSheetComponent',
  props: {
    type: Number,
    data: Object,
  },
  setup() {
    const { mdAndUp } = useDisplay()
    return {
      v$: useVuelidate(),
      mdAndUp
    }
  },
  validations() {
    return {
      addSheetForm: {
        inputAddFirstname: {
          required: helpers.withMessage('Le nom est requis.', required),
        },
        inputAddLastname: {
          required: helpers.withMessage('Le nom est requis.', required),
        },
        inputAddBirthday: {
          isValidDate
        },
        inputAddPhone: {
          validPhone: helpers.withMessage("Numéro avec 10 chiffres sans espace ni symbole.", (value) => !value || /^0[1-9][0-9]{8}$/.test(value))
        },
        inputAddEmail: {
          email: helpers.withMessage("E-mail non valide.", email),
          maxLength: helpers.withMessage('E-mail trop longue.',(value) => {
            return !value || value.length <= 255;
          })
        },
        inputAddComment: {
          maxLength: helpers.withMessage('Commentaire trop long.',(value) => {
            return !value || value.length <= 500;
          })
        }
      },
      editSheetForm: {
        inputEditLastname: {
          required: helpers.withMessage('Le nom est requis.', required),
        },
        inputEditFirstname: {
          required: helpers.withMessage('Le prénom est requis.', required),
        },
        inputEditBirthday: {

        },
        inputEditPhone: {
          validPhone: helpers.withMessage('Le format du numéro de téléphone doit être de 10 chiffres sans espace ni caractère spécial.', (value) => !value || /^0[1-9][0-9]{8}$/.test(value))
        },
        inputEditEmail: {
          email: helpers.withMessage("E-mail n'est pas une adresse e-mail valide.", email)
        },
        inputEditComment: {
          maxLength: helpers.withMessage('Commentaire trop long.',(value) => {
            return !value || value.length <= 500;
          })
        },
      },
    }
  },
  data() {
    return {
      addSheetForm: {
        inputAddFirstname: null,
        inputAddLastname: null,
        inputAddBirthday: null,
        inputAddPhone: null,
        inputAddEmail: null,
        inputAddComment: null,
        inputAddStructure: []
      },
      editSheetForm: {
        inputEditFirstname: null,
        inputEditLastname: null,
        inputEditBirthday: null,
        inputEditPhone: null,
        inputEditEmail: null,
        inputEditComment: null,
        inputEditStructure: [],
      },
      itemsSelectStructure: process.env.VUE_APP_STRUCTURES.split(','),
      disableLoading: false,
    }
  },
  computed: {
    getDialogSheetShow: {
      get() {
        return sheetStore().getDialogSheetShow;
      },
      set() {
        sheetStore().toogleDialogSheet;
      }
    }
  },
  methods: {
    async callPostSheet() {
      this.disableLoading = true;
      const result = await this.v$.addSheetForm.$validate();
      if (!result) {
        this.disableLoading = false;
        return false;
      }
      try {
        const response = await sheetStore().callPostSheet(this.addSheetForm.inputAddLastname, this.addSheetForm.inputAddFirstname, this.addSheetForm.inputAddBirthday, this.addSheetForm.inputAddPhone, this.addSheetForm.inputAddEmail, this.addSheetForm.inputAddComment, this.addSheetForm.inputAddStructure)
        if (response) {
          this.addSheetForm.inputAddLastname = "";
          this.addSheetForm.inputAddFirstname = "";
          this.addSheetForm.inputAddBirthday = null;
          this.addSheetForm.inputAddPhone = null;
          this.addSheetForm.inputAddEmail = null;
          this.addSheetForm.inputAddComment = null;
          this.addSheetForm.inputAddStructure = [];
          this.disableLoading = false;
          this.toggleDialog();
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la création de la fiche !", "red-darken-3", true, 5000);
      }
      this.disableLoading = false;
    },
    async callEditSheet() {
      this.disableLoading = true;
      const result = await this.v$.editSheetForm.$validate();
      if (!result) {
        this.disableLoading = false;
        return false;
      }
      try {
        const response = await sheetStore().callPutSheet(this.data.id, this.editSheetForm.inputEditLastname, this.editSheetForm.inputEditFirstname, this.editSheetForm.inputEditBirthday, this.editSheetForm.inputEditPhone, this.editSheetForm.inputEditEmail, this.editSheetForm.inputEditComment, this.editSheetForm.inputEditStructure)
        if (response) {
          this.disableLoading = false;
          this.toggleDialog();
          generalStore().showNotifSnackbar("Modification avec succès !", "light-green-darken-1", true, 5000);
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la connexion !", "red-darken-3", true, 5000);
      }
      this.disableLoading = false;
    },
    async callDeleteSheet() {
      this.disableLoading = true;
      try {
        const response = await sheetStore().callDeleteSheet(this.data.id, [])
        if (response) {
          this.disableLoading = false;
          this.toggleDialog();
          generalStore().showNotifSnackbar("Suppresion avec succès", "light-green-darken-1", true, 5000);
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la connexion !", "red-darken-3", true, 5000);
      }
      this.disableLoading = false;
    },
    toggleDialog() {
      if(this.disableLoading !== true) {
        sheetStore().dialogSheetShow = !sheetStore().getDialogSheetShow;
        this.$emit('finish');
      }
    },
  },
  watch: {
    getDialogSheetShow() {
      if(this.data !== null) {
        this.editSheetForm.inputEditLastname = this.data.lastname;
        this.editSheetForm.inputEditFirstname = this.data.firstname;
        if (this.data.birthday && this.data.birthday.date) {
          this.editSheetForm.inputEditBirthday = this.data.birthday.date.split(' ')[0];
        } else {
          this.editSheetForm.inputEditBirthday = null;
        }
        this.editSheetForm.inputEditComment = this.data.comment;
        this.editSheetForm.inputEditEmail = this.data.email;
        this.editSheetForm.inputEditPhone = this.data.phone;
        this.editSheetForm.inputEditStructure = this.data.structure;
      }
    }
  },
}
</script>