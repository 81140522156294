<template>
  <section class="fill-height">
    <v-row class="h-100 d-flex justify-center align-content-center align-center" no-gutters>
      <v-col cols="6" class="d-flex justify-center flex-column align-content-center align-center">
        <template v-if="mdAndUp">
          <v-avatar :image="require('@/assets/logo-vpconnect.png')" size="350"></v-avatar>
        </template>
        <template v-else>
          <v-avatar :image="require('@/assets/logo-vpconnect.png')" size="150"></v-avatar>
        </template>
        <div class="typewriter">
          <h1 class="text-h5 mt-2 mb-2 text-md-h3 mt-md-8 mb-md-0 font-weight-bold">Bienvenue sur VPConnect</h1>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <v-window v-model="windowLoginState" direction="vertical">
          <v-window-item :value="1">
            <h2 class="text-center mb-5 text-uppercase">S'authentifier</h2>
            <v-form class="px-5 px-md-15">
              <v-text-field
                  :loading="loginForm.loadingLogin"
                  v-model="loginForm.username"
                  label="Identifiant"
                  prepend-inner-icon="mdi-account"
                  variant="solo"
                  class="mb-5"
                  :error-messages="v$.loginForm.username.$errors.map(e => e.$message)" @input="v$.loginForm.username.$touch" @blur="v$.loginForm.username.$touch"
              ></v-text-field>
              <v-text-field
                  @keyup.enter="callPostLogin()"
                  :loading="loginForm.loadingLogin"
                  v-model="loginForm.password"
                  variant="solo"
                  label="Mot de passe"
                  prepend-inner-icon="mdi-lock"
                  :append-inner-icon="loginForm.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="loginForm.showPassword ? 'text' : 'password'"
                  @click:append-inner="loginForm.showPassword = !loginForm.showPassword"
                  :error-messages="v$.loginForm.password.$errors.map(e => e.$message)" @input="v$.loginForm.password.$touch" @blur="v$.loginForm.password.$touch"
              ></v-text-field>
              <p class="text-right mt-5 mb-5"><a @click="windowLoginState = 2" class="cursor-pointer text-grey-lighten-2 hover-text text-decoration-none font-weight-bold text-uppercase">Mot de passe oublié ?</a></p>
            </v-form>
            <div class="mx-15 d-flex flex-column flex-md-row justify-center align-content-center align-center">
              <v-btn
                  elevation="0"
                  class="d-flex ma-auto text-center mb-md-0 mb-5"
                  color="blue-grey-lighten-2"
                  size="x-large"
                  @click="redirectToVillePlurielle()"
              >Redirection Site
              </v-btn>
              <v-btn
                  elevation="0"
                  class="d-flex ma-auto text-center"
                  color="light-green-darken-1"
                  size="x-large"
                  :loading="loginForm.loadingLogin"
                  @click="callPostLogin()"
              >Se connecter
              </v-btn>
            </div>
          </v-window-item>
         <v-window-item :value="2">
            <h2 class="text-center mb-5 text-uppercase">Mot de passe oublié</h2>
            <v-form class="px-5 px-md-15">
              <v-text-field
                  @keyup.enter="callPostForgotPassword()"
                  variant="solo"
                  v-model="forgotPasswordForm.email"
                  :loading="forgotPasswordForm.loadingForgotPassword"
                  :error-messages="v$.forgotPasswordForm.email.$errors.map(e => e.$message)" @input="v$.forgotPasswordForm.email.$touch" @blur="v$.forgotPasswordForm.email.$touch"
                  label="E-mail"
                  prepend-inner-icon="mdi-account"
              ></v-text-field>
              <p class="text-left mt-5 mb-5 text-grey-lighten-2 font-weight-bold text-uppercase">Indiquer votre
                e-mail, nous allons vous envoyer un email afin de réinitialiser votre mot de passe de votre compte s'il
                existe.</p>
            </v-form>

            <div class="mx-15 d-flex flex-column flex-md-row justify-center align-content-center align-center">
              <v-btn
                  elevation="0"
                  class="d-flex ma-auto text-center mb-md-0 mb-5"
                  color="blue-grey-lighten-2"
                  size="x-large"
                  @click="windowLoginState = 1"
              >Retour Connexion
              </v-btn>
              <v-btn
                  elevation="0"
                  class="d-flex ma-auto text-center"
                  color="light-green-darken-1"
                  size="x-large"
                  :loading="forgotPasswordForm.loadingForgotPassword"
                  @click="callPostForgotPassword()"
              >Envoyer l'email
              </v-btn>
            </div>
          </v-window-item>
          <v-window-item :value="3">
            <h2 class="text-center mb-5 text-uppercase">Modifier Mot de passe</h2>
            <v-form class="px-5 px-md-15">
              <v-text-field
                  :loading="resetPasswordForm.loadingResetPassword"
                  v-model="resetPasswordForm.newPassword"
                  variant="solo"
                  label="Nouveau mot de passe"
                  class="mb-5"
                  prepend-inner-icon="mdi-lock"
                  :append-inner-icon="resetPasswordForm.showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="resetPasswordForm.showNewPassword ? 'text' : 'password'"
                  @click:append-inner="resetPasswordForm.showNewPassword = !resetPasswordForm.showNewPassword"
                  :error-messages="v$.resetPasswordForm.newPassword.$errors.map(e => e.$message)" @input="v$.resetPasswordForm.newPassword.$touch" @blur="v$.resetPasswordForm.newPassword.$touch"
              ></v-text-field>
              <v-text-field
                  @keyup.enter="callPostResetPassword()"
                  :loading="resetPasswordForm.loadingResetPassword"
                  v-model="resetPasswordForm.confirmNewPassword"
                  variant="solo"
                  label="Confirmer nouveau mot de passe"
                  prepend-inner-icon="mdi-lock"
                  :append-inner-icon="resetPasswordForm.showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="resetPasswordForm.showConfirmNewPassword ? 'text' : 'password'"
                  @click:append-inner="resetPasswordForm.showConfirmNewPassword = !resetPasswordForm.showConfirmNewPassword"
                  :error-messages="v$.resetPasswordForm.confirmNewPassword.$errors.map(e => e.$message)" @input="v$.resetPasswordForm.confirmNewPassword.$touch" @blur="v$.resetPasswordForm.confirmNewPassword.$touch"
              ></v-text-field>
              <p class="text-center mt-5 mb-5 text-grey-lighten-2 font-weight-bold text-uppercase">Indiquez votre
                nouveau mot de passe pour votre compte.</p>
            </v-form>
            <div class="mx-15 d-flex flex-column flex-md-row justify-center align-content-center align-center">
              <v-btn
                  elevation="0"
                  class="d-flex ma-auto text-center"
                  color="light-green-darken-1"
                  size="x-large"
                  :loading="resetPasswordForm.loadingResetPassword"
                  @click="callPostResetPassword()"
              >Confirmer
              </v-btn>
            </div>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import {defineComponent,nextTick} from 'vue';
import {useVuelidate} from '@vuelidate/core';
import {useDisplay} from "vuetify";
import {email, helpers, maxLength, minLength, required, sameAs} from '@vuelidate/validators';
import {authentificationStore} from "@/store/authentificationStore";
import {generalStore} from "@/store/General/generalStore";

export default defineComponent({
  name: 'LoginView',
  data() {
    return {
      windowLoginState: 1,
      loginForm: {
        username: null,
        password: null,
        loadingLogin: false,
        showPassword: false,
      },
      forgotPasswordForm: {
        email: null,
        loadingForgotPassword: false,
      },
      resetPasswordForm: {
        newPassword: null,
        confirmNewPassword: null,
        showNewPassword: false,
        showConfirmNewPassword: false,
        loadingResetPassword: false,
      },
    }
  },
  created() {
    const token = this.$route.query.token;
    if (token) {
      this.windowLoginState = 3;
    }
  },
  setup() {
    const { mdAndUp } = useDisplay()
    return {
      v$: useVuelidate(),
      mdAndUp
    }
  },
  validations() {
    return {
      loginForm: {
        username: {
          required: helpers.withMessage("L'identifiant est requis.", required),
        },
        password: {
          required: helpers.withMessage('Le mot de passe est requis.', required),
          minLength: helpers.withMessage('Le mot de passe doit contenir au moins 8 caractères.', (value) => value.length >= 8),
          maxLength: helpers.withMessage('Le mot de passe ne peut pas dépasser 255 caractères.', (value) => value.length <= 255)
        },
      },
      forgotPasswordForm: {
        email: {
          required: helpers.withMessage("L'e-mail est requis !", required),
          email: helpers.withMessage("L'e-mail n'est pas valide.", email),
        },
      },
      resetPasswordForm: {
        newPassword: {
          required: helpers.withMessage("Le mot de passe est requis.", required),
          minLength: helpers.withMessage("Le mot de passe doit contenir au moins 8 caractères.", minLength(8)),
          maxLength: helpers.withMessage("Le mot de passe ne peut pas dépasser 255 caractères.", maxLength(255)),
        },
        confirmNewPassword: {
          required: helpers.withMessage("La confirmation du mot de passe est requise.", required),
          sameAs: helpers.withMessage("Les mots de passe doivent correspondre.", sameAs(this.resetPasswordForm.newPassword)),
        },
      },
    }
  },
  methods: {
    async callPostLogin() {
      this.loginForm.loadingLogin = true;
      const result = await this.v$.loginForm.$validate()
      if (!result) {
        this.loginForm.loadingLogin = false;
        return false
      }
      try {
        const response = await authentificationStore().callPostLogin(this.loginForm.username, this.loginForm.password);
        if (response) {
          await this.$router.push('/dashboard');
          await nextTick();
          generalStore().showNotifSnackbar("Bienvenue sur VPConnect !", "light-green-darken-1", true, 5000);
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la connexion !", "red-darken-3", true, 5000);
      }
      this.loginForm.loadingLogin = false;
    },
    async callPostForgotPassword() {
      this.forgotPasswordForm.loadingForgotPassword = true;
      const result = await this.v$.forgotPasswordForm.$validate()
      if (!result) {
        this.forgotPasswordForm.loadingForgotPassword = false;
        return false
      }
      try {
        const response = await authentificationStore().callPostForgotPassword(this.forgotPasswordForm.email);
        if (response) {
          this.forgotPasswordForm.email = null;
         this.windowLoginState = 1;
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la demande de réinitialisation !", "red-darken-3", true, 5000);
      }
      this.forgotPasswordForm.loadingForgotPassword = false;
    },
    async callPostResetPassword() {
      this.resetPasswordForm.loadingResetPassword = true;
      const result = await this.v$.resetPasswordForm.$validate()
      if (!result) {
        this.resetPasswordForm.loadingResetPassword = false;
        return false
      }
      const token = this.$route.query.token;
      if (!token) {
        this.windowLoginState = 1;
      }
      try {
        const response = await authentificationStore().callPostResetPassword(token, this.resetPasswordForm.newPassword);
        if (response) {
          this.resetPasswordForm.newPassword = null;
          this.resetPasswordForm.confirmNewPassword = null;
          this.windowLoginState = 1;
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors du changement de mot de passe !", "red-darken-3", true, 5000);
      }
      this.resetPasswordForm.loadingResetPassword = false;
    },
    redirectToVillePlurielle() {
      const url = 'https://ville-plurielle.fr/';
      window.open(url, '_blank');
    },
  }
});
</script>
<style scoped>
.hover-text:hover {
  color: #BDBDBD !important;
  transition: color 0.2s linear;
}

.typewriter h1 {
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 2.5s steps(500, end);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>
