<template>
  <v-navigation-drawer v-model="getStateNavbar" :temporary="false" color="blue-grey-darken-4">
    <div class="pa-3 text-center d-flex justify-center align-center align-content-center cursor-pointer" @click="goTo('/dashboard')">
      <v-avatar :image="require('@/assets/logo-vpconnect.png')" color="surface-variant" size="70"></v-avatar>
      <p class="ml-5">VPConnect</p>
    </div>
    <v-divider></v-divider>
    <v-list class="pa-0">
      <v-list-item prepend-icon="mdi-home" title="Tableau de bord" @click="goTo('/dashboard')"></v-list-item>
    </v-list>
    <v-divider></v-divider>
    <div class="position-relative py-5">
      <h3 class="text-center">Inscriptions</h3>
    </div>
    <v-divider></v-divider>
    <v-list class="pa-0">
      <v-list-item prepend-icon="mdi-calendar-check" title="Activités" @click="goTo('/activity')"></v-list-item>
      <v-divider></v-divider>
      <v-list-item prepend-icon="mdi-account-group" title="Fiches" @click="goTo('/sheet')"></v-list-item>
    </v-list>
    <v-divider></v-divider>
    <div class="position-relative py-5">
      <h3 class="text-center">Outils</h3>
    </div>
    <v-divider></v-divider>
    <v-list class="pa-0">
      <v-list-item prepend-icon="mdi-qrcode" title="QRCode" @click="goTo('/qrcode')"></v-list-item>
      <v-divider></v-divider>
      <v-list-item prepend-icon="mdi-merge" title="PDF Fusion" @click="goTo('/pdf-merger')"></v-list-item>
    </v-list>
  <v-divider></v-divider>
    <div class="position-absolute text-center w-100 mb-1" style="bottom:0">
      <p class="">Nous sommes le <b>{{ date }}</b></p>
      <p class="">Il est <b>{{ time }}</b></p>
      <p class="text-caption d-flex justify-center align-center">Version {{ nameVersion }} {{ numberVersion }} <img class="ml-2" width="30" height="30" :src="imgVersion"/></p>
      <v-btn class="text-caption pb-5 mb-3" density="compact" size="small" prepend-icon="mdi-refresh" color="blue-grey-darken-1" @click="reloadWebsite()">Vérifier MàJ</v-btn>
    </div>
  </v-navigation-drawer>
</template>
<script>
import {generalStore} from "@/store/General/generalStore";
import {activityStore} from "@/store/activityStore";
import {sheetStore} from "@/store/sheetStore";

export default {
  name: 'NavComponent',
  computed: {
    getStateNavbar: {
      get() {
        return generalStore().getStateNavbar;
      },
      set() {
        generalStore().toogleNavbar();
      }
    }
  },
  data() {
    return {
      time: Intl.DateTimeFormat(navigator.language, {hour: 'numeric', minute: 'numeric', second: 'numeric'}).format(),
      date: new Date().toLocaleDateString(),
      imgVersion: null,
      numberVersion: null,
      nameVersion: null
    }
  },
  created() {
    var Pokedex = require('pokedex'), pokedex = new Pokedex();
    this.imgVersion = pokedex.pokemon(parseInt(process.env.VUE_APP_NUM_POKEMON)).sprites.animated;
    this.numberVersion = process.env.VUE_APP_VERSION
    this.nameVersion = process.env.VUE_APP_NAME_VERSION
    this.interval = setInterval(() => {
      this.date = new Date().toLocaleDateString();
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
    }, 1000);
  },
  methods: {
    goTo(url) {
      if(generalStore().getIsMobile) {
        generalStore().toogleNavbar();
      }
      activityStore().stateWindowActivity = 1;
      sheetStore().stateWindowSheet = 1;
      this.$router.push(url);
    },
    reloadWebsite() {
      location.reload();
    }
  },
}
</script>
