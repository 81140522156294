import { createRouter, createWebHistory } from 'vue-router'
import {authentificationStore} from "@/store/authentificationStore";
import ProfileView from "@/views/Profile/ProfileView";
import ActivityView from "@/views/Registration/ActivityView";
import DashboardView from "@/views/Dashboard/DashboardView";
import SheetView from "@/views/Registration/SheetView";
import LoginView from "@/views/Connection/LoginView";
import PdfMergerView from "@/views/Tools/PdfMergerView";
import QrcodeView from "@/views/Tools/QrcodeView";
import ActivityManageView from "@/views/ActivityManageView";
import axios from "axios";
import {activityStore} from "@/store/activityStore";
import {dashboardStore} from "@/store/dashboardStore";
import {errorStore} from "@/store/Error/errorStore";
import ErrorView from "@/views/Error/ErrorView";

const routes = [
  {
    path: '/dashboard',
    name: 'Tableau de bord',
    component: DashboardView,
    beforeEnter: async (to, from, next) => {
      dashboardStore().dashboard = null;
      if (authentificationStore().connected) {
        try {
          const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_DASHBOARD, {
            headers: {Authorization: 'Bearer ' + authentificationStore().getToken},
          });
          dashboardStore().dashboard = response.data.data;
          next();
        } catch (error) {
          next('/');
        }
      } else {
        next('/');
      }
    },

  },
  {
    path: '/profile',
    name: 'Mon profil',
    component: ProfileView,
    /*beforeEnter: async (to, from, next) => {
      if (authentificationStore().connected) {
        try {
          await userApiStore().callGetUserInfos();
          next();
        } catch (error) {
          console.error('Redirection due to error:', error);
console.log(error.status);
          // Vérification du code d'erreur
          if (error.status === 400) {
            errorStore().setError(400, "La requête envoyé semble invalide.", "red-darken-1", "mdi-lock");
          } else if (error.status === 401) {
            errorStore().setError(401, "Vous n'êtes pas connecté pour accéder à cette page ou ressource.", "red-darken-1", "mdi-lock");
          } else if (error.status === 403) {
            errorStore().setError(403, "Vous n'êtes pas autorisé à accéder à cette page ou ressource.", "red-darken-1", "mdi-lock");
          } else if (error.status === 404) {
            errorStore().setError(404, "La ressource que vous cherchez n'existe pas ou a été déplacée.", "red-darken-1", "mdi-lock");
          } else if (error.status === 408) {
            errorStore().setError(408, "Vous n'êtes pas autorisé à accéder à cette page.", "red-darken-1", "mdi-lock-alert");
          } else if (error.status === 429) {
            errorStore().setError(429, "Vous n'êtes pas autorisé à accéder à cette page.", "red-darken-1", "mdi-lock-alert");
          } else {
            errorStore().setError(500, "Le serveur a rencontré un problème !", "red-darken-1", "mdi-robot-dead");
          }
          next('/error');
        }
      } else {
        return next('/');
      }
    }*/
  },
  {
    path: '/activity',
    name: 'Activités',
    component: ActivityView,
    beforeEnter: (to, from, next) => {
      if (authentificationStore().connected) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/sheet',
    name: 'Fiches',
    component: SheetView,
    beforeEnter: (to, from, next) => {
      if (authentificationStore().connected) {
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/',
    name: 'Se connecter',
    component: LoginView,
    beforeEnter: (to, from, next) => {
      if (authentificationStore().connected) {
        next('/dashboard');
      } else {
        next();
      }
    },
  },
  {
    path: '/pdf-merger',
    name: 'PDF Fusion',
    component: PdfMergerView,
    beforeEnter: (to, from, next) => {
      if (authentificationStore().connected) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/qrcode',
    name: 'QRCode',
    component: QrcodeView,
    beforeEnter: (to, from, next) => {
      if (authentificationStore().connected) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/activite/modification/:id',
    name: 'Modifier une Activité',
    component: ActivityManageView,
    beforeEnter: async (to, from, next) => {
      activityStore().activityId = null;
      if (authentificationStore().connected) {
        const id = to.params.id;
        try {
          const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_ACTIVITY + '/' + id, {
            headers: {Authorization: 'Bearer ' + authentificationStore().getToken},
          });
          activityStore().activityId = response.data.data;
        } catch (error) {
          next('/');
        }
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/error',
    component: ErrorView,
    name: "Erreur",
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorView,
    name: "Erreur 404",
    beforeEnter: async (to, from, next) => {
      errorStore().setError(404, "La page que vous cherchez n'existe pas ou a été déplacée.", "orange-lighten-1", "mdi-robot-confused");
      next();
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
