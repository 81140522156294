<template>
  <section class="pa-4 fill-height">
    <v-card class="h-100">
      <v-row :no-gutters="true">
        <v-col cols="12">
          <v-btn class="ma-3">Retour aux activités</v-btn>
        </v-col>
        <v-col cols="3" class="d-flex justify-center align-content-center align-center">
          <v-avatar size="150">
            <v-img src="@/assets/logo-vpconnect.png"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="9">
          <div class="d-flex justify-start align-content-center align-center">
            <v-text-field variant="solo-filled" clearable label="Nom de votre activité" :value="getActivityId.name" v-model="getActivityId.name"></v-text-field>
          </div>
        </v-col>
        <v-col cols="8">
          <div class="pa-3 d-flex justify-start align-content-center align-center">
            <v-textarea
                variant="solo-filled"
                clearable
                label="Description de l'activité"
                class="w-75 ma-auto"
                :value="getActivityId.description"
                v-model="getActivityId.description"
            ></v-textarea>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="pa-3 d-flex justify-start align-content-center align-center">
            <v-text-field variant="solo-filled" clearable label="Adresse de votre activité" :value="getActivityId.address" v-model="getActivityId.address"></v-text-field>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="pa-3 d-flex justify-start align-content-center align-center">
            <v-text-field variant="solo-filled" clearable label="Date de début de votre activité"></v-text-field>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="pa-3 d-flex justify-start align-content-center align-center">
            <v-text-field variant="solo-filled" clearable label="Date de fin de votre activité"></v-text-field>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="pa-3 d-flex justify-start align-content-center align-center">
            <v-text-field variant="solo-filled" clearable label="Nombre de place disponible" :value="getActivityId.seat" v-model="getActivityId.seat"></v-text-field>
            <v-checkbox label="Aucune limite de place"></v-checkbox>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="pa-3 d-flex justify-start align-content-center align-center">
            <v-select variant="solo-filled" clearable label="Structure"></v-select>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import {activityStore} from "@/store/activityStore";

export default defineComponent({
  name: 'ActivityManageView',
  data() {
    return {
    }
  },
  computed: {
    getActivityId() {
      return activityStore().getActivityId;
    },
  },
  methods: {
    confirmEdit() {

    },
    exitEdit() {

    }
  }
});
</script>