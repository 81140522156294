import {defineStore} from 'pinia'
import axios from "axios";
import {authentificationStore} from "@/store/authentificationStore";
import {generalStore} from "@/store/General/generalStore";

export const registrationStore = defineStore('registrationStore', {
    state: () => {
        return {
            registration: [],
            registrationPagination: [],
            registrationActivity: [],
            registrationActivityPagination: [],
            dialogRegistrationShow: false,
        }
    },
    getters: {
        getRegistration: (state) => {
            return state.registration;
        },
        getRegistrationPagination: (state) => {
            return state.registrationPagination;
        },
        getRegistrationActivity: (state) => {
            return state.registrationActivity;
        },
        getRegistrationActivityPagination: (state) => {
            return state.registrationActivityPagination;
        },
        getDialogRegistrationShow: (state) => {
            return state.dialogRegistrationShow;
        },
    },
    actions: {
        toogleDialogRegistration() {
            this.dialogRegistrationShow = !this.dialogRegistrationShow;
        },
        async callGetRegistration(idSheet, search = '', limit = 10, page = 1, structure = "TOUS") {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION + '/' + idSheet,{
                    headers: { Authorization: 'Bearer ' + authentificationStore().getToken },
                    params: {
                        search: search,
                        limit: limit,
                        page: page,
                        structure: structure,
                        type_registration: 'sheet',
                    }
                });
                this.registration = response.data.data;
                this.registrationPagination = response.data.pagination;
                return true;
            } catch(error) {
                return false;
            }
        },
        async callGetRegistrationActivity(idActivity, search = '', limit = 10, page = 1, structure = "TOUS") {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION + '/' + idActivity,{
                    headers: { Authorization: 'Bearer ' + authentificationStore().getToken },
                    params: {
                        search: search,
                        limit: limit,
                        page: page,
                        structure: structure,
                        type_registration: 'activity',
                    }
                });
                this.registrationActivity = response.data.data;
                this.registrationActivityPagination = response.data.pagination;
                return true;
            } catch(error) {
                return false;
            }
        },
        async callPostRegistration(idActivity, idSheet) {
            try {
                await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION,{
                    activity: idActivity,
                    sheet: idSheet,
                },{
                    headers: {
                        Authorization: 'Bearer ' + authentificationStore().getToken }
                });
                return true;
            } catch(error) {
                if (error.response) {
                    if(error.response.status === 401) {
                        generalStore().showNotifSnackbar("Votre session a expiré !", "red-darken-3", true, 5000);
                    } else if(error.response.status === 422) {
                        generalStore().showNotifSnackbar("Le nombre de places disponibles est insuffisant pour votre réservation. Veuillez réduire le nombre d'inscription.", "red-darken-3", true, 5000);
                    }
                    else {
                        generalStore().showNotifSnackbar("Une erreur est survenue, veuillez réessayer !", "red-darken-3", true, 5000);
                    }
                } else if (error.request) {
                    generalStore().showNotifSnackbar("Erreur le serveur ne répond pas !", "red-darken-3", true, 5000);
                } else {
                    generalStore().showNotifSnackbar("Erreur lors de la requête !", "red-darken-3", true, 5000);
                }
                return false;
            }
        },
        async callPutRegistration(id, type, value, number) {
            try {
                await axios.put(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION + '/' + id,{
                    type: type,
                    value: value,
                    number: number
                },{
                    headers: {
                        Authorization: 'Bearer ' + authentificationStore().getToken }
                });
                return true;
            } catch(error) {
                if (error.response) {
                    if(error.response.status === 401) {
                        generalStore().showNotifSnackbar("Votre session a expiré !", "red-darken-3", true, 5000);
                    } else if(error.response.status === 422) {
                        generalStore().showNotifSnackbar("Le nombre de places disponibles est insuffisant pour votre réservation. Veuillez réduire le nombre d'inscription.", "red-darken-3", true, 5000);
                    }
                    else {
                        generalStore().showNotifSnackbar("Une erreur est survenue, veuillez réessayer !", "red-darken-3", true, 5000);
                    }
                } else if (error.request) {
                    generalStore().showNotifSnackbar("Erreur le serveur ne répond pas !", "red-darken-3", true, 5000);
                } else {
                    generalStore().showNotifSnackbar("Erreur lors de la requête !", "red-darken-3", true, 5000);
                }
                return false;
            }
        },
        async callDeleteRegistration(id) {
            try {
                await axios.delete(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION + '/' + id,{
                    headers: { Authorization: 'Bearer ' + authentificationStore().getToken },
                });
                return true;
            } catch(error) {
                if (error.response) {
                    if(error.response.status === 401) {
                        generalStore().showNotifSnackbar("Votre session a expiré !", "red-darken-3", true, 5000);
                    } else {
                        generalStore().showNotifSnackbar("Une erreur est survenue, veuillez réessayer !", "red-darken-3", true, 5000);
                    }
                } else if (error.request) {
                    generalStore().showNotifSnackbar("Erreur le serveur ne répond pas !", "red-darken-3", true, 5000);
                } else {
                    generalStore().showNotifSnackbar("Erreur lors de la requête !", "red-darken-3", true, 5000);
                }
                return false;
            }
        },
    }
})