import {defineStore} from 'pinia'
import axios from "axios";
import {authentificationStore} from "@/store/authentificationStore";

export const activityStore = defineStore('activityStore', {
  state: () => {
    return {
      activity: [],
      activityPagination: [],
      activityId: [],
      stateWindowActivity: 1,
      dialogActivityShow: false,
    }
  },
  getters: {
    getActivity: (state) => {
      return state.activity;
    },
    getActivityPagination: (state) => {
      return state.activityPagination;
    },
    getActivityId: (state) => {
      return state.activityId;
    },
    getDialogActivityShow: (state) => {
      return state.dialogActivityShow;
    },
  },
  actions: {
    toogleDialogActivity() {
      this.dialogActivityShow = !this.dialogActivityShow;
    },
    async callGetActivity(search = '', limit = 10, page = 1, structure = 'TOUS') {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_ACTIVITY,{
          headers: { Authorization: 'Bearer ' + authentificationStore().getToken },
          params: {
            search: search,
            limit: limit,
            page: page,
            structure: structure
          }
        });
        this.activity = response.data.data;
        this.activityPagination = response.data.pagination;
        return true;
      } catch(error) {
        return false;
      }
    },
    async callPostActivity(name, seat, address, description, dateActivityStart, dateActivityEnd, structure = null) {
      dateActivityStart = new Date(dateActivityStart).toISOString().replace("T", " ").substring(0, 16);
      dateActivityEnd = new Date(dateActivityEnd).toISOString().replace("T", " ").substring(0, 16);
      try {
        await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_ACTIVITY,{
          name: name,
          seat: seat,
          address: address,
          description: description,
          startActivity: dateActivityStart,
          endActivity: dateActivityEnd,
          structure: structure,
        },{
          headers: {
            Authorization: 'Bearer ' + authentificationStore().getToken }
        });
        return true;
      } catch(error) {
        return false;
      }
    },
    async callPutActivity(id, name, seat, address, description, dateActivityStart, dateActivityEnd, structure = null) {
      dateActivityStart = new Date(dateActivityStart).toISOString().replace("T", " ").substring(0, 16);
      dateActivityEnd = new Date(dateActivityEnd).toISOString().replace("T", " ").substring(0, 16);
      try {
        await axios.put(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_ACTIVITY + "/" + id,{
          name: name,
          seat: seat,
          address: address,
          description: description,
          startActivity: dateActivityStart,
          endActivity: dateActivityEnd,
          structure: structure,
        },{
          headers: {
            Authorization: 'Bearer ' + authentificationStore().getToken }
        });
        return true;
      } catch(error) {
        return false;
      }
    },
    async callGetActivityId(id) {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_ACTIVITY + '/' + id,{
          headers: { Authorization: 'Bearer ' + authentificationStore().getToken },
        });
        this.activityId = response.data.data;
        return true;
      } catch(error) {
        return false;
      }
    },
    async callDeleteActivity(id) {
      try {
        await axios.delete(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_ACTIVITY + '/' + id,{
          headers: { Authorization: 'Bearer ' + authentificationStore().getToken },
        });
        return true;
      } catch(error) {
        return false;
      }
    },
  }
})
