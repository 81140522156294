<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-data-table
            ref="dataTable"
            class="elevation-1"
            hover
            :headers="headerRegistration"
            :items="getRegistrationActivity"
            items-per-page="-1"
        >
          <template v-slot:no-data>
            <v-empty-state
                icon="mdi-magnify"
                text="Essayez de modifier vos termes de recherche. Il se peut aussi qu'il n'y ait pas d'inscription pour cette fiche dans ce cas, vous pouvez créer une inscription en cliquant sur le bouton ci-dessous."
                title="Aucune inscription"
            >
              <template v-slot:actions>
                <v-btn
                    prepend-icon="mdi-plus"
                    color="green-darken-1"
                    text="Créer une inscription"
                ></v-btn>
              </template>
            </v-empty-state>
          </template>
          <template v-slot:top>
            <v-row class="px-2 pt-2 pb-0" no-gutters>
              <template v-if="mdAndUp">
                <v-col cols="12" class="d-flex justify-space-between">
                  <v-btn prepend-icon="mdi-arrow-left" color="blue-grey-darken-4" @click="goToWindow(1)">Retour Fiches</v-btn>
                  <v-btn
                      :prepend-icon="stateMoreInfos ? 'mdi-eye-off' : 'mdi-eye'"
                      color="blue-grey-darken-4"
                      @click="showMoreInfos()"
                  >
                    {{ stateMoreInfos ? 'Cacher Informations' : 'Voir Informations' }}
                  </v-btn>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="12">
                  <v-btn block prepend-icon="mdi-arrow-left" color="blue-grey-darken-4" @click="goToWindow(1)">Retour Fiches</v-btn>
                </v-col>
                <v-col cols="12" class="mt-2">
                  <v-btn
                      block
                      :prepend-icon="stateMoreInfos ? 'mdi-eye-off' : 'mdi-eye'"
                      color="blue-grey-darken-4"
                      @click="showMoreInfos()"
                  >
                    {{ stateMoreInfos ? 'Cacher Informations' : 'Voir Informations' }}
                  </v-btn>
                </v-col>
              </template>
            </v-row>
            <v-row v-if="stateMoreInfos" class="pa-2 pb-0 mt-0" :no-gutters="true">
              <v-col cols="12" md="4" class="pr-0 pr-md-2 pb-2">
                <v-card color="indigo-lighten-2" class="fill-height d-flex flex-column justify-center align-content-center align-center">
                  <v-card-title class="d-flex justify-space-around flex-row align-center align-content-center">
                    <v-icon size="x-small" icon="mdi-account" class="mr-2 mr-md-4"></v-icon>
                    <p class="text-subtitle-1 font-weight-regular text-center text-md-left">Nom</p>
                  </v-card-title>
                  <v-card-text class="pb-2 text-white d-flex flex-row justify-start align-content-center align-center">
                    <div class="d-flex flex-column">
                      <p class="text-subtitle-2 text-center font-weight-bold text-md-left"><span class="text-uppercase">{{ getActivityId.name }}</span></p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4" class="pr-0 pr-md-2 pb-2">
                <v-card color="blue-darken-2" class="fill-height d-flex flex-column justify-center align-content-center align-center">
                  <v-card-title class="d-flex justify-space-around flex-row align-center align-content-center">
                    <v-icon size="x-small" icon="mdi-phone" class="mr-2 mr-md-4"></v-icon>
                    <p class="text-subtitle-1 font-weight-regular text-center">Description</p>
                  </v-card-title>
                  <v-card-text class="pb-2 d-flex flex-row justify-start align-content-center align-center">
                    <div class="d-flex flex-column">
                      <p v-if="getActivityId.description === null" class="text-subtitle-2 text-center font-weight-bold">Aucune description</p>
                      <p class="text-subtitle-2 text-center font-weight-bold" v-else>{{ getActivityId.description }}</p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="2" class="pr-0 pr-md-2 pb-2">
                <v-card color="orange-darken-1" class="fill-height d-flex flex-column justify-center align-content-center align-center">
                  <v-card-title class="d-flex justify-space-around flex-row align-center align-content-center">
                    <v-icon size="x-small" icon="mdi-information" class="mr-2 mr-md-4"></v-icon>
                    <p class="text-subtitle-1 font-weight-regular text-center">Structure(s)</p>
                  </v-card-title>
                  <v-card-text class="pb-2 text-white d-flex flex-row justify-start align-content-center align-center">
                    <div class="d-flex flex-column">
                      <p v-if="getActivityId.structure.length === 0 || getActivityId.structure === null" class="text-subtitle-2 text-center font-weight-bold"><v-chip color="blue-grey-lighten-5" density="compact" size="small" class="mr-1">TOUS</v-chip></p>
                      <p v-else class="text-subtitle-2 text-center font-weight-bold"><v-chip color="blue-grey-lighten-5" density="compact" size="small" class="mr-1 font-weight-bold" v-for="(snackbar, index) in getActivityId.structure" :key="index">{{snackbar}}</v-chip></p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="2" class="pr-0 pb-2">
                <v-card color="grey-lighten-5" class="fill-height d-flex flex-column justify-center align-content-center align-center">
                  <v-card-title class="text-black d-flex justify-space-around flex-row align-center align-content-center">
                    <v-icon size="x-small" icon="mdi-text" class="mr-2 mr-md-4"></v-icon>
                    <p class="text-subtitle-1 font-weight-regular text-center">Status</p>
                  </v-card-title>
                  <v-card-text class="pb-2 d-flex flex-row justify-start align-content-center align-center">
                    <div class="d-flex flex-column">
                      <p v-if="getActivityId.status === null" class="text-subtitle-2 text-center font-weight-bold">Aucun status</p>
                      <div class="d-flex flex-column d-md-flex flex-md-row justify-center align-content-center align-center">
                        <v-chip v-if="getActivityId.status === 'BLOCK'" class="font-weight-bold" color="deep-purple-accent-1" size="small">Bloqué</v-chip>
                        <v-chip v-if="getActivityId.status === 'STARTED'" class="font-weight-bold" color="orange-darken-1" size="small">En cours</v-chip>
                        <v-chip v-if="getActivityId.status === 'FINISH'" class="font-weight-bold" color="green-darken-1" size="small">Terminé</v-chip>
                        <v-chip v-if="getActivityId.status === 'SCHEDULED'" class="font-weight-bold" color="blue-darken-1" size="small">À venir</v-chip>
                        <template v-if="getActivityId.seat != null">
                          <v-chip v-if="getActivityId.totalRegistration === getActivityId.seat" class="font-weight-bold" color="red-darken-1" size="small">Complet</v-chip>
                          <v-chip v-else-if="getActivityId.totalRegistration > getActivityId.seat" class="font-weight-bold blink" color="red-darken-1" size="small"><v-icon icon="mdi-alert" class="mr-1"></v-icon>SURBOOKÉ</v-chip>
                        </template>
                        <v-chip v-if="getActivityId.status === 'CANCELED'" class="font-weight-bold" color="grey-darken-1" size="small">Annulé</v-chip>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" class="pr-0 pr-md-2 pb-2">
                <v-card color="green-lighten-1" class="fill-height d-flex flex-column justify-center align-content-center align-center">
                  <v-card-title class="text-white d-flex justify-space-around flex-row align-center align-content-center">
                    <v-icon size="x-small" icon="mdi-text" class="mr-2 mr-md-4"></v-icon>
                    <p class="text-subtitle-1 font-weight-regular text-center">Début</p>
                  </v-card-title>
                  <v-card-text class="text-white d-flex flex-row justify-start align-content-center align-center">
                    <div class="d-flex flex-column">
                      <p class="text-center"><b>{{ formatDate(getActivityId.startActivityDate, true) }}</b></p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" class="pr-0 pr-md-2 pb-2">
                <v-card color="green-lighten-1" class="fill-height d-flex flex-column justify-center align-content-center align-center">
                  <v-card-title class="text-white d-flex justify-space-around flex-row align-center align-content-center">
                    <v-icon size="x-small" icon="mdi-text" class="mr-2 mr-md-4"></v-icon>
                    <p class="text-subtitle-1 font-weight-regular text-center">Fin</p>
                  </v-card-title>
                  <v-card-text class="text-white d-flex flex-row justify-start align-content-center align-center">
                    <div class="d-flex flex-column">
                      <p class="text-center"><b>{{ formatDate(getActivityId.endActivityDate, true) }}</b></p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <!--<v-col cols="12" md="3" class="pb-2 pr-md-2">
                <v-card color="green-lighten-1" class="fill-height d-flex flex-column justify-center align-content-center align-center">
                  <v-card-title class="text-white d-flex justify-space-around flex-row align-center align-content-center">
                    <v-icon size="x-small" icon="mdi-text" class="mr-2 mr-md-4"></v-icon>
                    <p class="text-subtitle-1 font-weight-regular text-center">Total inscription</p>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" md="3" class="pb-2">
                <v-card color="green-lighten-1" class="fill-height d-flex flex-column justify-center align-content-center align-center">
                  <v-card-title class="text-white d-flex justify-space-around flex-row align-center align-content-center">
                    <v-icon size="x-small" icon="mdi-text" class="mr-2 mr-md-4"></v-icon>
                    <p class="text-subtitle-1 font-weight-regular text-center">Place Restante</p>
                  </v-card-title>
                  <v-card-text class="pb-2 text-white d-flex flex-row justify-start align-content-center align-center">
                    <div class="d-flex flex-column">
                      <p v-if="getActivityId.seat === null" class="text-subtitle-2 text-center font-weight-bold">Illimité</p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>-->
            </v-row>
            <v-row class="pa-2 pb-0 pt-0" :no-gutters="true">
              <v-col cols="12" class="mb-2 d-flex justify-space-between align-content-center align-center">



                <v-btn icon="mdi-plus" @click.stop="showDialogType" class="mr-2" density="comfortable" color="green-darken-1" variant="flat"></v-btn>
                <v-text-field @input="onInput" class="w-50" v-model="searchActivity" prepend-inner-icon="mdi-magnify" label="Rechercher une fiche inscrite" single-line hide-details :clearable="true">
                  <template v-slot:append-inner>
                    <v-progress-circular
                        v-if="isLoading"
                        indeterminate
                        size="20"
                        width="4"
                        color="blue-grey-darken-4"
                    ></v-progress-circular>
                  </template>
                </v-text-field>

                <!--<v-select
                    :disabled="loadingInputStructure"
                    :loading="loadingInputStructure"
                    v-model="inputSelectStructure"
                    @update:modelValue="callGetSheetId(inputSearchSheet, inputSelectLimitSheet, 1, inputSelectStructure)"
                    prepend-inner-icon="mdi-domain"
                    class="ml-2 w-25"
                    hide-details
                    label="Sélectionner un lieu"
                    :items="itemsSelectStructure"
                >
                  this.callGetSheet(this.inputSearchSheet, this.inputSelectLimitSheet, page, this.inputSelectStructure);
                  <template v-slot:append-inner>
                    <v-progress-circular
                        v-if="loadingInputStructure"
                        color="blue-grey-darken-4"
                        indeterminate
                        size="20"
                        width="4"
                    ></v-progress-circular>
                  </template>
                </v-select>
                <v-tooltip text="Rafraîchir les inscriptions" location="top" class="default-tooltip-color">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" icon="mdi-refresh" class="ml-2" density="comfortable" :loading="loadingButtonRefresh" :color="colorButtonRefresh" variant="flat" @click="callGetSheetId(inputSearchSheet, inputSelectLimitSheet, 1, inputSelectStructure)"></v-btn>
                  </template>
                </v-tooltip>--->
                <v-tooltip text="Télécharger le pdf inscriptions" location="top" class="default-tooltip-color">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" icon="mdi-printer" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat" @click="pdfDataSheet"></v-btn>
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!--<template v-slot:bottom v-if="getRegistrationActivityPagination.current_page">
            <template v-if="mdAndUp">
              <v-row :no-gutters="true" class="pa-2">
                <v-col cols="12" class="d-flex justify-end align-center align-content-center">
                  <p class="mr-2">Fiches par page :</p>
                  <v-select
                      :disabled="loadingInputLimitSheet"
                      :loading="loadingInputLimitSheet"
                      class="mr-2"
                      density="compact"
                      hide-details
                      max-width="100"
                      :items="['5', '10', '20', '50', '100']"
                      v-model="inputSelectLimitSheet"
                      @update:modelValue="goToSheetPagination('updateLimit')"
                  ></v-select>
                  <v-btn
                      class="mr-1"
                      density="comfortable"
                      :disabled="!getRegistrationActivityPagination.has_previous_page || loadingButtonFirstPage"
                      icon="mdi-page-first"
                      @click="goToSheetPagination('first')"
                  ></v-btn>
                  <v-btn
                      density="comfortable"
                      :disabled="!getRegistrationActivityPagination.has_previous_page || loadingButtonPreviousPage"
                      icon="mdi-chevron-left"
                      @click="goToSheetPagination('previous')"
                  ></v-btn>
                  <span class="mx-2">Page <b v-if="getRegistrationActivityPagination.total_pages > 0">{{ getRegistrationActivityPagination.current_page }}</b><b v-else>0</b> sur <b>{{ getRegistrationActivityPagination.total_pages }}</b></span>
                  <v-btn
                      class="mr-1"
                      density="comfortable"
                      :disabled="!getRegistrationActivityPagination.has_next_page || loadingButtonNextPage"
                      icon="mdi-chevron-right"
                      @click="goToSheetPagination('next')"
                  ></v-btn>
                  <v-btn
                      density="comfortable"
                      :disabled="!getRegistrationActivityPagination.has_next_page || loadingButtonLastPage"
                      icon="mdi-page-last"
                      @click="goToSheetPagination('last')"
                  ></v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row :no-gutters="true" class="pa-2">
                <v-col cols="12" class="d-flex justify-center align-center align-content-center">
                  <p class="mr-2">Fiches par page :</p>
                  <v-select
                      :disabled="loadingInputLimitSheet"
                      :loading="loadingInputLimitSheet"
                      class="mr-2"
                      density="compact"
                      hide-details
                      :items="['5', '10', '20', '50', '100']"
                      v-model="inputSelectLimitSheet"
                      @update:modelValue="goToSheetPagination('updateLimit')"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="mt-3 d-flex justify-center align-center align-content-center">
                  <v-btn
                      class="mr-5"
                      density="comfortable"
                      :disabled="!getRegistrationActivityPagination.has_previous_page || loadingButtonFirstPage"
                      icon="mdi-page-first"
                      @click="goToSheetPagination('first')"
                  ></v-btn>
                  <v-btn
                      density="comfortable"
                      :disabled="!getRegistrationActivityPagination.has_previous_page || loadingButtonPreviousPage"
                      icon="mdi-chevron-left"
                      @click="goToSheetPagination('previous')"
                  ></v-btn>
                  <span class="mx-2">Page <b v-if="getRegistrationActivityPagination.total_pages > 0">{{ getRegistrationActivityPagination.current_page }}</b><b v-else>0</b> sur <b>{{ getRegistrationActivityPagination.total_pages }}</b></span>
                  <v-btn
                      class="mr-5"
                      density="comfortable"
                      :disabled="!getRegistrationActivityPagination.has_next_page || loadingButtonNextPage"
                      icon="mdi-chevron-right"
                      @click="goToSheetPagination('next')"
                  ></v-btn>
                  <v-btn
                      density="comfortable"
                      :disabled="!getRegistrationActivityPagination.has_next_page || loadingButtonLastPage"
                      icon="mdi-page-last"
                      @click="goToSheetPagination('last')"
                  ></v-btn>
                </v-col>
              </v-row>
            </template>
          </template>-->
          <template v-slot:bottom></template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex flex-column d-md-flex flex-md-row justify-center align-content-center align-center">
              <v-chip v-if="item.status === 'BLOCK'" class="font-weight-bold" color="deep-purple-accent-1" size="small">Bloqué</v-chip>
              <v-chip v-if="item.status === 'STARTED'" class="font-weight-bold" color="orange-darken-1" size="small">En cours</v-chip>
              <v-chip v-if="item.status === 'FINISH'" class="font-weight-bold" color="green-darken-1" size="small">Terminé</v-chip>
              <v-chip v-if="item.status === 'SCHEDULED'" class="font-weight-bold" color="blue-darken-1" size="small">À venir</v-chip>
              <template v-if="item.seat != null">
                <v-chip v-if="item.totalActivityRegistrations === item.seat" class="font-weight-bold" color="red-darken-1" size="small">Complet</v-chip>
                <v-chip v-else-if="item.totalActivityRegistrations > item.seat" class="font-weight-bold blink" color="red-darken-1" size="small"><v-icon icon="mdi-alert" class="mr-1"></v-icon>SURBOOKÉ</v-chip>
              </template>
              <v-chip v-if="item.status === 'CANCELED'" class="font-weight-bold" color="grey-darken-1" size="small">Annulé</v-chip>
            </div>
          </template>
          <!--<template v-slot:[`item.individualRegistrations`]="{ item }">
            <div class="d-flex flex-row align-center">
              <v-number-input
                  class="w-25"
                  v-if="item.seat !== null && item.seat >= item.totalActivityRegistrations"
                  density="compact"
                  min="1"
                  hide-details
                  :max="Math.max(item.seat - item.totalActivityRegistrations + item.individualRegistrations, 0)"
                  v-model="item.individualRegistrations"
                  @update:modelValue="callPutRegistration(item, 'registration', null, item.individualRegistrations)"
                  :disabled="item.stateRegistrationSheet === 'loading'"
              >
                <template v-slot:append-inner>
                  <v-progress-circular
                      v-if="item.stateRegistrationSheet === 'loading'"
                      color="blue-grey-darken-4"
                      class="mr-2"
                      indeterminate
                      size="15"
                      width="4"
                  ></v-progress-circular>
                  <v-icon v-else-if="item.stateRegistrationSheet === 'success'" icon="mdi-check" color="green" class="mr-2"></v-icon>
                  <v-icon v-else-if="item.stateRegistrationSheet === 'error'" icon="mdi-close" color="red" class="mr-2"></v-icon>
                </template>
              </v-number-input>

              <v-number-input
                  v-else-if="item.seat !== null && item.seat < item.totalActivityRegistrations"
                  class="w-25"
                  density="compact"
                  min="1"
                  :max="item.individualRegistrations"
                  hide-details
                  v-model="item.individualRegistrations"
                  @update:modelValue="callPutRegistration(item, 'registration', null, item.individualRegistrations)"
                  :disabled="item.stateRegistrationSheet === 'loading'"
              >
                <template v-slot:append-inner>
                  <v-progress-circular
                      v-if="item.stateRegistrationSheet === 'loading'"
                      color="blue-grey-darken-4"
                      class="mr-2"
                      indeterminate
                      size="15"
                      width="4"
                  ></v-progress-circular>
                  <v-icon v-else-if="item.stateRegistrationSheet === 'success'" icon="mdi-check" color="green" class="mr-2"></v-icon>
                  <v-icon v-else-if="item.stateRegistrationSheet === 'error'" icon="mdi-close" color="red" class="mr-2"></v-icon>
                </template>
              </v-number-input>

              <v-number-input
                  v-else
                  density="compact"
                  class="w-25"
                  min="1"
                  hide-details
                  v-model="item.individualRegistrations"
                  @update:modelValue="callPutRegistration(item, 'registration', null, item.individualRegistrations)"
                  :disabled="item.stateRegistrationSheet === 'loading'"
              >
                <template v-slot:append-inner>
                  <v-progress-circular
                      v-if="item.stateRegistrationSheet === 'loading'"
                      color="blue-grey-darken-4"
                      class="mr-2"
                      indeterminate
                      size="15"
                      width="4"
                  ></v-progress-circular>
                  <v-icon v-else-if="item.stateRegistrationSheet === 'success'" icon="mdi-check" color="green" class="mr-2"></v-icon>
                  <v-icon v-else-if="item.stateRegistrationSheet === 'error'" icon="mdi-close" color="red" class="mr-2"></v-icon>
                </template>
              </v-number-input>

              <v-chip
                  v-if="item.seat !== null"
                  :color="getChipColor(item.seat - item.totalActivityRegistrations)"
              >
              <span class="font-weight-bold" v-if="(item.seat - item.totalActivityRegistrations) > 0">
        {{ Math.max(item.seat - item.totalActivityRegistrations, 0) }}
      </span>
                <p class="ml-1" v-if="(item.seat - item.totalActivityRegistrations) === 0">
                  Aucune place disponible
                </p>
                <p class="ml-1" v-if="(item.seat - item.totalActivityRegistrations) === 1">
                  Place disponible
                </p>
                <p class="ml-1" v-else-if="(item.seat - item.totalActivityRegistrations) > 1">
                  Places disponibles
                </p>
                <p class="ml-1" v-else-if="item.totalActivityRegistrations > item.seat">
                  <v-icon icon="mdi-alert"></v-icon> {{ item.totalActivityRegistrations - item.seat }} place en trop
                </p>
              </v-chip>
            </div>
          </template>-->
          <template v-slot:[`item.paid`]="{ item }">
            <v-tooltip :text="getTooltipText(item)" location="top">
              <template v-slot:activator="{ props }">
                <v-checkbox v-bind="props" v-model="item.paid" hide-details style="width: 60px" :disabled="item.statePaid === 'loading' || item.status === 'BLOCK' || item.status === 'CANCELED'" @update:modelValue="callPutRegistration(item, 'payement', item.paid, null)">
                  <template v-slot:append>
                    <v-progress-circular
                        v-if="item.statePaid === 'loading'"
                        color="blue-grey-darken-4"
                        indeterminate
                        size="15"
                        width="4"
                    ></v-progress-circular>
                    <v-icon v-if="item.statePaid === 'success'" icon="mdi-check" color="green">

                    </v-icon>
                    <v-icon v-if="item.statePaid === 'error'" icon="mdi-close" color="red" class="opacity-1">

                    </v-icon>
                  </template>
                </v-checkbox>
              </template>
            </v-tooltip>
          </template>
          <template v-slot:[`item.present`]="{ item }">
            <v-checkbox v-model="item.present" hide-details style="width: 60px" :disabled="item.statePresent === 'loading' || item.status === 'BLOCK'" @update:modelValue="callPutRegistration(item, 'present', item.present, null)">
              <template v-slot:append>
                <v-progress-circular
                    v-if="item.statePresent === 'loading'"
                    color="blue-grey-darken-4"
                    indeterminate
                    size="15"
                    width="4"
                ></v-progress-circular>
                <v-icon v-if="item.statePresent === 'success'" icon="mdi-check" color="green">

                </v-icon>
                <v-icon v-if="item.statePresent === 'error'" icon="mdi-close" color="red" class="opacity-1">

                </v-icon>
              </template>
            </v-checkbox>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex flex-column d-md-flex flex-md-row">
              <v-btn icon=" mdi-pen-remove" density="comfortable" class="ml-0 mt-1 ml-md-2 mt-md-0" @click="callDeleteRegistration(item)" color="red"></v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </section>
  <DialogRegistrationComponent @finish="callGetActivityId()" />
</template>
<script>
import {activityStore} from "@/store/activityStore";
import DialogRegistrationComponent from "@/components/Registration/DialogRegistrationComponent";
import {registrationStore} from "@/store/registrationStore";
import {useDisplay} from "vuetify";
import {generalStore} from "@/store/General/generalStore";
import html2pdf from "html2pdf.js";

export default {
  name: 'InfoActivityComponent',
  components: {DialogRegistrationComponent},
  setup() {
    const { mdAndUp } = useDisplay()
    return {
      mdAndUp
    }
  },
  data() {
    return {
      stateMoreInfos: true,
      headerRegistration: [
          { title: 'Nom', align: 'center', key: 'lastname', sortable: false},
          { title: 'Prénom', align: 'start', sortable: true, key: 'firstname'},
          { title: 'Payement', align: 'start', sortable: true, key: 'paid'},
          { title: 'Present', align: 'start', sortable: true, key: 'present'},
          { title: '', align: 'start', key: 'action', sortable: false},
      ]
    }
  },
  computed: {
    getActivityId() {
      return activityStore().getActivityId;
    },
    getRegistrationActivity() {
      return registrationStore().getRegistrationActivity;
    },
    getRegistrationActivityPagination() {
      return registrationStore().getRegistrationActivityPagination;
    },
  },
  methods: {
    async callGetActivityId() {
      this.loadingDataTableActivity = true;
      try {
        const response1 = await activityStore().callGetActivityId(this.getActivityId.id);
        if (response1) {
          const response2 = await registrationStore().callGetRegistrationActivity(this.getActivityId.id);
          if (response2) {
            clearInterval(this.timerLastRefresh);
            this.goToWindow(2);
          } else {
            generalStore().showNotifSnackbar("ERREUR", "orange", true, 5000);
            this.codeRefresh = 2;
            clearInterval(this.timerLastRefresh);
          }
        } else {
          generalStore().showNotifSnackbar("ERREUR", "orange", true, 5000);
          this.codeRefresh = 2;
          clearInterval(this.timerLastRefresh);
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la récupération des fiches !", "red-darken-3", true, 5000);
        this.codeRefresh = 2;
        clearInterval(this.timerLastRefresh);
      }
      this.loadingDataTableActivity = false;
    },
    async callPutRegistration(item, type, value, number) {
      if (type === "payement") {
        item.statePaid = "loading";
      } else if (type === "refunded") {
        item.stateRefunded = "loading";
      } else if (type === "present") {
        item.statePresent = "loading";
      } else {
        item.stateRegistrationSheet = "loading";
      }
      try {
        const response = await registrationStore().callPutRegistration(item.id, type, value, number);

        if (response) {
          if (type === "payement") {
            item.statePaid = "success";
          } else if (type === "refunded") {
            item.stateRefunded = "success";
          } else if (type === "present") {
            item.statePresent = "success";
          } else {
            item.stateRegistrationSheet = "success";
          }
          if (item.timer) {
            clearTimeout(item.timer);
          }
          item.timer = setTimeout(() => {
            item.statePaid = null;
            item.stateRefunded = null;
            item.statePresent = null;
            item.stateRegistrationSheet = null;
            item.timer = null;
          }, 15000);
          this.callGetActivityId();
        } else {
          if (type === "payement") {
            item.statePaid = "error";
          } else if (type === "refunded") {
            item.stateRefunded = "error";
          } else if (type === "present") {
            item.statePresent = "error";
          } else {
            item.stateRegistrationSheet = "error";
          }
          if (item.timer) {
            clearTimeout(item.timer);
          }
          item.timer = setTimeout(() => {
            item.statePaid = null;
            item.stateRefunded = null;
            item.statePresent = null;
            item.stateRegistrationSheet = null;
            item.timer = null;
          }, 15000);
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de mise à jour de la fiche !", "red-darken-3", true, 5000);
        item.stateRegistrationSheet = "error";
        this.callGetActivityId();
        if (item.timer) {
          clearTimeout(item.timer);
        }
        item.timer = setTimeout(() => {
          item.statePaid = null;
          item.stateRefunded = null;
          item.statePresent = null;
          item.stateRegistrationSheet = null;
          item.timer = null;
        }, 15000);
      }
    },
    async callDeleteRegistration(item) {
      item.statePresent = "loading";
      item.stateRefunded = "loading";
      item.statePaid = "loading";
      item.stateRegistrationSheet = "loading";
      await registrationStore().callDeleteRegistration(item.id);
      this.callGetActivityId();
    },
    formatDate(dateValue, withTime = false) {
      if (!dateValue || typeof dateValue !== 'object') {
        return "";
      }
      if (!dateValue.date) {
        return "Date invalide";
      }
      dateValue = dateValue.date;
      const cleanDate = dateValue.split('.')[0].replace(' ', 'T');
      const date = new Date(cleanDate);
      // Vérifier si la date est valide
      if (isNaN(date)) return "Date invalide";
      const options = {
        timeZone: 'Europe/Paris',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
      if (withTime) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.second = '2-digit';
      }
      return date.toLocaleString('fr-FR', options);
    },
    getTooltipText(item) {
      const individualRegistrations = item.individualRegistrations; // Si tu as une propriété pour le nombre d'inscriptions
      const isPaid = item.paid;

      if (individualRegistrations === 1) {
        return isPaid
            ? "L'inscription n'est pas payée"
            : "L'inscription est-elle payée ?";
      } else {
        return isPaid
            ? "Les inscriptions ne sont pas payées"
            : "Les inscriptions sont-elles payées ?";
      }
    },
    showDialogType() {
      registrationStore().dialogRegistrationShow = true;
    },
    getChipColor(availablePlaces) {
      if (availablePlaces <= 0) {
        return 'red'; // Full
      } else if (availablePlaces === 1) {
        return 'deep-orange'; // Un peu rouge
      } else if (availablePlaces <= 3) {
        return 'amber'; // Jaune foncé
      } else if (availablePlaces <= 5) {
        return 'yellow'; // Jaune
      } else {
        return 'green'; // Plus de 5 places disponibles
      }
    },
    goToWindow(state) {
      this.$emit('finish');
      activityStore().stateWindowActivity = state;
    },
    async pdfDataSheet() {
      this.loadingButtonPDF = true;
      this.colorPDF = "blue-grey-lighten-1";
      const calculateAge = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      };
      const logoSrc = require('@/assets/logo-vpconnect.png');
      const table = document.createElement('table');
      table.innerHTML = `
  <caption style="border: 5px solid black; padding: 10px; font-weight: bold; text-transform: uppercase; position: relative;">
    <img src="${logoSrc}" alt="Logo" style="position: absolute; top: 10px; left: 10px; width: 70px; height: auto;">
    <img src="${logoSrc}" alt="Logo" style="position: absolute; top: 10px; right: 10px; width: 70px; height: auto;">
    <div style="margin-left: 70px;">
      INSCRIPTIONS ACTIVITÉ ${this.getActivityId.name}<br>
      Date de début : ${new Date(this.getActivityId.startActivityDate.date).toLocaleString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      })}<br>
      Date de fin : ${new Date(this.getActivityId.endActivityDate.date).toLocaleString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      })}
    </div>
  </caption>
  <thead>
    <tr style="border-left: 1px solid black; border-right: 1px solid black;">
      <th>#</th>
      <th style="padding: 10px 0">Nom/Prénom</th>
      <th>Naissance</th>
      <th>Téléphone</th>
      <th>E-mail</th>
      <th>Commentaire</th>
      <th>Structure</th>
      <th>Payé</th>
      <th>Présent</th>
    </tr>
  </thead>
  <tbody>
    ${this.getRegistrationActivity.map((item, index) => {
        const dateStr = item.birthday?.date || '';
        const formattedDate = dateStr ? new Date(dateStr).toLocaleDateString('fr-FR') : '';
        const age = dateStr ? calculateAge(dateStr) : '';

        return `
          <tr style="
            border-left: 1px solid black;
            border-right: 1px solid black;
            border-top: 1px solid black;
            ${index === this.getRegistrationActivity.length - 1 ? 'border-bottom: 1px solid black;' : ''}
          ">
            <td style="padding: 10px;">${index + 1}</td>
            <td style="font-size: 12px;">
              <b>${item.lastname.toUpperCase()}</b><br>${item.firstname}
            </td>
            <td>
              ${formattedDate}<br>
              <span style="font-size: 12px;">${age ? `Âge: ${age} ans` : ''}</span>
            </td>
            <td style="font-size: 12px; padding: 10px;">${item.phone ? item.phone : ''}</td>
            <td style="font-size: 12px;">${item.email ? item.email : ''}</td>
            <td style="font-size: 10px; padding: 10px;">${item.comment ? item.comment : ''}</td>
            <td style="font-size: 8px;">${item.structure ? item.structure : ''}</td>
              <td style="font-size: 12px;">
              <input type="checkbox" class="custom-checkbox" ${item.paid ? 'checked' : ''} />
            </td>
            <td style="font-size: 12px;">
              <input type="checkbox" class="custom-checkbox" ${item.present ? 'checked' : ''} />
            </td>
          </tr>
        `;
      }).join('')}
  </tbody>
  <style>
    table, tr {
      page-break-inside: avoid;
    }
    input.custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #555;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

/* Style de la case quand elle est cochée */
input.custom-checkbox:checked {
  background-color: #2196F3;
  border-color: #2196F3;
}

/* Ajout d'un symbole de coche via le pseudo-élément */
input.custom-checkbox:checked::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
  </style>
`;

      table.style.width = "100%";
      table.style.pageBreakInside = "avoid"
      table.style.borderCollapse = "collapse";
      table.style.textAlign = "center";
      table.style.fontSize = "15px";
      const nomFichierPDF = this.getActivityId.name + ".pdf";

      const opt = {
        margin: 5,
        jsPDF: {
          format: 'a4',
          orientation: 'landscape'
        },
        html2canvas: {
          scale: 2,
          pagebreak: {mode: ['avoid-all']}
        }
      };

      await html2pdf()
          .set(opt)
          .from(table)
          .toPdf()
          .get('pdf')
          .then(function (pdf) {
            // Regarder si la couleur est bien noire ici avant de sauvegarder
            pdf.save(nomFichierPDF);
          });
      this.loadingButtonPDF = false;
      this.colorPDF = "blue-grey-darken-4";
    }
  }
}
</script>
<style scoped>
.blink {
  animation: blink-animation 0.8s infinite;
}
@keyframes blink-animation {
  0%, 70% {
    visibility: visible;
  }
  71%, 100% {
    visibility: hidden;
  }
}
</style>
