import {defineStore} from 'pinia'
import axios from "axios";
import {authentificationStore} from "@/store/authentificationStore";
import {generalStore} from "@/store/General/generalStore";

export const sheetStore = defineStore('sheetStore', {
    state: () => {
        return {
            sheet: [],
            sheetPagination: [],
            sheetId: [],
            stateWindowSheet: 1,
            dialogSheetShow: false,
        }
    },
    getters: {
        getSheet: (state) => {
            return state.sheet;
        },
        getSheetPagination: (state) => {
            return state.sheetPagination;
        },
        getSheetId: (state) => {
            return state.sheetId;
        },
        getDialogSheetShow: (state) => {
            return state.dialogSheetShow;
        },
    },
    actions: {
        toogleDialogSheet() {
            this.dialogSheetShow = !this.dialogSheetShow;
        },
        async callGetSheet(search = '', limit = 10, page = 1, structure = "TOUS") {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_SHEET,{
                    headers: { Authorization: 'Bearer ' + authentificationStore().getToken },
                    params: {
                        search: search,
                        limit: limit,
                        page: page,
                        structure: structure,
                    }
                });
                this.sheet = response.data.data;
                this.sheetPagination = response.data.pagination;
                return true;
            } catch(error) {
                return false;
            }
        },
        async callGetSheetId(id) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_SHEET + '/' + id, {
                    headers: { Authorization: 'Bearer ' + authentificationStore().getToken },
                });
                this.sheetId = response.data.data;
                return true;
            } catch(error) {
                return false;
            }
        },
        async callPostSheet(lastname, firstname, birthday = null, phone = null, email = null, comment = null, structure = null) {
            try {
                const response = await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_SHEET,{
                        lastname: lastname,
                        firstname: firstname,
                        birthday: birthday,
                        phone: phone,
                        email: email,
                        comment: comment,
                        structure: structure,
                },{
                    headers: {
                        Authorization: 'Bearer ' + authentificationStore().getToken }
                });
                if (response.data && response.data.message) {
                    generalStore().showNotifSnackbar(response.data.message, "light-green-darken-1", true, 10000);
                }
                return true;
            } catch(error) {
                if (error.response) {
                    if(error.response.status === 409) {
                        generalStore().showNotifSnackbar("Cette fiche existe déjà dans la base de donnée !", "red-darken-3", true, 5000);
                    } else {
                        generalStore().showNotifSnackbar("Une erreur est survenue, veuillez réessayer !", "red-darken-3", true, 5000);
                    }
                } else if (error.request) {
                    generalStore().showNotifSnackbar("Erreur le serveur ne répond pas !", "red-darken-3", true, 5000);
                } else {
                    generalStore().showNotifSnackbar("Erreur lors de la requête !", "red-darken-3", true, 5000);
                }
                return false;
            }
        },
        async callPutSheet(id, lastname, firstname, birthday, phone, email, comment, structure) {
            try {
                await axios.put(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_SHEET + '/' + id,{
                    lastname: lastname,
                    firstname: firstname,
                    birthday: birthday,
                    phone: phone,
                    email: email,
                    comment: comment,
                    structure: structure,
                },{
                    headers: {
                        Authorization: 'Bearer ' + authentificationStore().getToken }
                });
                return true;
            } catch(error) {
                return false;
            }
        },
        async callDeleteSheet(id) {
            try {
                await axios.delete(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_SHEET + '/' + id,{
                    headers: { Authorization: 'Bearer ' + authentificationStore().getToken },
                });

                return true;
            } catch(error) {
                if (error.response) {
                    if(error.response.status === 401) {
                        generalStore().showNotifSnackbar("Erreur vous n'êtes plus connecté pour supprimer cette fiche !", "red-darken-3", true, 5000);
                    } else {
                        generalStore().showNotifSnackbar("Une erreur est survenue, veuillez réessayer !", "red-darken-3", true, 5000);
                    }
                } else if (error.request) {
                    generalStore().showNotifSnackbar("Erreur le serveur ne répond pas !", "red-darken-3", true, 5000);
                } else {
                    generalStore().showNotifSnackbar("Erreur lors de la requête !", "red-darken-3", true, 5000);
                }
                return false;
            }
        },
    }
})
