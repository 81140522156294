import {defineStore} from 'pinia'
import axios from "axios";
import {jwtDecode} from "jwt-decode";
import {generalStore} from "@/store/General/generalStore";
import router from "@/router";

export const authentificationStore = defineStore('authentificationStore', {
    state: () => {
        return {
            connected: false,
            token: null,
            expireDateToken: null,
            userInfo: [],
        }
    },
    getters: {
        getConnected: (state) => {
            return !(state.token === null || state.token === "" || state.connected === false);
        },
        getToken: (state) => {
            return state.token
        },
        getExpireDateToken: (state) => {
            return state.expireDateToken
        },
        getUserInfo: (state) => {
            return state.userInfo
        },
    },
    actions: {
        async callPostLogin(username, password) {
            try {
                const response = await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_LOGIN,
                {
                    username: username,
                    password: password
                });
                this.token = response.data.token;
                this.userInfo = jwtDecode(response.data.token).userInfo;
                this.expireDateToken = jwtDecode(response.data.token).exp;
                this.connected = true;
                return true;
            } catch(error) {
                if (error.response) {
                    if(error.response.status === 401) {
                        generalStore().showNotifSnackbar("Erreur vérifier votre mot de passe ou identifiant !", "red-darken-3", true, 5000);
                    } else {
                        generalStore().showNotifSnackbar("Une erreur est survenue, veuillez réessayer !", "red-darken-3", true, 5000);
                    }
                } else if (error.request) {
                    generalStore().showNotifSnackbar("Erreur le serveur ne répond pas !", "red-darken-3", true, 5000);
                } else {
                    generalStore().showNotifSnackbar("Erreur lors de la requête !", "red-darken-3", true, 5000);
                }
                return false;
            }
        },
        async callPostForgotPassword(email) {
            try {
                const response = await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_USER_FORGOT_PASSWORD,
                    {
                        email: email
                    });
                if (response.data && response.data.message) {
                    generalStore().showNotifSnackbar(response.data.message, "light-green-darken-1", true, 10000);
                }
                return true;
            } catch (error) {
                if (error.response) {
                    if (error.response.data && Array.isArray(error.response.data.errors)) {
                        error.response.data.errors.forEach(err => {
                            generalStore().showNotifSnackbar(("#" + (err.code || "ERREUR") + ": ") + (err.message || "Une erreur est survenue, veuillez réessayer !"), "red-darken-3", true, 8000);
                        });
                    } else {
                        generalStore().showNotifSnackbar("Une erreur est survenue, veuillez réessayer !", "red-darken-3", true, 5000);
                    }
                } else if (error.request) {
                    generalStore().showNotifSnackbar("Erreur le serveur ne répond pas !", "red-darken-3", true, 5000);
                } else {
                    generalStore().showNotifSnackbar("Erreur lors de la requête !", "red-darken-3", true, 5000);
                }
                return false;
            }
        },
        async callPostResetPassword(token, newPassword) {
            try {
                const response = await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_USER_RESET_PASSWORD,
                    {
                        token: token,
                        password: newPassword
                    });
                if (response.data && response.data.message) {
                    generalStore().showNotifSnackbar(response.data.message, "light-green-darken-1", true, 10000);
                }
                return true;
            } catch (error) {
                if (error.response) {
                    if (error.response.data && Array.isArray(error.response.data.errors)) {
                        error.response.data.errors.forEach(err => {
                            generalStore().showNotifSnackbar(("#" + (err.code || "ERREUR") + ": ") + (err.message || "Une erreur est survenue, veuillez réessayer !"), "red-darken-3", true, 8000);
                        });
                    } else {
                        generalStore().showNotifSnackbar("Une erreur est survenue, veuillez réessayer !", "red-darken-3", true, 5000);
                    }
                } else if (error.request) {
                    generalStore().showNotifSnackbar("Erreur le serveur ne répond pas !", "red-darken-3", true, 5000);
                } else {
                    generalStore().showNotifSnackbar("Erreur lors de la requête !", "red-darken-3", true, 5000);
                }
                return false;
            }
        },
        async callPostLogout() {
            try {
               this.token = null;
               this.connected = false;
               this.expireDateToken = null;
               this.userInfo = [];
               generalStore().showNotifSnackbar("Vous avez été déconnecté ! À bientôt !", "light-green-darken-1", true, 5000);
               await router.push("/");
            } catch {
                return false;
            }
        },
        checkTokenExpiration() {
            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (!this.expireDateToken || currentTimestamp > this.expireDateToken) {
                if (router.currentRoute.value.name !== 'Se connecter') {
                    this.token = null;
                    this.connected = false;
                    this.expireDateToken = null;
                    this.userInfo = [];
                    generalStore().showNotifSnackbar("Votre session a expiré ! Vous devez vous reconnecter !", "red-darken-3", true, -1);
                    router.push("/");
                }
            }
        },
    },
    persist: {
        storage: localStorage,
        paths: ['token', 'connected', 'expireDateToken', 'userInfo'],
    },
})
