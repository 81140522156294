<template>
  <SnackbarComponent />
  <TipsComponent />
  <v-app>
    <NavComponent v-if="getIsConnected && !isLoginPage" />
    <TopbarComponent v-if="getIsConnected && !isLoginPage" />
    <v-main class="bg-blue-grey-darken-1 fill-height h-100">
      <router-view />
    </v-main>
    <FooterComponent />
  </v-app>
</template>

<script>
import SnackbarComponent from "@/components/General/SnackbarComponent";
import {generalStore} from "@/store/General/generalStore";
import {authentificationStore} from "@/store/authentificationStore";
import TipsComponent from "@/components/General/TipsComponent";
import NavComponent from "@/components/General/NavComponent";
import TopbarComponent from "@/components/General/TopbarComponent";
import FooterComponent from "@/components/General/FooterComponent";

export default {
  name: 'App',
  components: {FooterComponent, TopbarComponent, NavComponent, TipsComponent, SnackbarComponent },
  computed: {
    getIsConnected() {
      return authentificationStore().getConnected;
    },
    isLoginPage() {
      return this.$route.path === "/";
    }
  },
  data() {
    return {
      overlay: true
    }
  },
  setup() {
    setInterval(() => {
      authentificationStore().checkTokenExpiration();
    }, 1000);
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      window.innerWidth <= 960 ? generalStore().isMobile = true : generalStore().isMobile = false;
      if (generalStore().getIsMobile) {
        generalStore().stateNavbar = false;
        generalStore().navbarShowIcon = "mdi-menu";
      } else {
        generalStore().stateNavbar = true;
        generalStore().navbarShowIcon = "mdi-menu-open";
      }
    },
  },
};
</script>