import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { loadFonts } from './plugins/webfontloader'
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia'
import fr from './locales/fr.json';
import './registerServiceWorker'

loadFonts()

const i18n = createI18n({
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: fr
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

createApp(App).use(pinia).use(i18n)
  .use(router)
  .use(vuetify)
  .mount('#app')
