import {defineStore} from 'pinia'
import axios from "axios";
import {authentificationStore} from "@/store/authentificationStore";

export const dashboardStore = defineStore('dashboardStore', {
    state: () => {
        return {
            dashboard: [],
        }
    },
    getters: {
        getDashboard: (state) => {
            return state.dashboard;
        }
    },
    actions: {
        async callGetDashboard() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_DASHBOARD,{
                    headers: { Authorization: 'Bearer ' + authentificationStore().getToken }
                });
                this.dashboard = response.data.data;
                return true;
            } catch(error) {
                return false;
            }
        },
    }
})
