import {defineStore} from 'pinia'
export const errorStore = defineStore('errorStore', {
    state: () => {
        return {
            errorCode: "200",
            errorMessage: "Il n'y a aucun soucis ? Pourquoi venir ici ?",
            errorColor: "green-darken-1",
            errorIcon: "mdi-robot-happy",
        }
    },
    actions: {
        setError(code, message, color, icon) {
            this.errorCode = code;
            this.errorMessage = message;
            this.errorColor = color;
            this.errorIcon = icon;
        },
    }
})
