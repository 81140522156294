<template>
  <section class="pa-4">
    <v-window direction="vertical" v-model="getStateWindowActivity">
      <v-window-item :value="1">
        <keep-alive>
          <ListActivityComponent v-if="getStateWindowActivity === 1" />
        </keep-alive>
      </v-window-item>
      <v-window-item :value="2">
       <InfoActivityComponent/>
      </v-window-item>
    </v-window>
  </section>
</template>

<script>
import InfoActivityComponent from "@/components/Activity/InfoActivityComponent";
import {activityStore} from "@/store/activityStore";
import ListActivityComponent from "@/components/Activity/ListActivityComponent";

export default {
  name: 'ActivityView',
  components: {ListActivityComponent, InfoActivityComponent},
  data() {
    return {
      window: 2,
    };
  },
  computed: {
    getStateWindowActivity() {
      return activityStore().stateWindowActivity;
    }
  },
};
</script>
<style scoped>
</style>