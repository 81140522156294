<template>
  <v-app-bar color="blue-grey-darken-4" app elevation="0">
    <v-app-bar-nav-icon @click="toggleNavbar()"><v-icon :icon="getNavbarShowIcon"></v-icon></v-app-bar-nav-icon>
    <v-toolbar-title>{{ this.$route.name }}</v-toolbar-title>
    <template v-slot:append>
      <v-menu transition="slide-y-transition">
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-bell" v-bind="props" disabled></v-btn>
        </template>
        <v-card class="notification-card" elevation="3" color="blue-grey-darken-4" max-width="350px" max-height="350px">
          <v-card-title>
            <v-icon class="notification-icon" color="primary">mdi-bell</v-icon>
            Notifications
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list
                :items="items"
                lines="three"
                item-props
            >
              <template v-slot:subtitle="{ subtitle }">
                <div v-html="subtitle"></div>
              </template>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="clearNotifications">
              Effacer toutes les notifications
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-menu transition="slide-y-transition">
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-account" v-bind="props"></v-btn>
        </template>
        <v-list class="pa-0 rounded-0">
          <v-list-item prepend-icon="mdi-eye" title="Mon profil" class="bg-light-blue-darken-1" @click="goTo('/profile')"></v-list-item>
          <v-list-item prepend-icon="mdi-logout" title="Se déconnecter" class="bg-red" @click="logout()"></v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>
<script>
import {generalStore} from "@/store/General/generalStore";
import {authentificationStore} from "@/store/authentificationStore";

export default {
  name: 'TopbarComponent',
  computed: {
    getNavbarShowIcon() {
      return generalStore().getNavbarShowIcon;
    }
  },
  data() {
    return {
      items: [
        { type: 'subheader', title: 'Today' },
        {
          prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Brunch this weekend?',
          subtitle: `<span class="text-primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        { type: 'divider', inset: true },
        {
          prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Summer BBQ',
          subtitle: `<span class="text-primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        { type: 'divider', inset: true },
        {
          prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Oui oui',
          subtitle: '<span class="text-primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        },
        { type: 'divider', inset: true },
        {
          prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          title: 'Birthday gift',
          subtitle: '<span class="text-primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
        },
        { type: 'divider', inset: true },
        {
          prependAvatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
          title: 'Recipe to try',
          subtitle: '<span class="text-primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
        },
      ],
    }
  },
  methods: {
    goTo(url) {
      this.$router.push(url);
    },
    logout() {
      authentificationStore().callPostLogout();
    },
    toggleNavbar() {
      generalStore().toogleNavbar();
    },
  },
}
</script>
