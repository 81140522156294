<template>
  <section>
    <hr style="border: none; border-top: 1px solid #404B50;">
    <v-row class="h-100" no-gutters>
      <v-col cols="12">
        <v-card elevation="0" rounded="0">
          <v-tabs
              v-model="tab"
              bg-color="blue-grey-darken-4"
              center-active
              color="light-blue-accent-4"
              show-arrows
              next-icon="mdi-arrow-right"
              prev-icon="mdi-arrow-left"
              grow
          >
            <v-tab value="tab-1" style="border: none; border-right: 1px solid #404B50;">Informations Basique</v-tab>
            <v-tab value="tab-2" style="border: none; border-right: 1px solid #404B50;">E-mail</v-tab>
            <v-tab value="tab-3">Mot de passe</v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <section class="pa-4">
      <v-row no-gutters>
        <v-col cols="12">
          <v-tabs-window v-model="tab">
            <!-- Onglet Informations Basique -->
            <v-tabs-window-item value="tab-1">
              <v-card elevation="0" rounded="0" class="pa-4">
                <v-card-text class="pa-0">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-text-field v-model="inputLastname" label="Nom" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="inputFirstname" label="Prénom" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="inputJob" label="Job" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" class="text-center">
                          <v-btn color="blue-grey-darken-4">Modifier les infos</v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex justify-center align-center">
                      <v-avatar icon="$vuetify" image="https://ui-avatars.com/api/?name=Jean+Dupont&background=0D8ABC&color=ffffff" size="190"></v-avatar>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" md="4">
                      <v-card color="blue-grey-darken-4" class="mx-auto opacity-100 text-center">
                        <template v-slot:title>
                          <h4 class="font-weight-light">État du compte</h4>
                        </template>
                        <template v-slot:subtitle>
                          <h3 class="text-white">Activé</h3>
                        </template>
                        <template v-slot:prepend>
                          <v-icon icon="mdi-account" class="mr-2"></v-icon>
                        </template>
                        <template v-slot:append>
                          <v-avatar color="white">
                            <v-icon icon="mdi-check-circle" color="green"></v-icon>
                          </v-avatar>
                        </template>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-card color="blue-grey-darken-4" class="mx-auto opacity-100 text-center">
                        <template v-slot:title>
                          <h4 class="font-weight-light">Déconnexion dans</h4>
                        </template>
                        <template v-slot:subtitle>
                          <h3 class="text-white">15 Mars 2015 à 18h05</h3>
                        </template>
                        <template v-slot:prepend>
                          <v-icon icon="mdi-account-clock" class="mr-2"></v-icon>
                        </template>
                        <template v-slot:append>
                          <v-avatar color="white">
                            <v-icon icon="mdi-check-circle" color="green"></v-icon>
                          </v-avatar>
                        </template>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-card
                          color="blue-grey-darken-4"
                          class="mx-auto opacity-100 text-center"
                          prepend-icon="mdi-github"
                          subtitle="1 mois 5 jours et 12 heures"
                          title="Changer votre mot de passe dans :"
                      >
                        <template v-slot:append>
                          <v-avatar color="white">
                            <v-icon icon="mdi-alert-circle" color="orange"></v-icon>
                          </v-avatar>
                        </template>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-card color="blue-grey-darken-4" class="mx-auto opacity-100 text-center">
                        <template v-slot:title>
                          <h4 class="font-weight-light">Modification récente</h4>
                        </template>
                        <template v-slot:subtitle>
                          <h3 class="text-white">15 Mars 2015 à 17h05</h3>
                        </template>
                        <template v-slot:prepend>
                          <v-icon icon="mdi-account-edit" class="mr-2"></v-icon>
                        </template>
                        <template v-slot:append>
                          <v-icon></v-icon>
                        </template>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-card color="blue-grey-darken-4" class="mx-auto opacity-100 text-center">
                        <template v-slot:title>
                          <h4 class="font-weight-light">Création du compte</h4>
                        </template>
                        <template v-slot:subtitle>
                          <h3 class="text-white">15 Mars 2015 à 17h05</h3>
                        </template>
                        <template v-slot:prepend>
                          <v-icon icon="mdi-account-check"></v-icon>
                        </template>
                        <template v-slot:append>
                          <v-icon></v-icon>
                        </template>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tabs-window-item>

            <!-- Onglet E-mail -->
            <v-tabs-window-item value="tab-2">
              <v-card elevation="0" rounded="0" class="pa-4">
                <v-card-text class="pa-0">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-text-field v-model="inputJob" label="Votre e-mail actuelle" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="inputEmail" label="Nouvel e-mail"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="inputConfirmEmail" label="Confirmer l'e-mail"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="text-center">
                          <v-btn color="blue-grey-darken-4">Modifier l'email</v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex justify-center align-center">
                      <v-alert>
                        <template v-slot:text>
                          <p>Pour changer d'email, renseigner votre nouvelle email dans le formulaire. Une fois la validation effectué vous allez recevoir 2 emails. Un email dans votre ancienne boite email ainsi qu'un autre dans la nouvelle adresse email. Vous devez confirmer les changements dans les deux emails en cliquant sur le lien qui vous a été envoyé dans les 2 boites mails</p>
                        </template>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tabs-window-item>

            <!-- Onglet Mot de passe -->
            <v-tabs-window-item value="tab-3">
              <v-card elevation="0" rounded="0" class="pa-4">
                <v-card-text class="pa-0">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-text-field v-model="inputJob" label="Votre e-mail actuelle" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="inputEmail" label="Nouvel e-mail"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="inputConfirmEmail" label="Confirmer l'e-mail"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="text-center">
                          <v-btn color="blue-grey-darken-4">Modifier le mot de passe</v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex justify-center align-center">
                      <v-alert>
                        <template v-slot:text>
                          <p>Pour changer votre mot de passe renseigner votre ancien mot de passe puis un nouveau mot de passe avec ca confirmation dans le formulaire. Une fois la validation effectué vous allez recevoir 1 email de confirmation du changement !</p>
                        </template>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-col>
      </v-row>
    </section>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { authentificationStore } from "@/store/authentificationStore";
import {required, email, helpers, maxLength, sameAs, minLength} from "@vuelidate/validators";

export default defineComponent({
  name: 'ProfileView',
  data() {
    return {
      tab: "tab-1",
      inputPassword: null,
      inputConfirmPassword: null,
      inputOldPassword: null,
      inputEmail: null,
      inputConfirmEmail: null,
      inputLastname: null,
      inputFirstname: null,
      inputJob: null,
      inputPhone: null,
      inputAddress: null,
      inputCity: null,
      inputPostalCode: null,
      inputCountry: null,
      notifEmail: false,
      notifSMS: false
    };
  },
  computed: {
    getUserInfo() {
      return authentificationStore().getUserInfo;
    }
  },
  created() {
    if (this.getUserInfo) {
      this.inputFirstname = this.getUserInfo.firstname;
      this.inputLastname = this.getUserInfo.lastname;
    }
  },
  validations() {
    return {
      infoUserForm: {
        username: {
          required: helpers.withMessage("L'identifiant est requis.", required),
        },
        password: {
          required: helpers.withMessage('Le mot de passe est requis.', required),
          minLength: helpers.withMessage('Le mot de passe doit contenir au moins 8 caractères.', (value) => value.length >= 8),
          maxLength: helpers.withMessage('Le mot de passe ne peut pas dépasser 255 caractères.', (value) => value.length <= 255)
        },
      },
      emailUserForm: {
        email: {
          required: helpers.withMessage("L'e-mail est requis !", required),
          email: helpers.withMessage("L'e-mail n'est pas valide.", email),
        },
      },
      passwordUserForm: {
        newPassword: {
          required: helpers.withMessage("Le mot de passe est requis.", required),
          minLength: helpers.withMessage("Le mot de passe doit contenir au moins 8 caractères.", minLength(8)),
          maxLength: helpers.withMessage("Le mot de passe ne peut pas dépasser 255 caractères.", maxLength(255)),
        },
        confirmNewPassword: {
          required: helpers.withMessage("La confirmation du mot de passe est requise.", required),
          sameAs: helpers.withMessage("Les mots de passe doivent correspondre.", sameAs(this.resetPasswordForm.newPassword)),
        },
      },
    }
  },
  methods: {

  }
});
</script>
<style scoped>
:deep(.v-card-subtitle) {
  opacity: 1;
  text-transform: uppercase;
  font-weight: bold;
}
</style>