<template>
  <section :class="[{ 'pa-4': mdAndUp }, { 'pa-1': !mdAndUp }]">
    <v-row no-gutters class="bg-white pa-2">
      <v-col cols="12">
        <v-text-field
            prepend-inner-icon="mdi-pencil"
            label="Renseigner le nom du fichier final"
            v-model="finalFileName"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
            prepend-inner-icon="mdi-pencil"
            label="Renseigner l'auteur du fichier final"
            v-model="finalAuthor"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-number-input
            v-model.number="numberOfFiles"
            type="number"
            label="Nombre de fichiers à fusionner"
            :min="2"
            :max="10"
            @update:modelValue="updateFiles"
        ></v-number-input>
      </v-col>
      <v-col>
        <div v-for="(file, index) in files" :key="index" class="mt-3">
          <v-file-input
              v-model="file.file"
              prepend-icon=""
              prepend-inner-icon="mdi-file-pdf-box"
              :label="getFileUploadTitle(index + 1)"
              accept="application/pdf"
              class="ma-0 pa-0"
              @change="validateFile(index)"
          />
          <div v-if="!file.isValid" class="error-message">
            Le fichier numéro {{ index + 1 }} est invalide ou vide.
          </div>
        </div>

        <p v-if="files.length >= 10" class="text-caption text-red mt-3">
          Vous ne pouvez pas ajouter plus de 10 fichiers.
        </p>

        <div class="d-flex justify-center align-content-center align-center my-5">
        <v-btn @click="mergePDFs" color="blue-grey-darken-4" :disabled="files.length < 2 || hasErrors" class="mt-5">
          Fusionner les PDF
        </v-btn>
        </div>

        <!-- Message d'erreur général -->
        <div v-if="errorMessage" class="error-message mt-3">{{ errorMessage }}</div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { PDFDocument } from "pdf-lib";
import {useVuelidate} from "@vuelidate/core";
import {useDisplay} from "vuetify";
import {helpers, required} from "@vuelidate/validators/dist/index";

export default {
  name: "PdfFusionView",
  setup() {
    const { mdAndUp } = useDisplay()
    return {
      v$: useVuelidate(),
      mdAndUp
    }
  },
  data() {
    return {
      finalFileName: "",
      finalAuthor: "",
      numberOfFiles: 2, // Contrôle du nombre de fichiers via l'input number
      files: Array.from({ length: 2 }, () => ({ file: null, isValid: true })), // Initialise avec 2 fichiers
      errorMessage: "",
    };
  },
  validations() {
    return {
      pdfMergerForm: {
        fileName: {
          required: helpers.withMessage("L'url du QRCode est requise.", required),
        },
        authorName: {
          required: helpers.withMessage("Le nom du QRCode est requis.", required),
        },
      },
    }
  },
  computed: {
    hasErrors() {
      return this.files.some((file) => !file.isValid);
    },
    getFileUploadTitle() {
      return (index) => `Ajouter le Fichier PDF numéro ${index} à fusionner`;
    },
  },
  methods: {
    updateFiles() {
      const newFiles = Array.from({ length: this.numberOfFiles }, (_, index) => {
        return this.files[index] || { file: null, isValid: true };
      });
      this.files = newFiles;
      this.errorMessage = "";
    },
    validateFile(index) {
      const file = this.files[index].file;
      if (!file || file.type !== "application/pdf") {
        this.files[index].isValid = false;
        this.errorMessage = `Le fichier ${index + 1} n'est pas un PDF valide.`;
        return;
      }
      this.isFileEmpty(file).then((isEmpty) => {
        if (isEmpty) {
          this.files[index].isValid = false;
          this.errorMessage = `Le fichier ${index + 1} est vide.`;
        } else {
          this.files[index].isValid = true;
          this.errorMessage = "";
        }
      });
    },
    async isFileEmpty(file) {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        return pdfDoc.getPageCount() === 0;
      } catch {
        return true;
      }
    },
    async mergePDFs() {
      const mergedPdf = await PDFDocument.create();

      for (const { file, isValid } of this.files) {
        if (file && isValid) {
          const arrayBuffer = await file.arrayBuffer();
          const pdf = await PDFDocument.load(arrayBuffer);
          const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
          copiedPages.forEach((page) => mergedPdf.addPage(page));
        }
      }

      mergedPdf.setTitle(this.finalFileName || "Document Fusionné");
      mergedPdf.setAuthor(this.finalAuthor || "Anonyme");

      const pdfBytes = await mergedPdf.save();
      this.downloadPDF(pdfBytes, this.finalFileName || "merged.pdf");
    },
    downloadPDF(pdfBytes, fileName) {
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
};
</script>