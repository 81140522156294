<template>
  <section :class="[{ 'pa-4': mdAndUp }, { 'pa-1': !mdAndUp }]">
    <v-row no-gutters class="bg-white pa-2">
      <v-col cols="12" md="6">
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
                prepend-inner-icon="mdi-link"
                label="Renseigner l'url pour le QRCode"
                v-model="qrcodeForm.urlQrcode"
                :error-messages="v$.qrcodeForm.urlQrcode.$errors.map(e => e.$message)" @input="v$.qrcodeForm.urlQrcode.$touch" @blur="v$.qrcodeForm.urlQrcode.$touch"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
                prepend-inner-icon="mdi-pencil"
                label="Renseigner un nom pour le QRCode"
                v-model="qrcodeForm.nameQrcode"
                :error-messages="v$.qrcodeForm.nameQrcode.$errors.map(e => e.$message)" @input="v$.qrcodeForm.nameQrcode.$touch" @blur="v$.qrcodeForm.nameQrcode.$touch"
            >

            </v-text-field>
          </v-col>
          <v-col cols="12">
            <p class="mb-2 text-center text-grey-darken-2">Couleur {{ qrcodeForm.isBackgroundMode ? 'de fond' : 'du premier plan' }} du QRCode :</p>
            <v-color-picker
                class="mx-auto w-75"
                v-model="currentColor"
                hide-inputs
            ></v-color-picker>
            <div class="d-flex justify-center">
              <v-btn @click="toggleColorMode" color="blue-grey-darken-4" class="my-5">
                Basculer vers {{ qrcodeForm.isBackgroundMode ? 'la couleur du premier plan' : 'la couleur de fond' }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12">
            <v-slider
                track-color="white"
                thumb-color="blue-grey-lighten-3"
                track-fill-color="blue-grey-darken-3"
                label="Taille du QRCode"
                class="w-100"
                prepend-icon="mdi-resize"
                hide-details
                :max="500"
                :min="100"
                v-model="qrcodeForm.sizeQrcode"
            ></v-slider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="position-relative flex-column justify-center d-flex align-center align-content-center">
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center align-start align-content-center">
            <p class="my-5 text-grey-darken-2">Aperçu du QR Code :</p>
          </v-col>
          <v-col cols="12" class="d-flex justify-center align-content-center">
            <qrcode-vue id="qrcode-generated" :value="qrcodeForm.urlQrcode" :size="qrcodeForm.sizeQrcode" level="H" :background="qrcodeForm.backgroundQrcode" :foreground="qrcodeForm.foregroundQrcode"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="d-flex justify-center align-content-center align-center my-5">
        <v-btn @click="downloadQRCode()" color="blue-grey-darken-4" :disabled="!qrcodeForm.nameQrcode || qrcodeForm.nameQrcode.trim() === '' || !qrcodeForm.urlQrcode || qrcodeForm.urlQrcode.trim() === ''">Télécharger le QR-CODE</v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import QrcodeVue from "qrcode.vue";
import {useDisplay} from "vuetify";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import {generalStore} from "@/store/General/generalStore";

export default defineComponent({
  name: 'QrcodeView',
  components: { QrcodeVue },
  setup() {
    const { mdAndUp } = useDisplay()
    return {
      v$: useVuelidate(),
      mdAndUp
    }
  },
  data() {
    return {
      qrcodeForm: {
        sizeQrcode: 250,
        backgroundQrcode: "#ffffff",
        foregroundQrcode: "#000000",
        isBackgroundMode: true,
        urlQrcode: null,
        nameQrcode: null,
      },
    }
  },
  validations() {
    return {
      qrcodeForm: {
        urlQrcode: {
          required: helpers.withMessage("L'url du QRCode est requise.", required),
        },
        nameQrcode: {
          required: helpers.withMessage("Le nom du QRCode est requis.", required),
        },
      },
    }
  },
  computed: {
    currentColor: {
      get() {
        return this.qrcodeForm.isBackgroundMode ? this.qrcodeForm.backgroundQrcode : this.qrcodeForm.foregroundQrcode;
      },
      set(value) {
        if (this.qrcodeForm.isBackgroundMode) {
          this.qrcodeForm.backgroundQrcode = value;
        } else {
          this.qrcodeForm.foregroundQrcode = value;
        }
      }
    }
  },
  methods: {
    toggleColorMode() {
      this.qrcodeForm.isBackgroundMode = !this.qrcodeForm.isBackgroundMode;
    },
    async downloadQRCode() {
      const result = await this.v$.qrcodeForm.$validate();
      if (!result) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la vérification du nom et de l'url du QRCode", "red-darken-3", true, 8000);
        return false;
      }
      const canvas = document.getElementById('qrcode-generated');
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = this.qrcodeForm.nameQrcode;
      link.click();
    },
  }
});
</script>