<template>
  <v-dialog v-model="getDialogActivityShow" max-width="600" @update:modelValue="updateDialog">
    <v-card v-if="type === 0">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5">
          <v-icon size="small" class="mr-1" icon="mdi-plus"></v-icon>Créer une Activité
        </div>

        <v-btn
            icon="mdi-close"
            variant="text"
            @click="updateDialog"
        ></v-btn>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-row dense>
          <v-col
              cols="12"
              md="12"
              sm="12"
          >
              <v-text-field label="Nom de l'Activité" v-model="addActivityForm.inputAddName"></v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-number-input v-model="addActivityForm.inputAddSeat" label="Nombre de place limité" max="9999" min="1" :disabled="inputSelectShowSeat"></v-number-input>
          </v-col>
          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-checkbox label="Coché si les places ne sont pas limité" v-model="inputSelectShowSeat"> </v-checkbox>
          </v-col>
          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-text-field label="Date de début" type="datetime-local" v-model="addActivityForm.inputAddDateActivityStart">
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-text-field label="Date de fin" type="datetime-local" v-model="addActivityForm.inputAddDateActivityEnd">
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="12"
              sm="12"
          >
            <v-text-field
                label="Adresse"
                v-model="addActivityForm.inputAddAddress"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="12"
              sm="12"
          >
            <v-textarea persistent-hint hint="Ajouter une description si vous pensez que c'est nécessaire" label="Description" counter v-model="addActivityForm.inputAddDescription">
              <template v-slot:counter="{ counter }">
                <template v-if="counter >= 501"><span class="text-red">{{counter}}/500</span></template>
                <template v-else>{{counter}}/500</template>
              </template>
            </v-textarea>
          </v-col>

          <v-col
              cols="12"
              sm="12"
          >
            <v-select
                persistent-hint
                hint="Sélectionner une structure si la personne fréquente souvent une ou des structures. Laisser vide sinon"
                multiple
                chips
                clearable
                label="Select"
                v-model="addActivityForm.inputAddStructure"
                :items="itemsSelectStructure">
            </v-select>
          </v-col>
        </v-row>

      </v-card-text>
      <small class="text-caption text-medium-emphasis ml-6"><span class="text-red font-weight-bold">*</span> Champs requis</small>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text="Fermer"
            variant="plain"
            @click="toggleDialog"
        ></v-btn>

        <v-btn
            color="green"
            text="Créer l'activité"
            variant="tonal"
            @click="callPostActivity"
        ></v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="type === 1">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5">
          <v-icon size="small" class="mr-1" icon="mdi-plus"></v-icon>Modifier une Activité
        </div>

        <v-btn
            icon="mdi-close"
            variant="text"
            @click="updateDialog"
        ></v-btn>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-row dense>
          <v-col
              cols="12"
              md="12"
              sm="12"
          >
            <v-text-field label="Nom de l'Activité" v-model="editSheetForm.inputEditName"></v-text-field>
          </v-col>

          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-number-input v-model="editSheetForm.inputEditSeat" label="Nombre de place limité" max="9999" min="1" :disabled="inputSelectShowSeat"></v-number-input>
          </v-col>
          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-checkbox label="Coché si les places ne sont pas limité" v-model="inputSelectShowSeat"> </v-checkbox>
          </v-col>
          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-text-field label="Date de début" type="datetime-local" v-model="editSheetForm.inputEditDateActivityStart">
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="6"
              sm="6"
          >
            <v-text-field label="Date de fin" type="datetime-local" v-model="editSheetForm.inputEditDateActivityEnd">
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="12"
              sm="12"
          >
            <v-text-field
                label="Adresse"
                v-model="editSheetForm.inputEditAddress"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="12"
              sm="12"
          >
            <v-textarea persistent-hint hint="Ajouter une description si vous pensez que c'est nécessaire" label="Description" counter v-model="editSheetForm.inputEditDescription">
              <template v-slot:counter="{ counter }">
                <template v-if="counter >= 501"><span class="text-red">{{counter}}/500</span></template>
                <template v-else>{{counter}}/500</template>
              </template>
            </v-textarea>
          </v-col>

          <v-col
              cols="12"
              sm="12"
          >
            <v-select
                persistent-hint
                hint="Sélectionner une structure si la personne fréquente souvent une ou des structures. Laisser vide sinon"
                multiple
                chips
                clearable
                label="Select"
                v-model="editSheetForm.inputEditStructure"
                :items="itemsSelectStructure">
            </v-select>
          </v-col>
        </v-row>

      </v-card-text>
      <small class="text-caption text-medium-emphasis ml-6"><span class="text-red font-weight-bold">*</span> Champs requis</small>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text="Fermer"
            variant="plain"
            @click="toggleDialog"
        ></v-btn>

        <v-btn
            color="green"
            text="Modifier l'activité"
            variant="tonal"
            @click="callEditActivity"
        ></v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="type === 2">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5">
          <v-icon size="small" class="mr-1" icon="mdi-plus"></v-icon>Supprimer l'activité
        </div>

        <v-btn
            icon="mdi-close"
            variant="text"
            @click="updateDialog"
        ></v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        Êtes-vous sûr de vouloir supprimer l'activité :
        <p class="text-center font-weight-bold my-2">"{{data.name}}"</p>
        <v-alert variant="flat" class="text-subtitle-2"
                 prominent border="bottom" text="Attention, cette action est irréversible. Elle entraînera la suppression de l'activité, ainsi que des inscriptions à cette activités et des paiements qui lui sont liés." type="warning"></v-alert>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text="Annuler"
            variant="plain"
            @click="toggleDialog"
        ></v-btn>

        <v-btn
            color="primary"
            text="Confirmer"
            variant="tonal"
            @click="jean"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import {activityStore} from "@/store/activityStore";
import {generalStore} from "@/store/General/generalStore";

const dateRegex = /^([0-2][0-9]|(3)[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/

const isValidDate = helpers.withMessage(
    'Le format de la date doit être dd/mm/YYYY avec des slashes.',
    value => dateRegex.test(value)
)

export default {
  name: 'DialogActivityComponent',
  props: {
    type: Number,
    data: Object,
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      addActivityForm: {
        inputAddName: {
          required: helpers.withMessage('Le nom est requis.', required),
        },
        inputAddDescription: {
          required: helpers.withMessage('Le nom est requis.', required),
        },
        inputAddSeat: {
          isValidDate
        },
        inputAddDateActivityStart: {
          validPhone: helpers.withMessage("Numéro avec 10 chiffres sans espace ni symbole.", (value) => !value || /^0[1-9][0-9]{8}$/.test(value))
        },
        inputAddDateActivityEnd: {
          email: helpers.withMessage("E-mail non valide.", email),
          maxLength: helpers.withMessage('E-mail trop longue.',(value) => {
            return !value || value.length <= 255;
          })
        },
      },
      editSheetForm: {
        inputEditLastname: {
          required: helpers.withMessage('Le nom est requis.', required),
        },
        inputEditFirstname: {
          required: helpers.withMessage('Le prénom est requis.', required),
        },
        inputEditBirthday: {

        },
        inputEditPhone: {
          validPhone: helpers.withMessage('Le format du numéro de téléphone doit être de 10 chiffres sans espace ni caractère spécial.', (value) => !value || /^0[1-9][0-9]{8}$/.test(value))
        },
        inputEditEmail: {
          email: helpers.withMessage("E-mail n'est pas une adresse e-mail valide.", email)
        },
        inputEditComment: {
          maxLength: helpers.withMessage('Le commentaire est trop long.', (value) => value.length <= 500)
        },
      },
    }
  },
  data() {
    return {
      disableLoading: false,
      itemsSelectStructure: process.env.VUE_APP_STRUCTURES.split(','),
      inputSelectShowSeat: false,
      addActivityForm: {
        inputAddName: null,
        inputAddDescription: null,
        inputAddSeat: null,
        inputAddAddress: null,
        inputAddDateActivityStart: null,
        inputAddDateActivityEnd: null,
        inputAddStructure: []
      },
      editSheetForm: {
        inputEditName: null,
        inputEditDescription: null,
        inputEditSeat: null,
        inputEditAddress: null,
        inputEditDateActivityStart: null,
        inputEditDateActivityEnd: null,
        inputEditStructure: []
      },
      dataIdEdit: null,
      lastname: null,
      firstname: null,
      birthday: null,
      phone: null,
      email: null,
      comment: null,
      structure: null,
      dialog: true,
    }
  },
  computed: {
    getDialogActivityShow: {
      get() {
        return activityStore().getDialogActivityShow;
      },
      set() {
        activityStore().toogleDialogActivity;
      }
    }
  },
  methods: {
    toggleDialog() {
      if(this.disableLoading !== true) {
        activityStore().dialogActivityShow = !activityStore().dialogActivityShow;
        this.$emit('finish');
      }
    },
    updateDialog() {
      activityStore().dialogActivityShow = !activityStore().getDialogActivityShow;
    },
    async callPostActivity() {
      this.disableLoading = true;
      /*const result = await this.v$.addActivityForm.$validate();
      if (!result) {
        this.disableLoading = false;
        return false;
      }*/
      try {
        const response = await activityStore().callPostActivity(this.addActivityForm.inputAddName, this.addActivityForm.inputAddSeat, this.addActivityForm.inputAddAddress, this.addActivityForm.inputAddDescription, this.addActivityForm.inputAddDateActivityStart, this.addActivityForm.inputAddDateActivityEnd, this.addActivityForm.inputAddStructure)
        if (response) {
          this.disableLoading = false;
          this.toggleDialog();
          generalStore().showNotifSnackbar("Création avec succès !", "light-green-darken-1", true, 5000);
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la création de l'activité !", "red-darken-3", true, 5000);
      }
      this.disableLoading = false;
    },
    async callEditActivity() {
      this.disableLoading = true;
      try {
        const response = await activityStore().callPutActivity(this.dataIdEdit, this.editSheetForm.inputEditName, this.editSheetForm.inputEditSeat, this.editSheetForm.inputEditAddress, this.editSheetForm.inputEditDescription, this.editSheetForm.inputEditDateActivityStart, this.editSheetForm.inputEditDateActivityEnd, this.editSheetForm.inputEditStructure)
        if (response) {
          this.disableLoading = false;
          this.toggleDialog();
          generalStore().showNotifSnackbar("Modification avec succès !", "light-green-darken-1", true, 5000);
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la modification de l'activité !", "red-darken-3", true, 5000);
      }
      this.disableLoading = false;
    },
    async jean() {
      try {
        const response = await activityStore().callDeleteActivity(this.data.id, [])
        if (response) {
          this.toggleDialog();
          generalStore().showNotifSnackbar("Suppresion avec succès", "light-green-darken-1", true, 5000);
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la suppresion !", "red-darken-3", true, 5000);
      }
    },
  },
  watch: {
    getDialogActivityShow() {
      if(this.data !== null) {
        this.dataIdEdit = this.data.id;
        this.editSheetForm.inputEditName = this.data.name;
        this.editSheetForm.inputEditAddress = this.data.address;
        this.editSheetForm.inputEditDescription = this.data.description;
        this.editSheetForm.inputEditStructure = this.data.structure;
        this.editSheetForm.inputEditSeat = this.data.seat;
        if (this.data.startActivityDate && this.data.startActivityDate.date && this.data.endActivityDate && this.data.endActivityDate.date) {
          this.editSheetForm.inputEditDateActivityStart = this.data.startActivityDate.date
              .replace(" ", "T") // Remplace l'espace par un "T"
              .slice(0, 16);
          this.editSheetForm.inputEditDateActivityEnd = this.data.endActivityDate.date
              .replace(" ", "T") // Remplace l'espace par un "T"
              .slice(0, 16);
        } else {
          this.editSheetForm.inputEditDateActivityStart = null;
          this.editSheetForm.inputEditDateActivityEnd = null;
        }
      }
    },
  },
}
</script>
<style scoped>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>