<template>
  <v-snackbar :location="'top'" :style="{'margin-top':calcMargin(i)}" v-for="(snackbar,i) in snackbars" :key="snackbar.id" v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color" elevation="24">
    <p @click="hideSnackbar(snackbar.id)" class="text-left">{{ snackbar.message }}</p>
    <template v-slot:actions>
      <v-btn
          icon="mdi-close"
          color="white"
          density="comfortable"
          size="x-small"
          @click="hideSnackbar(snackbar.id)"
      ></v-btn>
    </template>
    <div
        class="progress-bar"
        :style="{ animationDuration: `${snackbar.timeout}ms` }"
    ></div>
  </v-snackbar>
</template>

<script>
import { defineComponent } from 'vue';
import {generalStore} from "@/store/General/generalStore";

export default defineComponent({
  name: 'SnackbarComponent',
  computed: {
    snackbars() {
      return generalStore().snackbars;
    },
  },
  methods: {
    calcMargin(i) {
      const baseMargin = 10; // Décalage initial pour la première snackbar
      const additionalMargin = 60; // Marge supplémentaire pour chaque snackbar
      return `${baseMargin + i * additionalMargin}px`;
    },
    hideSnackbar(id) {
      generalStore().hideSnackbar(id);
    },
  },
});
</script>
<style scoped>
.progress-bar {
  position: absolute;
  height: 5px;
  background-color: white;
  animation-name: progress-decrease;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  bottom: 0; /* Position collée en bas */
  left: 0;
  right: 0;
}

@keyframes progress-decrease {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
</style>