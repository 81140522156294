<template>
  <v-snackbar
      max-width="250"
      vertical
      :location="'bottom right'"
      :style="{ 'margin-bottom': calcMargin(i) }"
      v-for="(tips, i) in tips"
      :key="tips.id"
      v-model="tips.show"
      :timeout="tips.timeout"
      :color="tips.color"
      elevation="24"
  >
    <v-btn
        class="position-absolute"
        style="bottom: 15px; right: 10px"
        color="white"
        size="small"
        @click="hideTips(tips.id)"
    >J'ai compris</v-btn>

    <div class="d-flex justify-start mb-1">
      <v-icon color="yellow" class="mr-3" icon="mdi-lightbulb-on"></v-icon>
      <div class="text-subtitle-1 font-weight-bold">Astuce</div>
    </div>

    <p @click="hideTips(tips.id)" class="text-left mb-10">{{ tips.message }}</p>

    <div
        class="progress-bar position-absolute"
        :style="{ animationDuration: `${tips.timeout}ms` }"
    ></div>
  </v-snackbar>
</template>

<script>
import { defineComponent } from 'vue';
import { generalStore } from "@/store/General/generalStore";

export default defineComponent({
  name: 'TipsComponent',
  computed: {
    tips() {
      return generalStore().tips;
    },
  },
  methods: {
    calcMargin(i) {
      const baseMargin = 10; // Décalage initial pour la première snackbar
      const additionalMargin = 60; // Marge supplémentaire pour chaque snackbar
      return `${baseMargin + i * additionalMargin}px`;
    },
    hideTips(id) {
      generalStore().hideTips(id);
    },
  },
});
</script>
<style scoped>
.progress-bar {
  height: 5px;
  background-color: white;
  animation-name: progress-decrease;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  bottom: 0; /* Position collée en bas */
  left: 0;
  right: 0;
}

@keyframes progress-decrease {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
</style>