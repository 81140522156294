<template>
  <v-empty-state :icon="getErrorIcon" :color="getErrorColor">
    <template v-slot:headline>
      <h2 class="text-white font-weight-light">Erreur
        <span :class="'text-' + getErrorColor" class="font-weight-bold">{{ getErrorCode }}</span>
      </h2>
    </template>
    <template v-slot:title>
      <h3 class="text-white font-weight-light">{{getErrorMessage}}</h3>
    </template>
    <template v-slot:actions>
      <v-btn v-if="canGoBack" color="blue-grey-darken-4" text="Retour à la page précédente" @click="goBack()"></v-btn>
    </template>
  </v-empty-state>
</template>
<script>
import {errorStore} from "@/store/Error/errorStore";

export default {
  name: 'ErrorComponent',
  data() {
    return {
      canGoBack: false
    }
  },
  mounted() {
    this.canGoBack = !!this.$router.options.history.state.back;
  },
  computed: {
    getErrorCode() {
      return errorStore().errorCode;
    },
    getErrorMessage() {
      return errorStore().errorMessage;
    },
    getErrorColor() {
      return errorStore().errorColor;
    },
    getErrorIcon() {
      return errorStore().errorIcon;
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },

  }
}
</script>
