import {defineStore} from 'pinia'

export const generalStore = defineStore('generalStore', {
    state: () => {
        return {
            stateNavbar: true,
            navbarShowIcon: "mdi-menu-open",
            isMobile: null,
            snackbars: [],
            tips: [],
        }
    },
    getters: {
        getStateNavbar: (state) => {
            return state.stateNavbar;
        },
        getNavbarShowIcon: (state) => {
            return state.navbarShowIcon;
        },
        getIsMobile: (state) => {
            return state.isMobile;
        }
    },
    actions: {
        toogleNavbar() {
            this.stateNavbar = !this.stateNavbar;
            this.stateNavbar ? this.navbarShowIcon = "mdi-menu-open" : this.navbarShowIcon = "mdi-menu";
        },
        async showNotifSnackbar(message = "", color = "blue", show = true, timeout = 5000) {
            const snackbar = {
                id: Date.now(),
                message,
                color,
                show,
                timeout,
            };
            this.snackbars.push(snackbar);
            if (timeout !== -1) {
                setTimeout(() => {
                    this.hideSnackbar(snackbar.id);
                }, timeout);
            }
        },
        hideSnackbar(id) {
            this.snackbars = this.snackbars.filter(snackbar => snackbar.id !== id);
        },
        async showTips(message = "", color = 'blue', show = true, timeout = 5000, type = 'tips') {
            const tips = {
                id: Date.now(),
                message,
                color,
                show,
                timeout,
                type
            };
            this.tips.push(tips);
            setTimeout(() => {
                this.hideTips(tips.id);
            }, timeout);
        },
        hideTips(id) {
            this.tips = this.tips.filter(tips => tips.id !== id);
        },
        startTimeTips() {
            const tips = [
                {
                    text: "Ne donnez jamais votre mot de passe à quelqu'un !",
                    color: "light-green-darken-1",
                    delay: 2000,
                    type: "tips"
                },
                {
                    text: "Votre mot de passe est oublié ? Vous pouvez le réinitialiser en cliquant sur mot de passe oublié.",
                    color: "red",
                    delay: 3000,
                    type: "infos"
                },
                {
                    text: "Vous pouvez voir qui a modifié une fiche, une activité ou bien même une inscription.",
                    color: "blue",
                    delay: 4000,
                    type: "infos"
                },
                {
                    text: "La description ou bien l'adresse ne sont pas des champs obligatoires pour une activité.",
                    color: "orange",
                    delay: 5000,
                    type: "infos"
                },
                {
                    text: "La date de naissance, le téléphone, l'email ne sont pas des champs obligatoires pour une fiche.",
                    color: "purple",
                    delay: 6000,
                    type: "infos"
                },
                {
                    text: "Vous pouvez ajouter des commentaires sur des fiches pour vous rappeler de certaines informations.",
                    color: "pink",
                    delay: 7000,
                    type: "tips"
                },
                {
                    text: "N'oubliez pas de temps en temps de changer votre mot de passe pour plus de sécurité !",
                    color: "yellow",
                    delay: 8000,
                    type: "alert"
                },
                {
                    text: "Vos actions sont enregistrées : suppression, modification, ajout, tout est traçable !",
                    color: "cyan",
                    delay: 9000,
                    type: "info"
                },
                {
                    text: "Une erreur ? Un doute ? Une question ? N'hésitez pas à contacter l'email contact@harmant-mathieu.fr !",
                    color: "teal",
                    delay: 10000,
                    type: "alert"
                },
                {
                    text: "Vérifiez toujours que l'inscription d'une fiche est bien prise en compte à l'activité voulue !",
                    color: "amber",
                    delay: 11000,
                    type: "tips"
                },
                {
                    text: "Utilisez la recherche pour trouver rapidement ce que vous cherchez.",
                    color: "indigo",
                    delay: 12000,
                    type: "info"
                },
                {
                    text: "L'application est vouée à s'améliorer avec le temps, n'hésitez pas à proposer des améliorations à contact@harmant-mathieu.fr",
                    color: "deep-orange",
                    delay: 13000,
                    type: "alert"
                },
                {
                    text: "Si vous souhaitez afficher plus d'informations, utilisez les filtres pour afficher des éléments masqués.",
                    color: "lime",
                    delay: 14000,
                    type: "tips"
                },
                {
                    text: "Vérifiez les mises à jour de l'application pour bénéficier des dernières fonctionnalités en appuyant sur le bouton vérifier MàJ lors de votre connexion.",
                    color: "light-blue",
                    delay: 15000,
                    type: "info"
                },

                {
                    text: "Ajouter des données propres (Nom, prénom...) afin d'éviter de polluer la base de donnée pour rien !",
                    color: "light-green-darken-1",
                    delay: 5000,
                    type: "tips"
                },
                {
                    "text": "Pensez à vérifier vos informations régulièrement pour éviter les erreurs.",
                    "color": "blue",
                    "delay": 7000,
                    "type": "advice"
                },
                {
                    "text": "Organisez vos données de manière logique pour y accéder plus rapidement.",
                    "color": "green",
                    "delay": 8000,
                    "type": "advice"
                },
                {
                    "text": "Planifiez vos tâches pour rester productif et ne rien oublier.",
                    "color": "yellow",
                    "delay": 7500,
                    "type": "advice"
                },
                {
                    "text": "Gardez vos fiches, activités et inscriptions à jour pour garantir leurs fiabilités.",
                    "color": "orange",
                    "delay": 7000,
                    "type": "advice"
                }
            ];

            const randomTip = tips[Math.floor(Math.random() * tips.length)];
            this.showTips(randomTip.text, randomTip.color, true, randomTip.delay, randomTip.type);
        }
    }
})