<template>
  <TopbarComponent />
  <section class="pa-4">
    <v-card>
      <v-row class="px-2 pt-1 bg-grey-lighten-4" :no-gutters="true">
        <v-col cols="6" class="d-flex justify-start align-content-center align-center">
          <v-tooltip text="Rafraîchir le tableau de bord" location="top" class="default-tooltip-color">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" icon="mdi-refresh" density="comfortable" :loading="loadingButtonRefresh" :color="colorButtonRefresh" variant="flat" @click="callGetDashboard"></v-btn>
            </template>
          </v-tooltip>
        </v-col>
        <v-col cols="6" class="d-flex justify-end align-content-center align-center">
          <template v-if="codeRefresh === 0">
            <v-progress-circular class="mr-2" model-value="20" :indeterminate="true" :width="1" :size="12"></v-progress-circular>
            <p class="text-caption text-gray font-weight-bold">Mise à jour des données en cours...</p>
          </template>
          <template v-if="codeRefresh === 1">
            <v-icon icon="mdi-information" color="green" class="mr-1" size="small"></v-icon>
            <p class="text-caption text-green font-weight-bold" v-if="timeLastRefreshSheet === 1">Donnée mise à jour il y a {{ timeLastRefreshSheet }} seconde</p>
            <p class="text-caption text-green font-weight-bold" v-else>Donnée mise à jour il y a {{ timeLastRefreshSheet }} secondes</p>
          </template>
          <template v-if="codeRefresh === 2">
            <v-icon icon="mdi-alert" color="red" class="mr-1" size="small"></v-icon>
            <p class="text-caption text-red font-weight-bold">Impossible de mettre à jour les données</p>
          </template>
          <template v-if="codeRefresh === 3">
            <v-icon icon="mdi-pause-circle" color="gray" class="mr-1" size="small"></v-icon>
            <p class="text-caption text-gray font-weight-bold">Mise à jour en pause lors d'ajout, modification ou suppresion</p>
          </template>
        </v-col>
      </v-row>
    </v-card>
    <v-card rounded="0">
    <v-row :no-gutters="true" class="bg-white">
      <v-col cols="12" md="6" class="d-flex flex-column">
        <h2 class="ma-2">Stats :</h2>
        <v-row :no-gutters="true">
          <v-col cols="12" md="3" class="d-flex align-stretch">
            <v-card color="orange-darken-1" class="ma-2 w-100 justify-space-between text-center d-flex align-content-center align-center flex-column py-3">
              <v-row :no-gutters="true" class="w-100 justify-space-between text-center d-flex align-content-center align-center flex-row">
                <v-col cols="3">
                  <v-icon class="text-h4" icon="mdi-chart-bar"></v-icon>
                </v-col>
                <v-col cols="9">
                  <p class="text-h4 font-weight-bold">{{ getDashboard?.totalSheet }}</p>
                  <p class="text-subtitle-2">Total Fiches</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-stretch">
            <v-card color="orange-darken-1" class="ma-2 w-100 justify-space-between text-center d-flex align-content-center align-center flex-column py-3">
              <v-row :no-gutters="true" class="w-100 justify-space-between text-center d-flex align-content-center align-center flex-row">
                <v-col cols="3">
                  <v-icon class="text-h4" icon="mdi-chart-bar"></v-icon>
                </v-col>
                <v-col cols="9">
                  <p class="text-h4 font-weight-bold">{{ getDashboard?.totalActivity }}</p>
                  <p class="text-subtitle-2">Total Activité</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-stretch">
            <v-card color="blue-darken-1" class="ma-2 w-100 justify-space-between text-center d-flex align-content-center align-center flex-column py-3">
              <v-row :no-gutters="true" class="w-100 justify-space-between text-center d-flex align-content-center align-center flex-row">
                <v-col cols="3">
                  <v-icon class="text-h4" icon="mdi-calendar-check"></v-icon>
                </v-col>
                <v-col cols="9">
                  <p class="text-h4 font-weight-bold">{{ getDashboard.general?.countScheduledActivityUser }}</p>
                  <p class="text-subtitle-2">Activité Prévue</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-stretch">
            <v-card color="green-darken-1" class="ma-2 w-100 justify-space-between text-center d-flex align-content-center align-center flex-column py-3">
              <v-row :no-gutters="true" class="w-100 justify-space-between text-center d-flex align-content-center align-center flex-row">
                <v-col cols="3">
                  <v-icon class="text-h4" icon="mdi-check-circle"></v-icon>
                </v-col>
                <v-col cols="9">
                  <p class="text-h4 font-weight-bold">{{ getDashboard.general?.countFinishActivityUser }}</p>
                  <p class="text-subtitle-2">Activité Finis</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row :no-gutters="true">
          <v-col cols="12" md="3" class="d-flex align-stretch">
            <v-card color="blue" class="ma-2 w-100 justify-space-between text-center d-flex align-content-center align-center flex-column py-3">
              <v-row :no-gutters="true" class="w-100 justify-space-between text-center d-flex align-content-center align-center flex-row">
                <v-col cols="3">
                  <v-icon class="text-h4" icon="mdi-chart-bar"></v-icon>
                </v-col>
                <v-col cols="9">
                  <p class="text-h4 font-weight-bold">{{ getDashboard.totalSheetUser }}</p>
                  <p class="text-subtitle-2">Vos Fiches</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-stretch">
            <v-card color="blue" class="ma-2 w-100 justify-space-between text-center d-flex align-content-center align-center flex-column py-3">
              <v-row :no-gutters="true" class="w-100 justify-space-between text-center d-flex align-content-center align-center flex-row">
                <v-col cols="3">
                  <v-icon class="text-h4" icon="mdi-chart-bar"></v-icon>
                </v-col>
                <v-col cols="9">
                  <p class="text-h4 font-weight-bold">{{ getDashboard.totalActivityUser }}</p>
                  <p class="text-subtitle-2">Vos Activité</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-stretch">
            <v-card color="blue" class="ma-2 w-100 justify-space-between text-center d-flex align-content-center align-center flex-column py-3">
              <v-row :no-gutters="true" class="w-100 justify-space-between text-center d-flex align-content-center align-center flex-row">
                <v-col cols="3">
                  <v-icon class="text-h4" icon="mdi-calendar-check"></v-icon>
                </v-col>
                <v-col cols="9">
                  <p class="text-h4 font-weight-bold">{{ getDashboard.user?.countScheduledActivity }}</p>
                  <p class="text-subtitle-2">Vos Activité Prévue</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-stretch">
            <v-card color="green-darken-1" class="ma-2 w-100 justify-space-between text-center d-flex align-content-center align-center flex-column py-3">
              <v-row :no-gutters="true" class="w-100 justify-space-between text-center d-flex align-content-center align-center flex-row">
                <v-col cols="3">
                  <v-icon class="text-h4" icon="mdi-check-circle"></v-icon>
                </v-col>
                <v-col cols="9">
                  <p class="text-h4 font-weight-bold">{{ getDashboard.user?.countFinishActivity }}</p>
                  <p class="text-subtitle-2">Vos Activité Finis</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <h2 class="ma-2">Contact :</h2>
        <div class="d-flex flex-column justify-center align-center align-content-center">
          <p class="text-center">Un soucis ? Un bug ? Une demande ? Vous pouvez me contacter ici :</p>
          <div class="justify-center align-center d-flex">
            <p>contact@harmant-mathieu.fr ou via mon numéro pro (faites la demande à la direction :) )</p>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <h2 class="ma-2">Infos :</h2>
        <p class="pa-5">
          Bonjour à tous,<br>

          <b>Une mise à jour a été effectuée</b> : désormais, la liste des inscriptions se rafraîchit correctement après l'ajout d'une fiche à une activité.<br>

          <b class="text-red">Cependant, un bug est encore présent</b> : pour les activités sans limite de place, il est actuellement impossible d’ajouter des fiches. Je suis en train de corriger ce problème.<br>

          En attendant, si vous souhaitez inscrire des fiches, vous pouvez contourner ce bug en ajoutant une limite de places aux activités concernées.<br>

          Merci pour votre patience !<br>

          Bonne journée 😀
        </p>
      </v-col>
    </v-row>
    </v-card>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import {dashboardStore} from "@/store/dashboardStore";
import {generalStore} from "@/store/General/generalStore";

export default defineComponent({
  name: 'DashboardView',
  computed: {
    getDashboard() {
      return dashboardStore().getDashboard;
    }
  },
  unmounted() {
    clearInterval(this.timerLastRefresh);
  },
  data: () => ({
    overlay: true,
    test: [0,1],
    //START DATA CODE REFRESH
    codeRefresh: 1,
    timerLastRefresh: null,
    timeLastRefreshSheet: 1,
    timer: null,
    timerLeft: 10,
    //END DATA CODE REFRESH
    loadingButtonRefresh: false,
    loadingDataTableSheet: false,
    colorButtonRefresh: "blue-grey-darken-4",
  }),
  created() {
  },
  methods: {
    async callGetDashboard() {
      this.codeRefresh = 0;
      this.loadingButtonRefresh = true;
      this.colorButtonRefresh = "blue-grey-lighten-1";
      try {
        const response = await dashboardStore().callGetDashboard();
        if (response) {
          this.codeRefresh = 1;
          this.startInterval();
        } else {
          generalStore().showNotifSnackbar("ERREUR", "orange", true, 5000);
          clearInterval(this.timerLastRefresh);
          this.codeRefresh = 2;
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la récupération des fiches !", "red-darken-3", true, 5000);
        clearInterval(this.timerLastRefresh);
        this.codeRefresh = 2;
      }
      this.loadingButtonRefresh = false;
      this.colorButtonRefresh = "blue-grey-darken-4";
    },
    //START METHOD TIMEOUT AND INTERVAL
    startInterval() {
      if (this.timerLastRefresh !== null) {
        clearInterval(this.timerLastRefresh);
      }
      this.timeLastRefreshSheet = 1;
      this.timerLastRefresh = setInterval(() => {
        this.timeLastRefreshSheet++;
        if (this.timeLastRefreshSheet >= 60) {
          this.callGetDashboard();
          clearInterval(this.timerLastRefresh);
        }
      }, 1000);
    },
    //END METHOD TIMEOUT AND INTERVAL
  }
});
</script>
