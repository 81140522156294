<template>
  <section :class="[{ 'pa-4': mdAndUp }, { 'pa-1': !mdAndUp }]">
    <v-window direction="vertical" v-model="getStateWindowSheet">
      <v-window-item :value="1">
        <keep-alive>
          <ListSheetComponent v-if="getStateWindowSheet === 1" />
        </keep-alive>
      </v-window-item>
      <v-window-item :value="2">
        <keep-alive>
          <InfoSheetComponent v-if="getStateWindowSheet === 2" />
        </keep-alive>
      </v-window-item>
    </v-window>
  </section>
</template>

<script>
import InfoSheetComponent from "@/components/Sheet/InfoSheetComponent";
import ListSheetComponent from "@/components/Sheet/ListSheetComponent";
import {sheetStore} from "@/store/sheetStore";
import {useDisplay} from "vuetify";

export default {
  name: 'SheetView',
  components: {ListSheetComponent, InfoSheetComponent},
  computed: {
    getStateWindowSheet() {
      return sheetStore().stateWindowSheet;
    }
  },
  setup() {
    const { mdAndUp } = useDisplay()
    return {
      mdAndUp
    }
  },
};
</script>
<style scoped>
</style>